import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";
import { AnimatePresence } from "framer-motion";
import { FaShareAlt, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { constitution } from "../data/constitution";
import ShareButtons from "./ShareButtons";

const ChapterContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const BackButton = styled(Link)`
  display: inline-block;
  margin-bottom: 1rem;
  color: var(--primary-color);
  text-decoration: none;
`;

const ChapterTitle = styled.h1`
  color: var(--primary-color);
  margin-bottom: 2rem;
`;

const SectionContainer = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  color: var(--secondary-color);
  margin-bottom: 1rem;
`;

const ContentItem = styled.div`
  position: relative;
  margin-bottom: 1rem;
  padding-right: 2rem;
`;

const ShareButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--primary-color);
`;

const NavigationLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }

  &.disabled {
    color: #ccc;
    pointer-events: none;
  }
`;

const SubsectionContainer = styled.div`
  margin-left: 2rem;
`;

const ChapterContent = () => {
  const { chapterId } = useParams();
  const chapterIndex = constitution.findIndex((c) => c.id === chapterId);
  const chapter = constitution[chapterIndex];
  const [activeShare, setActiveShare] = useState(null);

  if (!chapter) {
    return <div>Chapter not found</div>;
  }

  const renderContent = (content, sectionNumber = "") => {
    return content.map((item, index) => (
      <ContentItem key={`${sectionNumber}-${index}`}>
        {item.number && <strong>{item.number} </strong>}
        {item.title && <strong>{item.title}: </strong>}
        {item.text}
        {item.subsections && (
          <SubsectionContainer>
            {renderContent(item.subsections, `${sectionNumber}-${item.number}`)}
          </SubsectionContainer>
        )}
        <ShareButton
          onClick={() =>
            setActiveShare(
              activeShare === `${sectionNumber}-${index}`
                ? null
                : `${sectionNumber}-${index}`
            )
          }
        >
          <FaShareAlt />
        </ShareButton>
        <AnimatePresence>
          {activeShare === `${sectionNumber}-${index}` && (
            <ShareButtons content={item.text} />
          )}
        </AnimatePresence>
      </ContentItem>
    ));
  };

  return (
    <ChapterContainer>
      <BackButton to="/">← Back to Chapters</BackButton>
      <ChapterTitle>{chapter.title}</ChapterTitle>
      {chapter.sections.map((section) => (
        <SectionContainer key={section.number}>
          <SectionTitle>
            {section.number}. {section.title}
          </SectionTitle>
          {renderContent(section.content, section.number)}
        </SectionContainer>
      ))}
      <NavigationLinks>
        <NavLink
          to={`/chapter/${constitution[chapterIndex - 1]?.id}`}
          className={chapterIndex === 0 ? "disabled" : ""}
        >
          <FaChevronLeft /> Previous Chapter
        </NavLink>
        <NavLink
          to={`/chapter/${constitution[chapterIndex + 1]?.id}`}
          className={chapterIndex === constitution.length - 1 ? "disabled" : ""}
        >
          Next Chapter <FaChevronRight />
        </NavLink>
      </NavigationLinks>
    </ChapterContainer>
  );
};

export default ChapterContent;
