import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { constitution } from "../data/constitution.js";

const SearchResultsContainer = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
`;

const ResultItem = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  padding: 1rem;
`;

const ResultTitle = styled(Link)`
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  display: block;
  margin-bottom: 0.5rem;

  &:hover {
    text-decoration: underline;
  }
`;

const ResultContent = styled.p`
  color: var(--text-color);
  margin: 0;
`;

const SearchResults = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get("q");

  const searchContent = (query) => {
    return constitution.flatMap((chapter) =>
      chapter.sections.flatMap((section) =>
        section.content.flatMap((item) => {
          const matches = [];
          if (
            typeof item.text === "string" &&
            item.text.toLowerCase().includes(query.toLowerCase())
          ) {
            matches.push({
              chapterId: chapter.id,
              chapterTitle: chapter.title,
              sectionNumber: section.number,
              sectionTitle: section.title,
              content: item,
            });
          }
          if (Array.isArray(item.subsections)) {
            matches.push(
              ...searchSubsections(
                item.subsections,
                query,
                chapter,
                section,
                item.number
              )
            );
          }
          return matches;
        })
      )
    );
  };

  const searchSubsections = (
    subsections,
    query,
    chapter,
    section,
    parentNumber
  ) => {
    return subsections.flatMap((subsection) => {
      const matches = [];
      if (
        typeof subsection.text === "string" &&
        subsection.text.toLowerCase().includes(query.toLowerCase())
      ) {
        matches.push({
          chapterId: chapter.id,
          chapterTitle: chapter.title,
          sectionNumber: section.number,
          sectionTitle: section.title,
          content: subsection,
          parentNumber: parentNumber,
        });
      }
      if (Array.isArray(subsection.subsections)) {
        matches.push(
          ...searchSubsections(
            subsection.subsections,
            query,
            chapter,
            section,
            subsection.number
          )
        );
      }
      return matches;
    });
  };

  const results = searchContent(query);

  return (
    <SearchResultsContainer>
      <h2>Search Results for "{query}"</h2>
      {results.length === 0 ? (
        <p>No results found.</p>
      ) : (
        results.map((result, index) => (
          <ResultItem key={index}>
            <ResultTitle to={`/chapter/${result.chapterId}`}>
              {result.chapterTitle} - Section {result.sectionNumber}:{" "}
              {result.sectionTitle}
            </ResultTitle>
            <ResultContent>
              {result.parentNumber && <strong>{result.parentNumber} </strong>}
              {result.content.number && (
                <strong>{result.content.number} </strong>
              )}
              {result.content.title && (
                <strong>{result.content.title}: </strong>
              )}
              {typeof result.content.text === "string"
                ? result.content.text.substring(0, 200) + "..."
                : "Content unavailable"}
              {Array.isArray(result.content.subsections) && (
                <span> (includes subsections)</span>
              )}
            </ResultContent>
          </ResultItem>
        ))
      )}
    </SearchResultsContainer>
  );
};

export default SearchResults;
