import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import GlobalStyle from "./styles/GlobalStyle";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ChapterList from "./components/ChapterList";
import ChapterContent from "./components/ChapterContent";
import SearchResults from "./components/SearchResults";
import NotFound from "./components/NotFound";
import About from "./components/About";
import ContactForm from "./components/ContactForm";
import PrivacyPolicy from "./components/PrivacyPolicy";
import FAQ from "./components/FAQ";
import TermsOfUse from "./components/TermsOfUse";
import { constitution } from "./data/constitution";
import { useLocation } from "react-router-dom";
import { initGA, logPageView } from "./analytics";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  flex: 1;
`;

function App() {
  const location = useLocation();

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <AppContainer>
      <GlobalStyle />
      <Header chapters={constitution} />
      <MainContent>
        <Routes>
          <Route
            path="/"
            element={
              <div className="container">
                <ChapterList chapters={constitution} />
              </div>
            }
          />
          <Route
            path="/chapter/:chapterId"
            element={
              <div className="container">
                <ChapterContent />
              </div>
            }
          />
          <Route
            path="/search"
            element={
              <div className="container">
                <SearchResults constitution={constitution} />
              </div>
            }
          />

          <Route
            path="/about"
            element={
              <div className="container">
                <About />
              </div>
            }
          />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/terms" element={<TermsOfUse />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </MainContent>
      <Footer />
    </AppContainer>
  );
}

export default App;
