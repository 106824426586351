import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&family=Open+Sans:wght@400;600&display=swap');

  :root {
    --primary-color: #3498db;
    --secondary-color: #2c3e50;
    --background-color: #f4f4f4;
    --text-color: #333333;
    --accent-color: #e74c3c;
  }

  body {
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
    margin: 0;
    padding: 0;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Merriweather', serif;
    color: var(--secondary-color);
  }

  * {
    box-sizing: border-box;
  }

  a {
    color: var(--primary-color);
    text-decoration: none;
    transition: color 0.3s ease;
    &:hover {
      color: var(--accent-color);
    }
  }

`;

export default GlobalStyle;
