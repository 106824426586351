import React from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
`;

const Title = styled.h1`
  color: var(--primary-color);
  margin-bottom: 1rem;
`;

const Content = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    color: var(--secondary-color);
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }
`;

const TermsOfUse = () => (
  <Container>
    <Title>Terms of Use</Title>
    <Content>
      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing and using the Constitution of Malawi website, you accept
        and agree to be bound by the terms and provision of this agreement.
      </p>

      <h2>2. Use License</h2>
      <p>
        Permission is granted to temporarily download one copy of the materials
        on the Constitution of Malawi's website for personal, non-commercial
        transitory viewing only.
      </p>

      <h2>3. Disclaimer</h2>
      <p>
        The materials on the Constitution of Malawi's website are provided on an
        'as is' basis. The Constitution of Malawi makes no warranties, expressed
        or implied, and hereby disclaims and negates all other warranties
        including, without limitation, implied warranties or conditions of
        merchantability, fitness for a particular purpose, or non-infringement
        of intellectual property or other violation of rights.
      </p>

      <h2>4. Limitations</h2>
      <p>
        In no event shall the Constitution of Malawi or its suppliers be liable
        for any damages (including, without limitation, damages for loss of data
        or profit, or due to business interruption) arising out of the use or
        inability to use the materials on the Constitution of Malawi's website.
      </p>

      <h2>5. Revisions and Errata</h2>
      <p>
        The materials appearing on the Constitution of Malawi's website could
        include technical, typographical, or photographic errors. The
        Constitution of Malawi does not warrant that any of the materials on its
        website are accurate, complete or current.
      </p>
    </Content>
  </Container>
);

export default TermsOfUse;
