import React from "react";
import styled from "styled-components";

const AboutContainer = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
`;

const AboutTitle = styled.h1`
  color: var(--primary-color);
  margin-bottom: 1rem;
`;

const AboutContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const About = () => (
  <AboutContainer>
    <AboutTitle>About the Constitution of Malawi</AboutTitle>
    <AboutContent>
      <p>
        The Constitution of Malawi is the supreme law of Malawi. It was adopted
        in 1994 and provides the framework for the government of Malawi.
      </p>
      <p>
        This website aims to make the Constitution of Malawi accessible to all
        citizens and interested parties. It provides an easy-to-navigate
        interface for exploring the various chapters and sections of the
        constitution.
      </p>
      <p>
        Our goal is to promote understanding of Malawi's governing principles
        and to foster informed citizenship.
      </p>
    </AboutContent>
  </AboutContainer>
);

export default About;
