import React from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
`;

const Title = styled.h1`
  color: var(--primary-color);
  margin-bottom: 1rem;
`;

const Content = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    color: var(--secondary-color);
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }
`;

const PrivacyPolicy = () => (
  <Container>
    <Title>Privacy Policy</Title>
    <Content>
      <h2>1. Information Collection</h2>
      <p>
        We collect information you provide directly to us when using the
        Constitution of Malawi website. This may include your name and email
        address when you contact us.
      </p>

      <h2>2. Use of Information</h2>
      <p>
        We use the information we collect to operate and improve our website,
        respond to your comments and questions, and provide customer service.
      </p>

      <h2>3. Information Sharing</h2>
      <p>
        We do not share your personal information with third parties except as
        described in this privacy policy or with your consent.
      </p>

      <h2>4. Data Security</h2>
      <p>
        We take reasonable measures to help protect your personal information
        from loss, theft, misuse, unauthorized access, disclosure, alteration,
        and destruction.
      </p>

      <h2>5. Changes to This Policy</h2>
      <p>
        We may update this privacy policy from time to time. We will notify you
        of any changes by posting the new privacy policy on this page.
      </p>
    </Content>
  </Container>
);

export default PrivacyPolicy;
