import React from "react";
import styled from "styled-components";
import { FaFacebook, FaTwitter, FaLinkedin, FaEnvelope } from "react-icons/fa";
import { motion } from "framer-motion";

const ShareContainer = styled(motion.div)`
  position: absolute;
  right: 0;
  top: 100%;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  display: flex;
  z-index: 10;
`;

const ShareLink = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 0.25rem;
  color: var(--primary-color);
`;

const ShareButtons = ({ content }) => {
  const shareUrl = window.location.href;
  const shareTitle = "Constitution of Malawi";

  const shareFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        shareUrl
      )}`,
      "_blank"
    );
  };

  const shareTwitter = () => {
    window.open(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        content
      )}&url=${encodeURIComponent(shareUrl)}`,
      "_blank"
    );
  };

  const shareLinkedIn = () => {
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        shareUrl
      )}&title=${encodeURIComponent(shareTitle)}&summary=${encodeURIComponent(
        content
      )}`,
      "_blank"
    );
  };

  const shareEmail = () => {
    window.location.href = `mailto:?subject=${encodeURIComponent(
      shareTitle
    )}&body=${encodeURIComponent(`${content}\n\nRead more: ${shareUrl}`)}`;
  };

  return (
    <ShareContainer
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.2 }}
    >
      <ShareLink onClick={shareFacebook}>
        <FaFacebook />
      </ShareLink>
      <ShareLink onClick={shareTwitter}>
        <FaTwitter />
      </ShareLink>
      <ShareLink onClick={shareLinkedIn}>
        <FaLinkedin />
      </ShareLink>
      <ShareLink onClick={shareEmail}>
        <FaEnvelope />
      </ShareLink>
    </ShareContainer>
  );
};

export default ShareButtons;
