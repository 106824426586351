import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FaSearch } from "react-icons/fa";
import { constitution } from "../data/constitution.js";

const StyledHeader = styled.header`
  background: linear-gradient(
    135deg,
    rgba(44, 62, 80, 0.98) 0%,
    rgba(52, 152, 219, 0.98) 100%
  );
  color: white;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const NavLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin: 0.5rem;
  &:hover {
    text-decoration: underline;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    width: auto;
  }
`;

const SearchForm = styled.form`
  display: flex;
  margin-bottom: 0.5rem;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
`;

const SearchInput = styled.input`
  padding: 0.5rem;
  border: none;
  border-radius: 4px 0 0 4px;
  flex-grow: 1;
  font-size: 16px; // Prevent zoom on mobile
`;

const SearchButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: white;
  color: var(--primary-color);
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
`;

const Select = styled.select`
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  appearance: none;
  background-color: white;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  padding-right: 2rem;
`;

const Header = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
  };

  const handleJumpToChapter = (e) => {
    const chapterId = e.target.value;
    if (chapterId) {
      navigate(`/chapter/${chapterId}`);
    }
  };

  return (
    <StyledHeader>
      <Nav>
        <NavLinks>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/faq">FAQ</NavLink>
          <NavLink to="/contact">Contact</NavLink>
        </NavLinks>
        <SearchContainer>
          <SearchForm onSubmit={handleSearch}>
            <SearchInput
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <SearchButton type="submit">
              <FaSearch />
            </SearchButton>
          </SearchForm>
          <Select onChange={handleJumpToChapter}>
            <option value="">Jump to Chapter</option>
            {constitution.map((chapter) => (
              <option key={chapter.id} value={chapter.id}>
                {chapter.title}
              </option>
            ))}
          </Select>
        </SearchContainer>
      </Nav>
    </StyledHeader>
  );
};

export default Header;
