import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion";

const ChapterListContainer = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
`;

const IntroText = styled(motion.div)`
  text-align: center;
  margin-bottom: 2rem;
  padding: 1.5rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const IntroTitle = styled.h2`
  color: var(--secondary-color); // Changed to secondary color
  margin-bottom: 0.5rem;
  font-weight: 600; // Made it slightly bolder
`;

const IntroDescription = styled.p`
  color: var(--text-color);
  margin: 0;
`;

const ChapterItem = styled(motion.div)`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const ChapterLink = styled(Link)`
  display: block;
  padding: 1rem;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;

  &:hover {
    background-color: rgba(52, 152, 219, 0.1);
  }
`;
const LoadMoreButton = styled.button`
  display: block;
  margin: 1rem auto;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--secondary-color);
  }
`;

const ChapterList = ({ chapters }) => {
  const [visibleChapters, setVisibleChapters] = useState(10);

  const loadMore = () => {
    setVisibleChapters((prevVisible) => prevVisible + 10);
  };

  return (
    <ChapterListContainer>
      <IntroText>
        <IntroTitle>Constitution of Malawi</IntroTitle>
        <IntroDescription>
          Explore the chapters of Malawi's constitution below
        </IntroDescription>
      </IntroText>
      {chapters.slice(0, visibleChapters).map((chapter, index) => (
        <ChapterItem
          key={chapter.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: index * 0.1 }}
        >
          <ChapterLink to={`/chapter/${chapter.id}`}>
            {chapter.title}
          </ChapterLink>
        </ChapterItem>
      ))}
      {visibleChapters < chapters.length && (
        <LoadMoreButton onClick={loadMore}>Load More</LoadMoreButton>
      )}
    </ChapterListContainer>
  );
};

export default ChapterList;
