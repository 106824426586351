import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaEnvelope } from "react-icons/fa";

const StyledFooter = styled.footer`
  background: linear-gradient(
    135deg,
    rgba(44, 62, 80, 0.98) 0%,
    rgba(52, 152, 219, 0.98) 100%
  );
  color: white;
  padding: 2rem 1rem;
  font-size: 0.9rem;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const FooterLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin: 0.5rem;
  &:hover {
    text-decoration: underline;
  }
`;

const Copyright = styled.p`
  text-align: center;
  margin-top: 1rem;
`;

const Footer = () => (
  <StyledFooter>
    <FooterContent>
      <FooterLinks>
        <FooterLink to="/about">About</FooterLink>
        <FooterLink to="/faq">FAQ</FooterLink>
        <FooterLink to="/contact">Contact</FooterLink>
        <FooterLink to="/privacy">Privacy Policy</FooterLink>
        <FooterLink to="/terms">Terms of Use</FooterLink>
      </FooterLinks>
      {/* <SocialLinks> */}
      {/* <SocialLink href="#" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </SocialLink>
        <SocialLink href="#" target="_blank" rel="noopener noreferrer">
          <FaTwitter />
        </SocialLink> */}
      <a
        href="mailto:info@malawiconstitution.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span
          style={{
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          <FaEnvelope style={{ margin: "5px" }} /> info@malawiconstitution.org
        </span>
      </a>
      {/* </SocialLinks> */}
    </FooterContent>
    <Copyright>
      &copy; {new Date().getFullYear()} Constitution of Malawi | Designed with
      ❤️
    </Copyright>
  </StyledFooter>
);

export default Footer;
