import React from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
`;

const Title = styled.h1`
  color: var(--primary-color);
  margin-bottom: 1rem;
`;

const Content = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Question = styled.h2`
  color: var(--secondary-color);
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
`;

const Answer = styled.p`
  margin-bottom: 1rem;
`;

const FAQ = () => (
  <Container>
    <Title>Frequently Asked Questions</Title>
    <Content>
      <Question>Q: What is the Constitution of Malawi?</Question>
      <Answer>
        A: The Constitution of Malawi is the supreme law of Malawi. It
        establishes the structure of the Malawian government and outlines the
        fundamental rights and duties of its citizens.
      </Answer>

      <Question>
        Q: When was the current Constitution of Malawi adopted?
      </Question>
      <Answer>
        A: The current Constitution of Malawi was adopted in 1994, following the
        country's transition from a one-party state to a multi-party democracy.
      </Answer>

      <Question>Q: How can I use this website?</Question>
      <Answer>
        A: You can use this website to explore the different chapters and
        sections of the Malawi Constitution. You can navigate through the
        chapters, search for specific content, and share sections on social
        media.
      </Answer>

      <Question>Q: Is the content on this website official?</Question>
      <Answer>
        A: While we strive to provide accurate information, this website is not
        an official government site. For official legal purposes, please refer
        to the official government publications of the Constitution of Malawi.
      </Answer>

      <Question>Q: How often is the content updated?</Question>
      <Answer>
        A: We aim to keep the content as up-to-date as possible. However, for
        the most recent amendments or changes to the Constitution, please refer
        to official government sources.
      </Answer>
    </Content>
  </Container>
);

export default FAQ;
