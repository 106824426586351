import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`;

const NotFoundTitle = styled.h1`
  font-size: 4rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
`;

const NotFoundText = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;

const HomeLink = styled(Link)`
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--secondary-color);
  }
`;

const NotFound = () => (
  <NotFoundContainer>
    <NotFoundTitle>404</NotFoundTitle>
    <NotFoundText>
      Oops! The page you're looking for doesn't exist.
    </NotFoundText>
    <HomeLink to="/">Return to Home</HomeLink>
  </NotFoundContainer>
);

export default NotFound;
