export const constitution = [
  {
    id: "chapter1",
    title: "Chapter I: The Republic of Malawi",
    sections: [
      {
        number: "1",
        title: "Malawi a sovereign state",
        content: [
          {
            number: "",
            text: "The Republic of Malawi is a sovereign State with rights and obligations under the Law of Nations.",
          },
        ],
      },
      {
        number: "2",
        title: "The national flag, etc",
        content: [
          {
            number: "",
            text: "Malawi shall have a National Flag, a National Coat of Arms, a National Anthem and a Public Seal.",
          },
        ],
      },
      {
        number: "3",
        title: "The national territory",
        content: [
          {
            number: "",
            text: "The national territory of the Republic of Malawi shall consist of all the territory, including airspace, waters and islands which comprised the territory of Malawi before the commencement of this Constitution, and shall include any territory lawfully acquired thereafter by adjustment of boundaries or otherwise.",
          },
        ],
      },
      {
        number: "4",
        title: "Protection of the people of Malawi under this Constitution",
        content: [
          {
            number: "",
            text: "This Constitution shall bind all executive, legislative and judicial organs of the State at all levels of Government and all the peoples Malawi are entitled to the equal protection of this Constitution, and laws made under it.",
          },
        ],
      },
      {
        number: "5",
        title: "Supremacy of this Constitution",
        content: [
          {
            number: "",
            text: "Any act of Government or any law that is inconsistent with the provisions of this Constitution shall, to the extent of such inconsistency, be invalid.",
          },
        ],
      },
      {
        number: "6",
        title: "Universal and equal suffrage",
        content: [
          {
            number: "",
            text: "Save as otherwise provided in this Constitution, the authority to govern derives from the people of Malawi as expressed through universal and equal suffrage in elections held in accordance with this Constitution in a manner prescribed by an Act of Parliament.",
          },
        ],
      },
      {
        number: "7",
        title: "The separate status, function and duty of the executive",
        content: [
          {
            number: "",
            text: "The executive shall be responsible for the initiation of policies and legislation and for the implementation of all laws which embody the express wishes of the people of Malawi and which promote the principles of this Constitution.",
          },
        ],
      },
      {
        number: "8",
        title: "The separate status, function and duty of the legislature",
        content: [
          {
            number: "",
            text: "The legislature when enacting laws shall reflect in its deliberations the interests of all the people of Malawi and shall further the values explicit or implicit in this Constitution.",
          },
        ],
      },
      {
        number: "9",
        title: "The separate status, functions duty of the judiciary",
        content: [
          {
            number: "",
            text: "The judiciary shall have the responsibility of interpreting, protecting and enforcing this Constitution and all laws and in accordance with this Constitution in an independent and impartial manner with regard only to legally relevant facts and the prescriptions of law.",
          },
        ],
      },
    ],
  },
  {
    id: "chapter2",
    title: "Chapter II: Application and Interpretation",
    sections: [
      {
        number: "10",
        title: "Application of this Constitution",
        content: [
          {
            number: "1",
            text: "In the interpretation of all laws and in the resolution of political disputes the provisions of this Constitution shall be regarded as the supreme arbiter and ultimate source of authority.",
          },
          {
            number: "2",
            text: "In the application and formulation of any Act of Parliament and in the application and development of the common law and customary law, the relevant organs of State shall have due regard to the principles and provisions of this Constitution.",
          },
        ],
      },
      {
        number: "11",
        title: "Interpretation",
        content: [
          {
            number: "1",
            text: "Appropriate principles of interpretation of this Constitution shall be developed and employed by the courts to reflect the unique character and supreme status of this Constitution.",
          },
          {
            number: "2",
            text: "In interpreting the provisions of this Constitution a court of law shall -",
            subsections: [
              {
                letter: "a",
                text: "promote the values which underlie an open and democratic society;",
              },
              {
                letter: "b",
                text: "take full account of the provisions of Chapter III and Chapter IV; and",
              },
              {
                letter: "c",
                text: "where applicable, have regard to current norms of public international law and comparable foreign case law.",
              },
            ],
          },
          {
            number: "3",
            text: "Where a court of law declares an act of executive or a law to be invalid, that court may apply such interpretation of that act or law as is consistent with this Constitution.",
          },
          {
            number: "4",
            text: "Any law that ousts or purports to oust the jurisdiction of the courts to entertain matters pertaining to this Constitution shall be invalid.",
          },
        ],
      },
    ],
  },
  {
    id: "chapter3",
    title: "Chapter III: Fundamental Principles",
    sections: [
      {
        number: "12",
        title: "Constitutional principles",
        content: [
          {
            number: "",
            text: "This Constitution is founded upon the following underlying principles -",

            subsections: [
              {
                number: "(i)",
                text: "All legal and political authority of the State derives from the people of Malawi and shall be exercised in accordance with this Constitution solely to serve and protect their interests.",
              },
              {
                number: "(ii)",
                text: "All persons responsible for the exercise of powers of State do so on trust and shall only exercise such power to the extent of their lawful authority and in accordance with their responsibilities to the people of Malawi.",
              },
              {
                number: "(iii)",
                text: "The authority to exercise power of State is conditional upon the sustained trust of the people of Malawi and that trust can only be maintained through open, accountable and transparent Government and informed democratic choice.",
              },
              {
                number: "(iv)",
                text: "The inherent dignity and worth of each human being requires that the State and all persons shall recognize and protect fundamental human rights and afford the fullest protection to the rights and views of all individuals, groups and minorities whether or not they are entitled to vote.",
              },
              {
                number: "(v)",
                text: "As all persons have equal status before the law, the only justifiable limitations to lawful rights are those necessary to ensure peaceful human interaction in an open and democratic society.",
              },
              {
                number: "(vi)",
                text: "All institutions and persons shall observe and uphold the Constitution and the rule of law and no institution or person shall stand above the law.",
              },
            ],
          },
        ],
      },
      {
        number: "13",
        title: "Principle of National Policy",
        content: [
          {
            number: "1",
            text: "The State shall actively promote the welfare and development of the people of Malawi by progressively adopting and implementing policies and legislation aimed at achieving the following goals -",
          },
          {
            number: "(a)",
            title: "Gender Equality",
            text: "To obtain gender equality for women with men through -",
            subsections: [
              {
                number: "(i)",
                text: "full participation of women in all spheres of Malawian society on the basis of equality with men;",
              },
              {
                number: "(ii)",
                text: "the implementation of the principles of non-discrimination and such other measures as may be required; and",
              },
              {
                number: "(iii)",
                text: "the implementation of policies to address social issues such as domestic violence, security of the person, lack of maternity benefits, economic exploitation and rights to property.",
              },
            ],
          },
          {
            number: "(b)",
            title: "Nutrition",
            text: "To achieve adequate nutrition for all in order to promote good health and self-sufficiency.",
          },

          {
            number: "(c)",
            title: "Health",
            text: "To provide adequate health care, commensurate with the health needs of Malawian society and international standards of healthcare.",
          },

          {
            number: "(d)",
            title: "The Environment",
            text: "To manage the environment responsibly in order to -",
            subsections: [
              {
                number: "(i)",
                text: "prevent the degradation of the environment;",
              },
              {
                number: "(ii)",
                text: "provide a healthy living and working environment for the people of Malawi;",
              },
              {
                number: "(iii)",
                text: "accord full recognition to the rights of future generations  by means of environmental protection and the sustainable development of natural resources; and",
              },
              {
                number: "(iv)",
                text: "conserve and enhance the biological diversity of Malawi.",
              },
            ],
          },
          {
            number: "(e)",
            title: "Rural Life",
            text: "To enhance the quality of life in rural communities and to recognize rural standards of living as a key indicator of the success of Government policies.",
          },

          {
            number: "(f)",
            title: "Education",
            text: "To provide adequate resources to the education sector and devise programmes in order to eliminate illiteracy in Malawi;",
            subsections: [
              {
                number: "(i)",
                text: "make primary education compulsory and free to all citizens of Malawi;",
              },
              {
                number: "(ii)",
                text: "offer greater access to higher learning and continuing education; and",
              },
              {
                number: "(iii)",
                text: "promote national goals such as unity and the elimination of political, religious, racial and ethnic intolerance.",
              },
            ],
          },
          {
            number: "(g)",
            title: "The Disabled",
            text: "To support the disabled through -",
            subsections: [
              { number: "(i)", text: "greater access to public places;" },
              { number: "(ii)", text: "fair opportunities in employment; and" },
              {
                number: "(iii)",
                text: "the fullest possible participation in all spheres of Malawian society.",
              },
            ],
          },
          {
            number: "(h)",
            title: "Children",
            text: "To encourage and promote conditions conducive to the full development of healthy, productive and responsible members of society.",
          },
          {
            number: "(i)",
            title: "The Family",
            text: "To recognize and protect the family as a fundamental and vital social unit.",
          },

          {
            number: "(j)",
            title: "The Elderly",
            text: "To respect and support the elderly through the provision of community services and to encourage the participation of the elderly in the life of the community.",
          },
          {
            number: "(k)",
            title: "International Relations",
            text: "To govern in accordance with the law of nations and the rule of law and actively support the further development thereof in regional and international affairs.",
          },
          {
            number: "(l)",
            title: "Peaceful Settlement of Disputes",
            text: "To strive to adopt mechanisms by which differences are settled through negotiation, good offices, mediation, conciliation and arbitration.",
          },

          {
            number: "(m)",
            title: "Administration of Justice",
            text: "To promote law and order and respect for society through civic education, by honest practices in Government, adequate resourcing, and the humane application and enforcement of laws and policing standards.",
          },

          {
            number: "(n)",
            title: "Economic Management",
            text: "To achieve a sensible balance between the creation and distribution of wealth through the nurturing of a market economy and long-term investment in health, education, economic and social development programmes.",
          },

          {
            number: "(o)",
            title: "Public Trust and Good Governance",
            text: `To introduce measures which will guarantee accountability,
            transparency, personal integrity and financial probity and which
            by virtue of their effectiveness and transparency will strengthen
            confidence in public institutions.`,
          },
        ],
      },
      {
        number: "14",
        title: "Application of the principles of national policy",
        content: [
          {
            number: "",
            text: `The principles of national policy contained in this Chapter shall be 
            directory in nature but courts shall be entitled to have regard
            to them in interpreting and applying any of the provisions of this
            Constitution or of any law or in determining the validity of
            decisions of the executive and in the interpretation of the
            provisions of this Constitution.`,
          },
        ],
      },
    ],
  },

  {
    id: "chapter4",
    title: "Chapter IV: HUMAN RIGHTS",
    sections: [
      {
        number: "15",
        title: `Protection of human rights and freedoms`,
        content: [
          {
            number: "1",
            text: `
            The human rights and freedoms enshrined in this Chapter shall be
            respected and upheld by the executive, legislature and judiciary and all
            organs of the Government and its agencies and, where applicable to them,
            by all natural and legal persons in Malawi and shall be enforceable in the
            manner prescribed in this Chapter.           
            `,
          },
          {
            number: "2",
            text: `
            Any person or group of persons with sufficient interest in the protection
            and enforcement of rights under this Chapter shall be entitled to the
            assistance of the courts, the Ombudsman, the Human Rights Commission
            and other organs of Government to ensure the promotion, protection and
            redress of grievance in respect of those rights.
            `,
          },
        ],
      },
      {
        number: "16",
        title: `The right to life`,
        content: [
          {
            number: "",
            text: `
            Every person has the right to life and no person shall be arbitrarily deprived of his
            or her life:
    
            Provided that the execution of the death sentence imposed by a competent court
            on a person in respect of a criminal offence under the laws of Malawi of which he
            or she has been convicted shall not be regarded as arbitrary deprivation of his or
            her right to life.
            `,
          },
        ],
      },
      {
        number: "17",
        title: `Genocide`,
        content: [
          {
            number: "",
            text: `Acts of genocide are prohibited and shall be prevented and punished.`,
          },
        ],
      },
      {
        number: "18",
        title: `Liberty`,
        content: [
          {
            number: "",
            text: `Every person has the right to personal liberty.`,
          },
        ],
      },
      {
        number: "19",
        title: `Human dignity and personal freedoms`,
        content: [
          {
            number: "1",
            text: `The dignity of all persons shall be inviolable.`,
          },
          {
            number: "2",
            text: `In any judicial proceedings or in any other proceedings before any organ of
            the State, and during the enforcement of a penalty, respect for human
            dignity shall be guaranteed.`,
          },
          {
            number: "3",
            text: `No person shall be subject to torture of any kind or to cruel, inhuman or
            degrading treatment or punishment.`,
          },
          {
            number: "4",
            text: `No person shall be subject to corporal punishment in connexion with any
            judicial proceedings or in any other proceedings before any organ of the
            State.`,
          },
          {
            number: "5",
            text: `No person shall be subjected to medical or scientific experimentation
            without his or her consent.`,
          },
          {
            number: "6",
            text: `Subject to this Constitution, every person shall have the right to freedom 
            and security of person, which shall include the right not to be-
            `,
            subsections: [
              {
                number: "(a)",
                text: `detained without trial;`,
              },
              {
                number: "(b)",
                text: `detained solely by reason of his or her political or other opinions; or`,
              },
              {
                number: "(c)",
                text: `imprisoned for inability to fulfill contractual obligations.`,
              },
            ],
          },
        ],
      },
      {
        number: "20",
        title: `Equality`,
        content: [
          {
            number: "1",
            text: `Discrimination of persons in any form is prohibited and all persons are,
                under any law, guaranteed equal and effective protection against
                discrimination on grounds of race, colour, sex, language, religion, political
                or other opinion, nationality, ethnic or social origin, disability, property,
                birth or other status.`,
          },
          {
            number: "2",
            text: `Legislation may be passed addressing inequalities in society and
                prohibiting discriminatory practices and the propagation of such practices
                and may render such practices criminally punishable by the courts.`,
          },
        ],
      },
      {
        number: "21",
        title: `Privacy`,
        content: [
          {
            number: "1",
            text: `Every person shall have the right to personal privacy, which shall include
                the right not to be subject to -`,
            subsections: [
              {
                number: "(a)",
                text: `searches of his or her person, home or property;`,
              },
              { number: "(b)", text: `the seizure of private possessions; or` },
              {
                number: "(c)",
                text: `interference with private communications, including mail and all
                        forms of telecommunications.`,
              },
            ],
          },
        ],
      },
      {
        number: "22",
        title: `Family and marriage`,
        content: [
          {
            number: "1",
            text: `The family is the natural and fundamental group unit of society and is
                entitled to protection by society and the State.`,
          },
          {
            number: "2",
            text: `Each member of the family shall enjoy full and equal respect and shall be
                protected by law against all forms of neglect, cruelty or exploitation.`,
          },
          {
            number: "3",
            text: `All men and women have the right to marry and found a family.`,
          },
          {
            number: "4",
            text: `No person shall be forced to enter into marriage.`,
          },
          {
            number: "5",
            text: `Sub-sections (3) and (4) shall apply to all marriages at law, custom and
                marriages by repute or by permanent cohabitation.`,
          },
          {
            number: "6",
            text: `No person over the age of eighteen years shall be prevented from entering
                into marriage.`,
          },
          {
            number: "7",
            text: `For persons between the age of fifteen and eighteen years a marriage shall
                only be entered into with the consent of their parents or guardians.`,
          },
          {
            number: "8",
            text: `The State shall actually discourage marriage between persons where either
                of them is under the age of fifteen years.`,
          },
        ],
      },
      {
        number: "23",
        title: `Rights of Children`,
        content: [
          {
            number: "1",
            text: `All children, regardless of the circumstances of their birth, are entitled to
                equal treatment before the law.`,
          },
          {
            number: "2",
            text: `All children shall have the right to a given name and a family name and the
            right to a nationality.`,
          },
          {
            number: "3",
            text: `Children have the right to know, and to be raised by, their parents.`,
          },
          {
            number: "4",
            text: `Children are entitled to be protected from economic exploitation or any
            treatment, work or punishment that is, or is likely to -`,
            subsections: [
              {
                number: "(a)",
                text: `be hazardous;`,
              },
              {
                number: "(b)",
                text: `interfere with their education; or`,
              },
              {
                number: "(c)",
                text: `be harmful to their health or to their physical, mental or spiritual or
                    social development.`,
              },
            ],
          },
          {
            number: "5",
            text: `For purposes of this section, children shall be persons under sixteen years
            of age.`,
          },
        ],
      },
      {
        number: "24",
        title: `Rights of women`,
        content: [
          {
            number: "1",
            text: `Women have the right to full and equal protection by the law, and have the
                right not to be discriminated against on the basis of their gender or marital
                status which includes the right -`,
            subsections: [
              {
                number: "(a)",
                text: `to be accorded the same rights as men in civil law, including equal
                        capacity -`,
                subsections: [
                  {
                    number: "(i)",
                    text: `to enter into contracts;`,
                  },
                  {
                    number: "(ii)",
                    text: `to acquire and maintain rights in property, independently or in
                                association with others, regardless of their marital status;`,
                  },
                  {
                    number: "(iii)",
                    text: `to acquire and retain custody, guardianship and care of children
                                and to have an equal right in the making of decisions that affect
                                their upbringing; and`,
                  },
                  {
                    number: "(iv)",
                    text: `to acquire and retain citizenship and nationality.`,
                  },
                ],
              },
              {
                number: "(b)",
                text: `on the dissolution of marriage -`,
                subsections: [
                  {
                    number: "(i)",
                    text: `to a fair disposition of property that is held jointly with a
                                husband; and`,
                  },
                  {
                    number: "(ii)",
                    text: `to fair maintenance, taking into consideration all the
                                circumstances and, in particular, the means of the former
                                husband and the needs of any children.`,
                  },
                ],
              },
            ],
          },
          {
            number: "2",
            text: `Any law that discriminates against women on the basis of gender or marital
                status shall be invalid and legislation shall be passed to eliminate customs
                and practices that discriminate against women, particularly practices such
                as -`,
            subsections: [
              {
                number: "(a)",
                text: `sexual abuse, harassment and violence;`,
              },
              {
                number: "(b)",
                text: `discrimination in work, business and public affairs; and`,
              },
              {
                number: "(c)",
                text: `deprivation of property, including property obtained by inheritance.`,
              },
            ],
          },
        ],
      },
      {
        number: "25",
        title: `Education`,
        content: [
          { number: "1", text: `All persons are entitled to education.` },
          {
            number: "2",
            text: `Primary education shall consist of at least five years of education.`,
          },
          {
            number: "3",
            text: `Private schools and other private institutions of higher learning shall be
          permissible, provided that -`,
            subsections: [
              {
                number: "(a)",
                text: `such schools or institutions are registered with a State department in
                accordance with the law;`,
              },
              {
                number: "(b)",
                text: `the standards maintained by such schools or institutions are not
                inferior to official standards in State schools.`,
              },
            ],
          },
        ],
      },
      {
        number: "26",
        title: "Culture and language",
        content: [
          {
            number: "",
            text: "Every person shall have the right to use the language and to participate in the cultural life of his or her choice.",
          },
        ],
      },
      {
        number: "27",
        title: "Slavery, servitude and forced labour",
        content: [
          {
            number: "1",
            text: "No person shall be held in slavery or servitude.",
          },
          {
            number: "2",
            text: "Slavery and the slave trade are prohibited.",
          },
          {
            number: "3",
            text: "No person shall be subject to forced labour.",
          },
          {
            number: "4",
            text: "No person shall be subject to tied labour that amounts to servitude.",
          },
        ],
      },
      {
        number: "28",
        title: "Property",
        content: [
          {
            number: "1",
            text: "Every person shall be able to acquire property alone or in association with others.",
          },
          {
            number: "2",
            text: "No person shall be arbitrarily deprived of property.",
          },
        ],
      },
      {
        number: "29",
        title: "Economic activity",
        content: [
          {
            number: "",
            text: "Every person shall have the right freely to engage in economic activity, to work and to pursue a livelihood anywhere in Malawi.",
          },
        ],
      },
      {
        number: "30",
        title: "Right to development",
        content: [
          {
            number: "1",
            text: "All persons and peoples have a right to development and therefore to the enjoyment of economic, social, cultural and political development and women, children and the disabled in particular shall be given special consideration in the application of this right.",
          },
          {
            number: "2",
            text: "The State shall take all necessary measures for the realization of the right to development. Such measures shall include, amongst other things, equality of opportunity for all in their access to basic resources, education, health services, food, shelter, employment and infrastructure.",
          },
          {
            number: "3",
            text: "The State shall take measures to introduce reforms aimed at eradicating social injustices and inequalities.",
          },
          {
            number: "4",
            text: "The State has a responsibility to respect the right to development and to justify its policies in accordance with this responsibility.",
          },
        ],
      },
      {
        number: "31",
        title: "Labour",
        content: [
          {
            number: "1",
            text: "Every person shall have the right to fair and safe labour practices and to fair remuneration.",
          },
          {
            number: "2",
            text: "All persons shall have the right to form and join trade unions or not to form or join trade unions.",
          },
          {
            number: "3",
            text: "Every person shall be entitled to fair wages and equal remuneration for work of equal value without distinction or discrimination of any kind, in particular on basis of gender, disability or race.",
          },
          {
            number: "4",
            text: "The State shall take measures to ensure the right to withdraw labour.",
          },
        ],
      },
      {
        number: "32",
        title: "Freedom of association",
        content: [
          {
            number: "1",
            text: "Every person shall have the right to freedom of association, which shall include the freedom to form associations.",
          },
          {
            number: "2",
            text: "No person may be compelled to belong to an association.",
          },
        ],
      },
      {
        number: "33",
        title: "Freedom of conscience",
        content: [
          {
            number: "",
            text: "Every person has the right to freedom of conscience, religion, belief and thought, and to academic freedom.",
          },
        ],
      },
      {
        number: "34",
        title: "Freedom of opinion",
        content: [
          {
            number: "",
            text: "Every person shall have the right to freedom of opinion, including the right to hold opinions without interference to hold, receive and impart opinions.",
          },
        ],
      },
      {
        number: "35",
        title: "Freedom of expression",
        content: [
          {
            number: "",
            text: "Every person shall have the right to freedom of expression.",
          },
        ],
      },
      {
        number: "36",
        title: "Freedom of the press",
        content: [
          {
            number: "",
            text: "The press shall have the right to report and publish freely, within Malawi and abroad, and to be accorded the fullest possible facilities for access to public information.",
          },
        ],
      },
      {
        number: "37",
        title: "Access to information",
        content: [
          {
            number: "",
            text: "Subject to any Act of Parliament, every person shall have the right of access to all information held by the State or any of its organs at any level of Government in so far as such information is required for the exercise of his rights.",
          },
        ],
      },
      {
        number: "38",
        title: "Freedom of assembly",
        content: [
          {
            number: "",
            text: "Every person shall have the right to assemble and demonstrate with others peacefully and unarmed.",
          },
        ],
      },
      {
        number: "39",
        title: "Freedom of Movement and residence",
        content: [
          {
            number: "1",
            text: "Every person shall have the right of freedom of movement and residence within the borders of Malawi.",
          },
          {
            number: "2",
            text: "Every person shall have the right to leave the Republic and to return to it.",
          },
        ],
      },
      {
        number: "40",
        title: "Political rights",
        content: [
          {
            number: "1",
            text: "Subject to this Constitution, every person shall have the right -",
            subsections: [
              {
                number: "(a)",
                text: "to form, to join, to participate in the activities of, and to recruit members for, a political party;",
              },
              {
                number: "(b)",
                text: "to campaign for a political party or cause;",
              },
              {
                number: "(c)",
                text: "to participate in peaceful political activity intended to influence the composition and policies of the Government; and",
              },
              {
                number: "(d)",
                text: "freely to make political choices.",
              },
            ],
          },
          {
            number: "2",
            text: "The State shall provide funds so as to ensure that, during the life of any Parliament, any political party which has secured more than one-tenth of the national vote in elections to that Parliament has sufficient funds to continue to represent its constituency.",
          },
          {
            number: "3",
            text: "Save as otherwise provided in this Constitution, every person shall have the right to vote, to do so in secret and to stand for election for public office.",
          },
        ],
      },
      {
        number: "41",
        title: "Access to justice and legal remedies",
        content: [
          {
            number: "1",
            text: "Every person shall have a right to recognition as a person before the law.",
          },
          {
            number: "2",
            text: "Every person shall have access to any court of law or any other tribunal with jurisdiction for final settlement of legal issues.",
          },
          {
            number: "3",
            text: "Every person shall have the right to an effective remedy by a court of law or tribunal for acts violating the rights and freedoms granted to him by this Constitution or any other law.",
          },
        ],
      },
      {
        number: "42",
        title: "Arrest, detention and fair trial",
        content: [
          {
            number: "1",
            text: "Every person who is detained, including every sentenced prisoner, shall have the right -",
            subsections: [
              {
                number: "(a)",
                text: "to be informed of the reason for his or her detention promptly, and in a language which he or she understands;",
              },
              {
                number: "(b)",
                text: "to be detained under conditions consistent with human dignity, which shall include at least the provision of reading and writing materials, adequate nutrition and medical treatment at the expense of the State;",
              },
              {
                number: "(c)",
                text: "to consult confidentially with a legal practitioner of his or her choice, to be informed of this right promptly and, where the interests of justice so require, to be provided with the services of a legal practitioner by the State;",
              },
              {
                number: "(d)",
                text: "to be given the means and opportunity to communicate with, and to be visited by, his or her spouse, partner, next-of-kin, relative, religion counsellor and a medical practitioner of his or her choice;",
              },
              {
                number: "(e)",
                text: "to challenge the lawfulness of his or her detention in person or through a legal practitioner before a court of law; and",
              },
              {
                number: "(f)",
                text: "to be released if such detention is unlawful.",
              },
            ],
          },
          {
            number: "2",
            text: "Every person arrested for, or accused of, the alleged commission of an offence shall, in addition to the rights which he or she has as a detained person, have the right -",
            subsections: [
              {
                number: "(a)",
                text: "promptly to be informed, in a language which he or she understands, that he or she has the right to remain silent and to be warned of the consequences of making any statement;",
              },
              {
                number: "(b)",
                text: "as soon as it is reasonably possible, but not later than 48 hours after the arrest, or if the period of 48 hours expires outside ordinary court hours or on a day which is not a court day, the first court day after such expiry, to be brought before an independent and impartial court of law and to be charged or to be informed of the reason for his or her further detention, failing which he or she shall be released;",
              },
              {
                number: "(c)",
                text: "not to be compelled to make a confession or admission which could be used in evidence against him or her;",
              },
              {
                number: "(d)",
                text: "save in exceptional circumstances, to be segregated from convicted persons and to be subject to separate treatment appropriate to his or her status as an unconvicted person;",
              },
              {
                number: "(e)",
                text: "to be released from detention, with or without bail unless the interests of justice require otherwise;",
              },
              {
                number: "(f)",
                text: "as an accused person, to a fair trial, which shall include the right -",
                subsections: [
                  {
                    number: "(i)",
                    text: "to public trial before an independent and impartial court of law within a reasonable time after having been charged;",
                  },
                  {
                    number: "(ii)",
                    text: "to be informed with sufficient particularity of the charge;",
                  },
                  {
                    number: "(iii)",
                    text: "to be presumed innocent and to remain silent during plea proceedings or trial and not to testify during trial;",
                  },
                  {
                    number: "(iv)",
                    text: "to adduce and challenge evidence, and not to be a compellable witness against himself or herself;",
                  },
                  {
                    number: "(v)",
                    text: "to be represented by a legal practitioner of his or her choice or, where it is required in the interests of justice, to be provided with legal representation at the expense of the State, and to be informed of these rights;",
                  },
                  {
                    number: "(vi)",
                    text: "not to be convicted of an offence in respect of any act or omission which was not an offence at the time when the act was committed or omitted to be done, and not to be sentenced to a more severe punishment than that which was applicable when the offence was committed;",
                  },
                  {
                    number: "(vii)",
                    text: "not to be prosecuted again for a criminal act or omission of which he or she has previously been convicted or acquitted;",
                  },
                  {
                    number: "(viii)",
                    text: "to have recourse by way of appeal or review to a higher court than the court of first instance;",
                  },
                  {
                    number: "(ix)",
                    text: "to be tried in a language which he or she understands or, failing this, to have the proceedings interpreted to him or her, at the expense of the State, into a language which he or she understands; and",
                  },
                  {
                    number: "(x)",
                    text: "to be sentenced within a reasonable time after conviction;",
                  },
                ],
              },
              {
                number: "(g)",
                text: "in addition, if that person is a child, to treatment consistent with the special needs of children, which shall include the right -",
                subsections: [
                  {
                    number: "(i)",
                    text: "not to be sentenced to life imprisonment without possibility of release;",
                  },
                  {
                    number: "(ii)",
                    text: "to be imprisoned only as a last resort and for the shortest period of time;",
                  },
                  {
                    number: "(iii)",
                    text: "to be separated from adults when imprisoned, unless it is considered to be in his or her best interest not to do so, and to maintain contact with his or her family through correspondence and visits;",
                  },
                  {
                    number: "(iv)",
                    text: "to be treated in a manner consistent with the promotion of his or her sense of dignity and worth, which reinforces respect for the rights and freedoms of others;",
                  },
                  {
                    number: "(v)",
                    text: "to be treated in a manner which takes into account his or her age and the desirability of promoting his or her reintegration into society to assume a constructive role; and",
                  },
                  {
                    number: "(vi)",
                    text: "to be dealt with in a form of legal proceedings that reflects the vulnerability of children while fully respecting human rights and legal safeguards.",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        number: "43",
        title: "Administrative justice",
        content: [
          {
            number: "",
            text: "Every person shall have the right to -",
            subsections: [
              {
                number: "(a)",
                text: "lawful and procedurally fair administrative action, which is justifiable in relation to reasons given where his or her rights, freedoms, legitimate expectations or interests are affected or threatened; and",
              },
              {
                number: "(b)",
                text: "be furnished with reasons in writing for administrative action where his or her rights, freedoms, legitimate expectations or interests if those interests are known.",
              },
            ],
          },
        ],
      },
      {
        number: "44",
        title: "Limitations on rights",
        content: [
          {
            number: "1",
            text: "There shall be no derogation, restrictions or limitation with regard to -",
            subsections: [
              {
                number: "(a)",
                text: "the right to life;",
              },
              {
                number: "(b)",
                text: "the prohibition of torture and cruel, inhuman or degrading treatment or punishment;",
              },
              {
                number: "(c)",
                text: "the prohibition of genocide;",
              },
              {
                number: "(d)",
                text: "the prohibition of slavery, the slave trade and slave-like practices;",
              },
              {
                number: "(e)",
                text: "the prohibition of imprisonment for failure to meet contractual obligations;",
              },
              {
                number: "(f)",
                text: "the prohibition on retrospective criminalization and the retrospective imposition of greater penalties for criminal acts;",
              },
              {
                number: "(g)",
                text: "the right to equality and recognition before the law;",
              },
              {
                number: "(h)",
                text: "the right to freedom of conscience, belief, thought and religion and to academic freedom; or",
              },
              {
                number: "(i)",
                text: "the right to habeas corpus.",
              },
            ],
          },
          {
            number: "2",
            text: "Without prejudice to subsection (1), no restrictions or limitations may be placed on the exercise of any rights and freedoms provided for in this Constitution other than those prescribed by law, which are reasonable, recognized by international human rights standards and necessary in an open and democratic society.",
          },
          {
            number: "3",
            text: "Laws prescribing restrictions or limitations shall not negate the essential content of the right or freedom in question, shall be of general application.",
          },
          {
            number: "4",
            text: "Expropriation of property shall be permissible only when done for public utility and only when there has been adequate notification and appropriate compensation, provided that there shall always be a right to appeal to a court of law.",
          },
          {
            number: "5",
            text: "Wherever it is stated in this Constitution that a person has the right to the services of a legal practitioner or medical practitioner of his or her own choice, that right shall be without limitation, save where the State is obliged to provide such services of a legal practitioner or medical practitioner, in which case an Act of Parliament may prescribe that the choice of the legal practitioner or medical practitioner should be limited to those in Government service or employment.",
          },
        ],
      },
      {
        number: "45",
        title: "Derogation and public emergency",
        content: [
          {
            number: "1",
            text: "No derogation from rights contained in this Chapter shall be permissible save to the extent provided for by this section and no such derogation shall be made unless there has been a declaration of a state of emergency within the meaning of this section.",
          },
          {
            number: "2",
            text: "The President may declare a state of emergency -",
            subsections: [
              {
                number: "(a)",
                text: "only to the extent that it is provided for in this section;",
              },
              {
                number: "(b)",
                text: "only with the approval of the Defence and Security Committee of the National Assembly;",
              },
              {
                number: "(c)",
                text: "only in times of war, threat of war, civil war or widespread natural disaster;",
              },
              {
                number: "(d)",
                text: "only with regard to the specific location where that emergency exists, and that any declaration of a state of emergency shall be publicly announced; and",
              },
              {
                number: "(e)",
                text: "only after the state of emergency has been publicly announced.",
              },
            ],
          },
          {
            number: "3",
            text: "Derogation shall only be permissible during a state of emergency -",
            subsections: [
              {
                number: "(a)",
                text: "With respect to freedom of expression, freedom of information, freedom of movement, freedom of assembly and rights under section 19 (6) (a) and section 42 (2) (b);",
              },
              {
                number: "(b)",
                text: "to the extent that such derogation is not inconsistent with the obligations of Malawi under International Law; and",
              },
              {
                number: "(c)",
                text: "to the extent that -",
                subsections: [
                  {
                    number: "(i)",
                    text: "in the case of war or threat of war, it is strictly required to prevent the lives of defensive combatants and legitimate military objectives from being placed in direct jeopardy; or",
                  },
                  {
                    number: "(ii)",
                    text: "in the case of a widespread natural disaster, it is strictly required for the protection and relief of those people in the disaster area.",
                  },
                ],
              },
            ],
          },
          {
            number: "4",
            text: "The declaration of a state of emergency and any action taken in consequence thereof shall be in force for a period of not more than twenty-one days, unless it is extended for a period of not longer than three months, or consecutive periods of not longer than three months at a time, by resolution of the National Assembly adopted by a majority of at least two-thirds of all its members.",
          },
          {
            number: "5",
            text: "The High Court shall be competent to hear applications challenging the validity of a declaration of a state of emergency, any extension thereof, and any action taken, including any regulation enacted, under such declaration.",
          },
          {
            number: "6",
            text: "Where a person is detained under a state of emergency such detention shall be subject to the following conditions -",
            subsections: [
              {
                number: "(a)",
                text: "an adult family member or friend of the detainee shall be notified of the detention as soon as is reasonably possible and in any case not later than forty-eight hours of detention;",
              },
              {
                number: "(b)",
                text: "the name of every detainee and a reference to the measures in terms of which he or she is being detained shall be published in the Gazette within five days of his or her detention;",
              },
              {
                number: "(c)",
                text: "when rights entrenched in section 19 (6) (a) or section 42 (2) (b) have been suspended -",
                subsections: [
                  {
                    number: "(i)",
                    text: "the detention of a person shall as soon as it is reasonably possible but not later than ten days after his or her detention, be reviewed by a court, and the court shall order the release of the detainee if it is satisfied that the detention is not necessary to restore peace or order;",
                  },
                  {
                    number: "(ii)",
                    text: "a detainee shall at any stage after the expiry of a period of five days after a review under of subparagraph (i) be entitled to apply to a court of law for a further review of his or her detention, and the court shall order the release of the detainee if it is satisfied that the detention is no longer necessary to restore peace or order;",
                  },
                ],
              },
              {
                number: "(d)",
                text: "the State shall for the purpose of a review referred to in paragraph (c) submit written reasons to justify the detention or further detention of the detainee to the court, and shall furnish the detainee with such reasons not later than two days before the review.",
              },
            ],
          },
          {
            number: "7",
            text: "If a court finds the grounds for the detention of a person to be unjustified or illegal it shall order his or her release and that person shall not be detained again on the same grounds unless the State shows good cause to a court prior to such re-detention.",
          },
          {
            number: "8",
            text: `Under no circumstance shall it be possible to suspend this Constitution or
            any part thereof or dissolve any of its organs, save as is consistent with the
            provisions of this Constitution.`,
          },
        ],
      },
      {
        number: "46",
        title: "Enforcement",
        content: [
          {
            number: "1",
            text: "Save in so far as it may be authorized to do so by this Constitution, the National Assembly or any subordinate legislative authority shall not make any law, and the executive and the agencies of Government shall not take any action which abolishes or abridges the fundamental rights and freedoms conferred by this Chapter, and any law or action in contravention thereof shall, to the extent of the contravention, be invalid.",
          },
          {
            number: "2",
            text: "Any person who claims that a fundamental right or freedom guaranteed by this Constitution has been infringed or threatened shall be entitled -",
            subsections: [
              {
                number: "(a)",
                text: "to make application to a competent court to enforce or protect such a right or freedom; and",
              },
              {
                number: "(b)",
                text: "to make application to the Ombudsman or the Human Rights Commission in order to secure such assistance or advice as he or she may reasonably require.",
              },
            ],
          },
          {
            number: "3",
            text: "Where a court referred to in subsection (2) (a) finds that rights or freedoms conferred by this Constitution have been unlawfully denied or violated, it shall have the power to make any orders that are necessary and appropriate to secure the enjoyment of those rights and freedoms and where a court finds that a threat exists to such rights or freedoms, it shall have the power to make any orders necessary and appropriate to prevent those rights and freedoms from being unlawfully denied or violated.",
          },
          {
            number: "4",
            text: "A court referred to in subsection (2) (a) shall have the power to award compensation to any person whose rights or freedoms have been unlawfully denied or violated where it considers it to be appropriate in the circumstances of a particular case.",
          },
          {
            number: "5",
            text: "The law shall prescribe criminal penalties for violations of those non-derogable rights listed in subsection 44 (1).",
          },
        ],
      },
    ],
  },
  {
    id: "chapter5",
    title: "Chapter V: CITIZENSHIP",
    sections: [
      {
        number: "47",
        title: "Citizenship",
        content: [
          {
            number: "1",
            text: "Every person who, immediately before the appointed day, was a citizen of Malawi under any existing law shall continue to be a citizen of Malawi after the appointed day.",
          },
          {
            number: "2",
            text: "An Act of Parliament may make provision for the acquisition or loss of citizenship of Malawi by any person after the appointed day, but citizenship shall not be arbitrarily denied or deprived.",
          },
          {
            number: "3",
            text: "In this section, the expression -",
            subsections: [
              {
                number: "(a)",
                text: `"acquisition of citizenship" includes acquisition by birth, descent, marriage, registration, naturalisation or any other means prescribed by an Act of Parliament; and`,
              },
              {
                number: "(b)",
                text: `"loss of citizenship" includes loss by deprivation, renunciation or any other means prescribed by an Act of Parliament.`,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "chapter6",
    title: "Chapter VI: THE LEGISLATURE",
    sections: [
      {
        number: "48",
        title: "Parliament",
        content: [
          {
            number: "1",
            text: "All legislative powers of the Republic shall be vested in Parliament which shall have the powers and responsibilities set out in this Constitution.",
          },
          {
            number: "2",
            text: "An Act of Parliament shall have primacy over other forms of law, but shall be subject to the Constitution.",
          },
          {
            number: "3",
            text: "Any question proposed for decision by the National Assembly or by the Senate shall be decided by a majority of the votes of the members present and voting, unless this Constitution or any other Act of Parliament otherwise provides.",
          },
        ],
      },
      {
        number: "49",
        title: "Definitions",
        content: [
          {
            number: "1",
            text: `For the purposes of this Constitution, unless otherwise provided, "Parliament" consists of the National Assembly, the Senate and the President as Head of State.`,
          },
          {
            number: "2",
            text: `Unless otherwise provided in this Constitution, an "Act of Parliament" shall be a Bill which has -`,
            subsections: [
              {
                number: "(i)",
                text: "been laid before and passed by a majority of the National Assembly;",
              },
              {
                number: "(ii)",
                text: "been laid before and passed by a majority of the Senate; and",
              },
              {
                number: "(iii)",
                text: "been assented to by the President in accordance with this Chapter.",
              },
            ],
          },
          {
            number: "3",
            text: `"Chamber" means either the Chamber of the National Assembly or of the Senate.`,
          },
        ],
      },
      {
        number: "50",
        title: "Quorum",
        content: [
          {
            number: "1",
            text: "The quorum of each Chamber shall be formed by the presence at the beginning of any sitting of at least two thirds of the members of that Chamber entitled to vote, not including the Speaker or a presiding member.",
          },
          {
            number: "2",
            text: "If it is brought to the attention of the Speaker or person acting as Speaker by any member of the Chamber over which he or she is presiding that there are less than the number of members prescribed by the Standing Orders of that Chamber present and after such interval as may be prescribed in the rules of procedure of the Chamber, the Speaker or person acting as Speaker ascertains that the number of members present is still less than that prescribed by the Standing Orders of the Chamber, he or she shall adjourn the Chamber.",
          },
        ],
      },
      {
        number: "51",
        title: "Qualifications of Members of Parliament",
        content: [
          {
            number: "1",
            text: "A person shall not be qualified to be nominated or elected as a Member of the Parliament unless that person -",
            subsections: [
              {
                number: "(a)",
                text: "is a citizen of the Republic who at the time of nomination has attained-",
                subsections: [
                  {
                    number: "(i)",
                    text: "the age of twenty-one years, in the case of the National Assembly; and",
                  },
                  {
                    number: "(ii)",
                    text: "the age of thirty-five years, in the case of the Senate.",
                  },
                ],
              },
              {
                number: "(b)",
                text: "is able to speak and to read the English language well enough to take an active part in the proceedings of Parliament; and",
              },
            ],
          },
          {
            number: "2",
            text: "Notwithstanding subsection (1), no person shall be qualified to be nominated or elected as a member of Parliament who -",
            subsections: [
              {
                number: "(a)",
                text: "owes allegiance to a foreign country;",
              },
              {
                number: "(b)",
                text: "is, under any law in force in the Republic, adjudged or otherwise declared to be mentally incompetent;",
              },
              {
                number: "(c)",
                text: "has, within the last seven years, been convicted by a competent court of a crime involving dishonesty or moral turpitude;",
              },
              {
                number: "(d)",
                text: "is an undischarged bankrupt, having been adjudged or otherwise declared bankrupt under any law in force in the Republic;",
              },
              {
                number: "(e)",
                text: "holds, or acts, in any public office or appointment, except where this Constitution provides that a person shall not be disqualified from standing for election solely on account of holding that or appointment or where that person resigns from that office in order to stand;",
              },
              {
                number: "(f)",
                text: "belongs to, and is serving in the Defence Forces of Malawi or the Malawi Police Force; and",
              },
              {
                number: "(g)",
                text: "has, within the last seven years, been convicted by a competent court of any violation of any law relating to election of the President or election of members of Parliament.",
              },
            ],
          },
        ],
      },
      {
        number: "52",
        title: "Oath of allegiance",
        content: [
          {
            number: "",
            text: "Every officer and member of Parliament, before taking his or her seat, or assuming the duties of his or her office shall take and subscribe before the Chief Justice in the Chamber in which he or she shall sit -",
            subsections: [
              {
                number: "(a)",
                text: "the oath of allegiance in the form prescribed by law; and",
              },
              {
                number: "(b)",
                text: "such other oaths for the due performance of their respective offices as may be prescribed by law.",
              },
            ],
          },
        ],
      },
      {
        number: "53",
        title: "The Speaker",
        content: [
          {
            number: "1",
            text: "There shall be a Speaker of the National Assembly and a Speaker of the Senate who shall be elected by majority vote of the Chamber in which he or she sits at the first sitting after any dissolution of that Chamber.",
          },
          {
            number: "2",
            text: "The members of each Chamber shall elect one or more persons to be Deputy Speaker or Deputy Speakers at the first sitting after a general election in the case of the National Assembly or after a local election in the case of the Senate and after any event which results in a vacancy in the office of any Deputy Speaker.",
          },
          {
            number: "3",
            text: "The office of Speaker shall become vacant -",
            subsections: [
              {
                number: "(a)",
                text: "on the death or resignation of the Speaker;",
              },
              {
                number: "(b)",
                text: "if the Speaker ceases to be a member of the Chamber in which he or she sits;",
              },
              {
                number: "(c)",
                text: "if the Speaker becomes President, Vice-President, a Minister or a Deputy Minister or a member of the Senate; or",
              },
              {
                number: "(d)",
                text: "if the Chamber in which the Speaker presides, by a resolution supported by the votes of not less than two-thirds of all the members of that Chamber, resolves that the Speaker be removed from office:",
              },
            ],
          },
          {
            number: "",
            text: "Provided that the Speaker shall have the right to be heard by the Chamber on his or her own motion on any matter relating to his or her removal from office.",
          },
          {
            number: "4",
            text: "The Speaker of each Chamber, or in the absence of the Speaker, such Deputy Speaker of that Chamber as the Speaker has nominated, shall preside at every sitting of that Chamber:",
            subsections: [
              {
                number: "",
                text: "Provided that in the absence of the Speaker and of every Deputy Speaker, the Chamber may elect one among its members to act a Speaker for that session or that sitting.",
              },
            ],
          },
          {
            number: "5",
            text: "Any person who is elected Speaker, or any person acting as Speaker, shall discharge his or her functions and duties and exercise such powers as he or she has by virtue of that office independent of the direction or interference of any body or authority, save as accords with the express will and the Standing Orders of the Chamber in which he or she sits.",
          },
          {
            number: "6",
            text: "Notwithstanding that the Speaker or a person acting as Speaker has been elected as a member of a political party to the Chamber in which he or she sits he or she shall not be subject to the control, discipline, authority or direction of that political party or any other political party in the discharge of the functions and duties of that office and in the exercise of the powers of that office.",
          },
          {
            number: "7",
            text: "The Speaker may, where there is a matter which is the subject of debate in the Chamber over which he or she is presiding and which is a matter solely pertaining to his or her constituency, temporarily vacate the Speaker's seat and participate in that debate and exercise a deliberative vote where there is voting on that matter.",
          },
        ],
      },
      {
        number: "54",
        title: "Casting vote",
        content: [
          {
            number: "1",
            text: "Subject to subsection 53 (7) the Speaker of a Chamber shall not have a deliberative vote, but if the votes of the Chamber over which that Speaker is presiding are equally divided upon any question, he or she shall exercise the casting vote.",
          },
          {
            number: "2",
            text: "Any member of the National Assembly or Senate, other than the Speaker or Deputy Speaker, shall, when presiding in the Chamber of which he is a member, retain his or her original deliberative vote as a member and shall also have and exercise a casting vote if the votes of that Chamber are equally divided.",
          },
        ],
      },
      {
        number: "55",
        title: "The Clerk",
        content: [
          {
            number: "",
            text: "There shall be a Clerk to the National Assembly and a Clerk to the Senate who shall be public officers and shall assist the Speaker of the Chamber to which that Clerk is appointed and perform such other functions and duties as the Speaker may direct.",
          },
        ],
      },
      {
        number: "56",
        title: "The right to regulate procedure",
        content: [
          {
            number: "1",
            text: "Subject to this Constitution, the National Assembly, the Senate, or may by Standing Order or otherwise regulate its own procedure.",
          },
          {
            number: "3",
            text: "Save as otherwise provided in this Constitution, the National Assembly and the Senate may act unless more than two-thirds of all their seats are vacant.",
          },
          {
            number: "4",
            text: "The presence or participation of any person not entitled to be present or to participate in the proceedings of each Chamber shall not invalidate those proceedings.",
          },
          {
            number: "5",
            text: "Each Chamber shall provide access to the press and members of the public, except where a motion is passed with reasons prohibiting public access in the national interest.",
          },
          {
            number: "6",
            text: "The proceedings of Parliament shall be conducted in the English language and such other languages as each Chamber may prescribe in respect of its own proceedings.",
          },
          {
            number: "7",
            text: "Parliament may establish any committees of its members and may form joint committees for the scrutiny of legislation and performance of other functions, except voting on motions and Bills.",
          },
          {
            number: "8",
            text: "In addition to any committee appointed under subsection (6), there shall be a Public Appointments Committee, a Budget Committee and a Legal Affairs Committee of the National Assembly which shall each -",
            subsections: [
              {
                number: "(a)",
                text: "be appointed by the National Assembly with proportionate representation from all parties represented in the National Assembly;",
              },
              {
                number: "(b)",
                text: "appointed within thirty days of the first sitting of the National Assembly after a general election and thereafter annually; and",
              },
              {
                number: "(c)",
                text: "perform such functions as are conferred on them by this Constitution or by an Act of Parliament or by the Standing Orders of Parliament.",
              },
            ],
          },
        ],
      },
      {
        number: "57",
        title: "Money Bills",
        content: [
          {
            number: "1",
            text: "Except upon the recommendation of the Minister responsible for Finance, signified in writing, the National Assembly shall not -",
            subsections: [
              {
                number: "(a)",
                text: "proceed upon any Bill or any amendment to a Bill that, in the opinion of the person presiding, makes provision for any of the following purposes -",
                subsections: [
                  {
                    number: "(i)",
                    text: "for the imposition of tax or the alteration of tax;",
                  },
                  {
                    number: "(ii)",
                    text: "for the imposition of any charge upon the Consolidated Fund, or the alteration of any such charge;",
                  },
                  {
                    number: "(iii)",
                    text: "for the payment, issue or withdrawal from the Consolidated Fund of any moneys not charged thereon, or any increase in the amount of such payment, issue or withdrawal; or",
                  },
                  {
                    number: "(iv)",
                    text: "for the composition or remission of any debt due to the Government;",
                  },
                ],
              },
              {
                number: "(b)",
                text: "proceed upon any motion or any amendment to a motion the effect of which, in the opinion of the person presiding, would be to make provision for any of the purposes specified in subsection (a); or",
              },
              {
                number: "(c)",
                text: "receive any petition that, in the opinion of the person presiding, requests that provision be made for any of the purposes.",
              },
            ],
          },
          {
            number: "2",
            text: "The Senate shall not have the power to debate or vote upon any motion or receive any Bill to which this section applies except with the recommendation of the Minister responsible for Finance, signified in writing, and may not in any case amend or reject such a motion or Bill.",
          },
        ],
      },
      {
        number: "58",
        title: "Subsidiary legislation",
        content: [
          {
            number: "1",
            text: "Parliament may, with respect to any particular Act of Parliament, delegate to the executive or to the judiciary the power to make subsidiary legislation within the specification and for the purposes laid out in that Act and any subsidiary legislation so made shall be laid before Parliament in accordance with its Standing Orders.",
          },
          {
            number: "2",
            text: "Notwithstanding subsection (1), Parliament shall not have the power to delegate any legislative powers which would substantially and significantly affect the fundamental rights and freedoms recognized by this Constitution.",
          },
        ],
      },
      {
        number: "59",
        title: "Sessions and sittings",
        content: [
          {
            number: "1",
            text: "Every session of the National Assembly and of the Senate shall be held at such place within Malawi and shall commence at such time as each Speaker, in consultation with the President, may appoint with respect to the Chamber in which that Speaker presides and the sittings of each Chamber after the commencement of that session shall be held at such times and on such days as that Chamber shall appoint:",
            subsections: [
              {
                number: "",
                text: "Provided that -",
                subsections: [
                  {
                    number: "(a)",
                    text: "the President, in consultation with the Speaker of the relevant Chamber, may summon, on extraordinary occasions, a meeting of the National Assembly or the Senate; and",
                  },
                  {
                    number: "(b)",
                    text: "the President may, in consultation with the Speaker of the relevant Chamber, prorogue the National Assembly or the Senate.",
                  },
                ],
              },
            ],
          },
          {
            number: "2",
            text: "There shall be at least two sittings of the National Assembly and of the Senate every year.",
          },
        ],
      },
      {
        number: "60",
        title: "Privileges and immunities",
        content: [
          {
            number: "1",
            text: "The Speaker, every Deputy Speaker, every member of the National Assembly and every member of the Senate shall, except in cases of treason, be privileged from arrest while going to, returning from, or while in the precincts of the National Assembly or the Senate, and shall not, in respect of any utterance that forms part of the proceedings in the National Assembly or the Senate, be amenable to any other action or proceedings in any court, tribunal or body other than Parliament.",
          },
          {
            number: "2",
            text: "All official reports and publications of Parliament or of its proceedings or of the proceedings of any committee of the Parliament shall be privileged and utterances made in the Parliament or in any committee thereof wherever published shall be protected by absolute privilege.",
          },
          {
            number: "3",
            text: "The National Assembly and the Senate shall each have the power to conduct investigations and exercise the power to subpoena the attendance of any person or office holder whosoever as required in connexion with the prudent exercise of the respective functions of each Chamber.",
          },
        ],
      },
      {
        number: "61",
        title: "Member's interests",
        content: [
          {
            number: "1",
            text: "A member of the Parliament, where he or she has a direct or indirect material interest in a matter being discussed by the Chamber of which he or she is a member, shall -",
            subsections: [
              {
                number: "(a)",
                text: "disclose such interest to that Chamber; and",
              },
              {
                number: "(b)",
                text: "not be entitled to vote on that matter without leave of that Chamber.",
              },
            ],
          },
          {
            number: "2",
            text: "Where a member of Parliament fails to disclose a material interest in accordance with subsection (1) that member shall be guilty of contempt of the Chamber of which he or she is a member.",
          },
        ],
      },
      {
        number: "62",
        title: "Composition of the National Assembly",
        content: [
          {
            number: "1",
            text: "The National Assembly shall consist of such number of seats representing every constituency in Malawi, as shall be determined by the Electoral Commission.",
          },
          {
            number: "2",
            text: "Each constituency shall freely elect any person, subject to this Constitution and an Act of Parliament, to represent it as a member of the National Assembly in such manner as may be prescribed by this Constitution or an Act of Parliament.",
          },
        ],
      },
      {
        number: "63",
        title: "Vacancies in the National Assembly",
        content: [
          {
            number: "1",
            text: "The seat of a member of the National Assembly shall become vacant -",
            subsections: [
              {
                number: "(a)",
                text: "if the National Assembly has been dissolved;",
              },
              {
                number: "(b)",
                text: "if the member dies or resigns his or her seat;",
              },
              {
                number: "(c)",
                text: "if the member ceases to be a citizen of Malawi;",
              },
              {
                number: "(d)",
                text: "if the member assumes the office of President or Vice-President, or becomes a member of the Senate;",
              },
              {
                number: "(e)",
                text: "if any circumstances arise that, if he or she were not a member of the National Assembly, would cause that member to be disqualified for election under this Constitution or any other Act of Parliament;",
              },
              {
                number: "(f)",
                text: "if the National Assembly declares a member's seat vacant in accordance with such Standing Orders as may permit or prescribe the removal of a member for good and sufficient reason provided that they accord with the principles of natural justice; or",
              },
              {
                number: "(g)",
                text: "if a member is subject to recall by his or her constituency in accordance with the provisions of this Constitution or of an Act of Parliament.",
              },
            ],
          },
          {
            number: "2",
            text: "The Speaker of the National Assembly shall give notice in the Gazette in the event that the seat of any member of the Assembly shall become vacant under this section:",
            subsections: [
              {
                number: "",
                text: "Provided that -",
                subsections: [
                  {
                    number: "(a)",
                    text: "Parliament shall make provision for holding by-elections to fill any vacancy that shall occur;",
                  },
                  {
                    number: "(b)",
                    text: "any by-election to fill a vacancy that occurs shall be held within sixty days after the seat of the member becomes vacant or, if in the opinion of the Speaker the circumstances do not so admit, then as expeditiously as possible after the expiry of that period; and",
                  },
                  {
                    number: "(c)",
                    text: "any member elected at a by-election shall serve until such time as his or her seat becomes vacant in accordance with subsection (1).",
                  },
                ],
              },
            ],
          },
          {
            number: "3",
            text: "The Speaker may, upon a motion of the National Assembly, postpone the declaration of a vacant seat for such period as that motion prescribes so as to permit any member to appeal to a court or other body to which an appeal lies against a decision which would require that member to vacate his or her seat in accordance with this section.",
          },
        ],
      },
      {
        number: "64",
        title: "Recall of members",
        content: [
          {
            number: "",
            text: "* (Repealed by Act No. 6 of 1995).",
          },
        ],
      },

      {
        number: "65",
        title: "Crossing the floor",
        content: [
          {
            number: "1",
            text: "The Speaker shall declare vacant the seat of any member of the National Assembly who was, at the time of his or her election, a member of one political party represented in the National Assembly, other than by that member alone but who has voluntarily ceased to be a member of that party and has joined another political party represented in the National Assembly.",
          },
          {
            number: "2",
            text: "Notwithstanding subsection (1), all members of all parties shall have the absolute right to exercise a free vote in any and all proceedings of the National Assembly, and a member shall not have his or her seat declared vacant solely on account of his or her voting in contradiction to the recommendations of a political party, represented in the National Assembly, of which he or she is a member.",
          },
        ],
      },
      {
        number: "66",
        title: "Functions and powers of the National Assembly",
        content: [
          {
            number: "1",
            text: "The National Assembly shall be a directly elected Chamber the primary purpose of which shall be legislative and which shall have power, subject to this Constitution, to -",
            subsections: [
              {
                number: "(a)",
                text: "receive, amend, accept or reject Government Bills and Private Bills;",
              },
              {
                number: "(b)",
                text: "initiate Private Member's Bills on the motion of any member and amend, accept or reject all Private Member's Bills;",
              },
              {
                number: "(c)",
                text: "receive, amend, accept or reject any Bills remitted from the Senate;",
              },
              {
                number: "(d)",
                text: "debate and vote motions in relation to any matter including motions to indict and convict the President or Vice-President by impeachment;",
              },
              {
                number: "(e)",
                text: "exercise such other functions and powers as are conferred on it by this Constitution; and",
              },
              {
                number: "(f)",
                text: "take all actions incidental to and necessary for the proper exercise of its functions.",
              },
            ],
          },
          {
            number: "2",
            text: "For the purposes of this Constitution -",
            subsections: [
              {
                number: "(a)",
                text: "a Government Bill shall be a Bill promulgated by the Government and introduced to Parliament on behalf of the Government;",
              },
              {
                number: "(b)",
                text: "a Private Bill shall be -",
                subsections: [
                  {
                    number: "(i)",
                    text: "promulgated by an agency that is not part of the Government; and",
                  },
                  {
                    number: "(ii)",
                    text: "introduced to Parliament on behalf of that agency where that agency is mandated by an Act of Parliament so to do;",
                  },
                ],
              },
              {
                number: "(c)",
                text: "a Private Member's Bill shall be -",
                subsections: [
                  {
                    number: "(i)",
                    text: "promulgated by a member of Parliament; and",
                  },
                  {
                    number: "(ii)",
                    text: "introduced by that member in the Chamber of which he or she is a member in accordance with the procedure of that Chamber.",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        number: "67",
        title: "Dissolution of the National Assembly",
        content: [
          {
            number: "2",
            text: "The National Assembly shall stand dissolved on the 20th of March in the fifth year after its election, and the polling day for the general elections for the next National Assembly shall be the Tuesday in the third week of May that year:",
            subsections: [
              {
                number: "",
                text: "Provided that where it is not practicable for the polling to be held on the Tuesday in the third week of May, the polling shall be held on a day, within seven days from that Tuesday, appointed by the Electoral Commission.",
              },
            ],
          },
          {
            number: "3",
            text: "This section shall not preclude the Electoral Commission from setting other days for polling in the general election for special classes or categories of voters, provided that such polling takes place not more two days, before after the polling day, excluding Sundays.",
          },
          {
            number: "4",
            text: "The first meeting of the National Assembly shall commence on a date to be appointed by the President occurring within forty-five days after the polling day or, where polling takes place on more than one day, within forty-five days after the last polling day.",
          },
          {
            number: "5",
            text: "If, between the dissolution of the National Assembly and the general election that follows, the President is of the opinion that a constitutional crisis or emergency has arisen which requires urgent legislation or consideration by the National Assembly, he or she may reconvene the National Assembly for that purpose alone but in any event, that reconvened National Assembly shall stand dissolved on the date of the general election.",
          },
          {
            number: "6",
            text: "Notwithstanding the dissolution of the National Assembly on the date specified in subsection (1), every person who, immediately before the dissolution of the National Assembly in accordance with subsection (1), is a member of the National Assembly shall be entitled to receive his or her remuneration and other benefits up to and including the last day preceding the general election.",
          },
        ],
      },
      {
        number: "68",
        title: "Composition of the Senate",
        content: [
          {
            number: "1",
            text: "The Senate shall consist of eighty members as follows -",
            subsections: [
              {
                number: "(a)",
                text: "one Senator from each District, registered as a voter in that District and elected by the District Council of that District in secret ballot within thirty days of each local government election;",
              },
              {
                number: "(b)",
                text: "one Senator from each District, being a Chief registered as a voter in that District and elected by a caucus of all the Chiefs of that District in secret ballot within thirty days of each local government election;",
              },
              {
                number: "(c)",
                text: "thirty-two other Senators who shall be elected by a two-thirds majority of sitting members of the Senate on the basis of nominations by the Nominations Committee provided for in subsection (2) from all of the following sectors -",
                subsections: [
                  {
                    number: "(i)",
                    text: "interest groups, who shall include representatives from women's organizations, the disabled and from health, education, farming and business sectors, and from trade unions;",
                  },
                  {
                    number: "(ii)",
                    text: "society, who shall be such persons as are generally recognized for their outstanding service to the public or contribution to the social, cultural, or technological development of the nation; and",
                  },
                  {
                    number: "(iii)",
                    text: "religion, who shall include representatives of the major religious faiths in Malawi.",
                  },
                ],
              },
            ],
          },
          {
            number: "2",
            text: "There shall be a Nominations Committee of the Senate which shall be formed within forty-five days of each local government election for the purpose of nominating the representatives referred to in subsection (1) (c) and which shall consist of the Speaker of the National Assembly, the Ombudsman, and seven members, being Senators elected under subsections (1) (a) or (b), appointed by the National Assembly on a motion by the Speaker of the National Assembly.",
          },
          {
            number: "3",
            text: "A Senator may be elected or nominated for an indefinite number of subsequent terms, unless otherwise disqualified or removed.",
          },
          {
            number: "4",
            text: "The Nominations Committee shall endeavour to ensure, when considering nominations, that the Senate is proportionally representative of the various groups in Malawian society and therefore shall seek to ensure, so far as it is possible, that one-half of the members of the Senate are women.",
          },
        ],
      },

      {
        number: "69",
        title: "Vacancies in the Senate",
        content: [
          {
            number: "1",
            text: "The seat of a member of the Senate shall become vacant -",
            subsections: [
              {
                number: "(a)",
                text: "if the Senate has been dissolved;",
              },
              {
                number: "(b)",
                text: "if a member dies or resigns his or her seat;",
              },
              {
                number: "(c)",
                text: "if a member ceases to be a citizen of the Republic;",
              },
              {
                number: "(d)",
                text: "if a member assumes the office of President or Vice-President, Minister or Deputy Minister or becomes a member of the National Assembly;",
              },
              {
                number: "(e)",
                text: "if any circumstances arise that, if he or she were not a member of the Senate, would cause that member to be disqualified for nomination or election under this Constitution or an Act of Parliament; or",
              },
              {
                number: "(f)",
                text: "if the Senate declares a member's seat vacant in accordance with such rules and Standing Orders as may permit or provide for the removal of a member for good and sufficient reason provided that they accord with the principles of natural justice.",
              },
            ],
          },
          {
            number: "2",
            text: "The Speaker of the Senate shall give notice to the Electoral Commission and in the Gazette in the event that the seat of any member of the Senate becomes vacant under this section.",
          },
          {
            number: "3",
            text: "Where the seat of a member of the Senate is declared vacant by virtue of this section -",
            subsections: [
              {
                number: "(a)",
                text: "if that member was elected by a District Council, then the Electoral Commission shall notify the Council of that District which elected that member to declare an election within thirty days of the seat becoming vacant;",
              },
              {
                number: "(b)",
                text: "if that member was elected by Chiefs, then the Speaker of the Senate shall notify the Chiefs of the District from where that member was elected so as to convene the relevant caucus of Chiefs for the election of another member;",
              },
              {
                number: "(c)",
                text: "if that member is a sector representative, then the Speaker of the Senate shall convene the Nominations Committee of the Senate which shall put forward nominations for appointment to the Senate.",
              },
            ],
          },
        ],
      },
      {
        number: "70",
        title: "Functions and powers of the Senate",
        content: [
          {
            number: "",
            text: "The Senate shall be an indirectly elected chamber purpose the primary of which shall be deliberative and which shall have power, subject to this Constitution, to -",
            subsections: [
              {
                number: "(a)",
                text: "receive, scrutinize and amend Bills from the National Assembly;",
              },
              {
                number: "(b)",
                text: "vote motions to confirm or remit Bills passed by the National Assembly;",
              },
              {
                number: "(c)",
                text: "debate any issue on its own motion, initiate Private Member's Bills and vote motions in respect of any matter, including motions to indict or convict the President or Vice-President by impeachment;",
              },
              {
                number: "(d)",
                text: "exercise such other functions and powers as are conferred on it by this Constitution;",
              },
              {
                number: "(e)",
                text: "carry out such other functions as may be delegated to it by an Act of Parliament; and",
              },
              {
                number: "(f)",
                text: "take all actions incidental to and necessary for the proper exercise of its functions.",
              },
            ],
          },
        ],
      },
      {
        number: "71",
        title: "Scrutiny by the Senate",
        content: [
          {
            number: "1",
            text: "All Bills shall be laid before the Senate.",
          },
          {
            number: "2",
            text: "Any member of the Senate may, in respect of a Bill laid before the Senate -",
            subsections: [
              {
                number: "(a)",
                text: "within fourteen days of that Bill being laid, raise a motion to debate that Bill in full readings; or",
              },
              {
                number: "(b)",
                text: "after fourteen days, but before the lapse of forty days, raise a motion to remit the Bill to the National Assembly.",
              },
            ],
          },
          {
            number: "3",
            text: "Any Bill laid before the Senate which has not been the subject of a motion to debate within the meaning of this section shall after the lapse of forty days, be presented for assent by the President.",
          },
          {
            number: "4",
            text: "Where a Bill is debated under subsection (2) (a), it shall be passed back to the Speaker of the National Assembly who shall certify that it is -",
            subsections: [
              {
                number: "(a)",
                text: "without amendment, in which case the Speaker shall present it for assent by the President; or",
              },
              {
                number: "(b)",
                text: "amended, in which case the Bill shall be laid before the National Assembly for fourteen days, provided that if no motion to debate the Bill in full is raised by any member of the National Assembly within that time it shall be presented in amended form for assent by the President.",
              },
            ],
          },
          {
            number: "5",
            text: "Where a Bill has been remitted by the Senate by virtue of a majority vote in favour of a motion under subsection (2) (b) -",
            subsections: [
              {
                number: "(a)",
                text: "the Senate shall give written reasons for that remittance; and",
              },
              {
                number: "(b)",
                text: "the Speaker of the National Assembly shall table the Bill which may be further debated and amended, and if passed by a majority of all the members of the National Assembly, may be presented for assent by the President.",
              },
            ],
          },
          {
            number: "6",
            text: "Where a Private Member's Bill has been initiated and passed by the Senate it shall be sent to the Speaker of the National Assembly who shall table that Bill which may be further debated and amended and, if passed by a majority of the National Assembly, the Bill shall be presented for assent by the President.",
          },
        ],
      },
      {
        number: "72",
        title: "Dissolution of the Senate",
        content: [
          {
            number: "",
            text: "The Senate shall continue from the date of its first sitting, being no later than thirty days after a Local Government election after any dissolution, until it dissolves sixty days before the next Local Government elections:",
            subsections: [
              {
                number: "",
                text: "Provided that the life of the Senate shall not, in any case, be longer than three years.",
              },
            ],
          },
        ],
      },
      {
        number: "73",
        title: "Presidential assent",
        content: [
          {
            number: "1",
            text: "Where a Bill is presented to the President for assent, the President shall either assent or withhold assent and shall do so within twenty-one days from the date the Bill is presented to him or her.",
          },
          {
            number: "2",
            text: "Where the President withholds assent to a Bill, the Bill shall be returned to the Speaker of the National Assembly by the President with a notification that the President's assent has been withheld, including reasons therefor, and the Bill shall not be again debated by the National Assembly until after the expiry of twenty-one days from the date of the notification of that withholding.",
          },
          {
            number: "3",
            text: "If the Bill is debated again and passed by a majority of the National Assembly at any time between the date of the expiry of the twenty-one days referred to in subsection (2) and three months from that date, the Bill shall again be presented for assent by the President.",
          },
          {
            number: "4",
            text: "Where a Bill is again presented to the President for assent in accordance with subsection (3), the President shall assent to the Bill within twenty-one days of its presentation.",
          },
          {
            number: "5",
            text: "When a Bill that has been duly passed is assented to in accordance with this Constitution, the Clerk shall cause it to be published immediately in the Gazette.",
          },
        ],
      },
      {
        number: "74",
        title: "The coming into force of laws",
        content: [
          {
            number: "",
            text: "No law made by Parliament shall come into force until it has been published in the Gazette, but Parliament may prescribe that a law shall not come into force until some later date, after its publication in the Gazette.",
          },
        ],
      },
    ],
  },

  {
    id: "chapter7",
    title: "Chapter VII: ELECTIONS",
    sections: [
      {
        number: "75",
        title: "The Electoral Commission",
        content: [
          {
            number: "1",
            text: "There shall be an Electoral Commission which shall consist of a Chairman who shall be a Judge nominated in that behalf by the Judicial Service Commission and such other members, not being less than six, as may be appointed in accordance with an Act of Parliament.",
          },
          {
            number: "2",
            text: "A person shall not be qualified to hold the office of a member of the Electoral Commission if that person is a Minister, Deputy Minister, a member of Parliament or a person holding public office.",
          },
          {
            number: "3",
            text: "Subject to this section, a person shall cease to be a member of the Electoral Commission -",
            subsections: [
              {
                number: "(a)",
                text: "at the expiration of four years from the date of his or her appointment, unless re-appointed to a new four-year term; or",
              },
              {
                number: "(b)",
                text: "if any circumstances arise that, if that person were not a member of the Electoral Commission, he or she would be disqualified for appointment as such.",
              },
            ],
          },
          {
            number: "4",
            text: "A member of the Electoral Commission may be removed from office by the President on the recommendation of the Public Appointments Committee on the grounds of incapacity or incompetence in the performance of the duties of that office.",
          },
        ],
      },
      {
        number: "76",
        title: "Powers and functions",
        content: [
          {
            number: "1",
            text: "The Electoral Commission shall exercise such functions in relation to elections as are conferred upon it by this Constitution or by an Act of Parliament.",
          },
          {
            number: "2",
            text: "The duties and functions of the Electoral Commission shall include -",
            subsections: [
              {
                number: "(a)",
                text: "to determine constituency boundaries impartially on the basis of ensuring that constituencies contain approximately equal numbers of voters eligible to register, subject only to considerations of -",
                subsections: [
                  {
                    number: "(i)",
                    text: "population density;",
                  },
                  {
                    number: "(ii)",
                    text: "ease of communication; and",
                  },
                  {
                    number: "(iii)",
                    text: "geographical features and existing administrative areas;",
                  },
                ],
              },
              {
                number: "(b)",
                text: "to review existing constituency boundaries at intervals of not more than five years and alter them in accordance with the principles laid down in subsection (2) (a);",
              },
              {
                number: "(c)",
                text: "to determine electoral petitions and complaints related to the conduct of any elections;",
              },
              {
                number: "(d)",
                text: "to ensure compliance with the provisions of this Constitution and any other Act of Parliament; and",
              },
              {
                number: "(e)",
                text: "to perform such other functions as may be prescribed by this Constitution or an Act of Parliament.",
              },
            ],
          },
          {
            number: "3",
            text: "Any person who has petitioned or complained to the Electoral Commission shall have a right to appeal to the High Court against determinations made under subsections (2) (c) and (2) (d).",
          },
          {
            number: "4",
            text: "The Electoral Commission shall exercise its powers, functions and duties under this section independent of any direction or interference by other authority or any person.",
          },
          {
            number: "5",
            text: "Without prejudice to subsection (3) -",
            subsections: [
              {
                number: "(a)",
                text: "the High Court shall have jurisdiction to entertain applications for judicial review of the exercise by the Electoral Commission of its powers and functions to ensure that such powers and functions were duly exercised in accordance with this Constitution or any Act of Parliament; and",
              },
              {
                number: "(b)",
                text: "the National Assembly shall confirm all determinations by the Electoral Commission with regard to the drawing up of constituency boundaries but may not alter the boundaries of any constituency, except upon the recommendation of the Electoral Commission.",
              },
            ],
          },
        ],
      },
      {
        number: "77",
        title: "The franchise",
        content: [
          {
            number: "1",
            text: "All persons shall be eligible to vote in any general election, by-election, presidential election, local government election or referendum, subject only to this section.",
          },
          {
            number: "2",
            text: "Subject to subsection (3), a person shall be qualified to be registered as a voter in a constituency if, and shall not be so qualified unless, at the date of the application for registration that person -",
            subsections: [
              {
                number: "(a)",
                text: "is a citizen of Malawi or, if not a citizen, has been ordinarily resident in the Republic for seven years;",
              },
              {
                number: "(b)",
                text: "has attained the age of eighteen years; and",
              },
              {
                number: "(c)",
                text: "is ordinarily resident in that constituency or was born there or is employed or carries on a business there.",
              },
            ],
          },
          {
            number: "3",
            text: "No person shall be qualified for registration as a voter in a constituency if that person -",
            subsections: [
              {
                number: "(a)",
                text: "is under any law in force in the Republic adjudged or otherwise declared to be mentally incompetent;",
              },
              {
                number: "(b)",
                text: "is under sentence of death imposed by a court having jurisdiction in the Republic, either before or after the appointed day; or",
              },
              {
                number: "(c)",
                text: "is disqualified from registration as a voter on the grounds of his or her having been convicted of any violation of any law relating to elections passed by Parliament and in force at the time of, or after, the commencement of this Constitution, but such disqualification shall be valid only with respect to registration for the election in question and the person so disqualified shall be qualified to be registered as a voter in the next or any subsequent election.",
              },
            ],
          },
          {
            number: "4",
            text: "Where any person is qualified to be registered in more than one constituency as a voter, he or she may be so registered only in one of the constituencies.",
          },
          {
            number: "5",
            text: "No person shall exercise more than one vote in any one election.",
          },
        ],
      },
    ],
  },

  {
    id: "chapter8",
    title: "Chapter VIII: THE EXECUTIVE",
    sections: [
      {
        number: "78",
        title: "The President",
        content: [
          {
            number: "",
            text: "There shall be a President of the Republic who shall be Head of State and Government and the Commander-in-Chief of the Defence Forces of Malawi.",
          },
        ],
      },
      {
        number: "79",
        title: "Vice-Presidents",
        content: [
          {
            number: "",
            text: "There shall be a First Vice-President and, subject to section 80 (5), a Second Vice-President both of whom shall assist the President and who shall exercise the powers and perform the functions conferred on the First Vice-President or the Second Vice-President, as the case may be, by this Constitution or by any Act of Parliament and by the President.",
          },
        ],
      },
      {
        number: "80",
        title: "Election of the President and the Vice-President",
        content: [
          {
            number: "1",
            text: "The President shall be elected in accordance with the provisions of this Constitution in such manner as may be prescribed by Act of Parliament and, save where this Constitution provides otherwise, the ballot in a Presidential election shall take place concurrently with the general election for members of the National Assembly as prescribed by section 67 (1).",
          },
          {
            number: "2",
            text: "The President shall be elected by a majority of the electorate through direct, universal and equal suffrage.",
          },
          {
            number: "3",
            text: "Every presidential candidate shall declare who shall be his or her First Vice-President if he or she is elected at the time of his or her nomination.",
          },
          {
            number: "4",
            text: "The First Vice-President shall be elected concurrently with the President and the name of a candidate for the First Vice-President shall appear on the same ballot paper as the name of the Presidential candidate who nominated him.",
          },
          {
            number: "5",
            text: "Where the President considers it desirable in the national interest so to do, he or she may appoint a person to the office of Second Vice-President and may do so upon taking his or her oath of office or at any time thereafter or upon a vacancy in the office of Second Vice-President; and, where no person has been appointed to the office of Second Vice-President then -",
            subsections: [
              {
                number: "(a)",
                text: "the provisions of this Chapter making reference to that office shall be read mutatis mutandis; and",
              },
              {
                number: "(b)",
                text: "the office of First Vice-President shall be known as the office of Vice-President as if section 79 created the office of a Vice-President only:",
              },
            ],
          },
          {
            number: "",
            text: "Provided that where the President was elected on the sponsorship of a political party, then he or she shall not appoint a Second Vice-President from that political party.",
          },
          {
            number: "6",
            text: "Notwithstanding any provision of this Constitution to the contrary, a person shall only be qualified for nomination for election as President or First Vice-President or for appointment as First Vice-President or Second Vice-President if that person-",
            subsections: [
              {
                number: "(a)",
                text: "is a citizen of Malawi by birth or descent; and",
              },
              {
                number: "(b)",
                text: "has attained the age of thirty-five years.",
              },
            ],
          },
          {
            number: "7",
            text: "No person shall be eligible for nomination as a candidate for election as President or First Vice-President or for appointment as first Vice-President or Second Vice-President if that person-",
            subsections: [
              {
                number: "(a)",
                text: "has been adjudged or declared to be of unsound mind;",
              },
              {
                number: "(b)",
                text: "is an undischarged bankrupt having been declared bankrupt under a law of the Republic;",
              },
              {
                number: "(c)",
                text: "has, within the last seven years, been convicted by a competent court of a crime involving dishonesty or moral turpitude;",
              },
              {
                number: "(d)",
                text: "owes allegiance to a foreign country;",
              },
              {
                number: "(e)",
                text: "is the holder of a public office or a member of Parliament, unless that person first resigns;",
              },
              {
                number: "(f)",
                text: "is a serving Member of the Defence Forces or Malawi Police Force; or",
              },
              {
                number: "(g)",
                text: "has, within the last seven years, been convicted by a competent court of any violation of any law relating to election of the President or election of the members of Parliament.",
              },
            ],
          },
        ],
      },
      {
        number: "81",
        title: "Oath of office",
        content: [
          {
            number: "1",
            text: "Before a person elected to be President or First Vice-President or appointed to be First Vice-President or Second Vice-President takes office that person shall take the following oath which shall be administered in public by the Chief Justice-",
            subsections: [
              {
                number: "",
                text: '"I.......do solemnly swear that I will well and truly perform the functions of the high office of President (or Vice-President) of the Republic of Malawi, and that I will preserve and defend the Constitution, and that I will do right to all manner of people according to law without fear or favour, affection or ill-will. So help me God."',
              },
            ],
          },
          {
            number: "2",
            text: 'Instead of taking an oath, the President, First Vice-President or Second Vice-President may, if he or she thinks fit, make an affirmation which shall be in the like form with the substitution of "affirm" for "swear", and the omission of the final sentence.',
          },
          {
            number: "3",
            text: "A person elected to be President or appointed to be First Vice-President or Second Vice-President shall be sworn into office, in accordance with sub-section (1), within thirty days of being elected or appointed.",
          },
          {
            number: "4",
            text: "The President, First Vice-President and Second Vice-President shall hold office until such time as his or her successor is sworn in.",
          },
        ],
      },
      {
        number: "82",
        title: "Remuneration",
        content: [
          {
            number: "",
            text: "The President, First Vice-President and Second Vice-President shall receive such salary, allowance or pension as may, from time to time, be determined by an Act of Parliament in consultation with the President and shall have such adequate number of residences and personal staff, at State expense, as an Act of Parliament may prescribe.",
          },
        ],
      },
      {
        number: "83",
        title: "Tenure of office",
        content: [
          {
            number: "1",
            text: "The President shall hold office for five years from the date that his or her oath of office is administered, but shall continue in office until his or her successor has been sworn in.",
          },
          {
            number: "2",
            text: "The First Vice-President and the Second Vice-President shall hold office from the date of the administration of the oath of office to them until the end of the President's term of office unless their office should come to an end sooner in accordance with the provisions of this Constitution.",
          },
          {
            number: "3",
            text: "The President, the First Vice-President and the Second Vice-President may serve in their respective capacities a maximum of two consecutive terms, but when a person is elected or appointed to fill a vacancy in the office of President or Vice- President, the period between that election or appointment and the next election of a President shall not be regarded as a term.",
          },
          {
            number: "4",
            text: "Whenever there is a vacancy in the office of President, the First Vice-President shall assume that office for the remainder of the term and shall appoint another person to serve as First Vice-President for the remainder of the term.",
          },
        ],
      },
      {
        number: "84",
        title: "Death or resignation Vice-President",
        content: [
          {
            number: "",
            text: "If the First Vice-President and Second Vice-President dies or resigns from office, the vacancy shall be filled for the unexpired period of that term by a person appointed by the President.",
          },
        ],
      },
      {
        number: "85",
        title: "Vacancy of office of President and Vice-President",
        content: [
          {
            number: "",
            text: "If at any time both the office of President and First Vice- President become vacant then the Cabinet shall elect from among its members an Acting President and Acting First Vice-President who shall hold office for not more than sixty days or, where four years of a Presidential term have expired, for the rest of that Presidential term.",
          },
        ],
      },
      {
        number: "86",
        title: "Removal from office",
        content: [
          {
            number: "1",
            text: "The President or First Vice-President shall be removed from office where the President or First Vice-President, as the case may be, has been indicted and convicted by impeachment.",
          },
          {
            number: "2",
            text: "The procedure for impeachment shall be as laid down by the Standing Orders of Parliament, provided that they are in full accord with the principles of natural justice and that -",
            subsections: [
              {
                number: "(a)",
                text: "indictment and conviction by impeachment shall only be on the grounds of serious violation of the Constitution or serious breach of the written laws of the Republic that either occurred or came to light during the term of office of the President or the First Vice-President;",
              },
              {
                number: "(b)",
                text: "indictment on impeachment shall require the affirmative vote of two-thirds of the members of the National Assembly in a committee of the whole house;",
              },
              {
                number: "(c)",
                text: "conviction on impeachment shall require the affirmative vote of two-thirds of the members of both Chambers;",
              },
              {
                number: "(d)",
                text: "conviction in cases of impeachment shall cause the removal, and disqualification from future office, of the office holder; and",
              },
              {
                number: "(e)",
                text: "conviction by way of impeachment shall not act as a bar to legal proceedings.",
              },
            ],
          },
          {
            number: "3",
            text: "The President shall have power to remove the Second Vice-President from office.",
          },
        ],
      },
      {
        number: "87",
        title: "Incapacity",
        content: [
          {
            number: "1",
            text: "Whenever the President is incapacitated so as to be unable to discharge the powers and duties of that office, the First Vice-President shall act as President, until such time, in the President's term of office, as the President is able to resume his or her functions.",
          },
          {
            number: "2",
            text: "The President shall not be deemed to be incapacitated for the purposes of this section until and unless -",
            subsections: [
              {
                number: "(a)",
                text: "there is a written declaration, certified by a board of independent medical practitioners, that the President is unable to discharge the duties of the office of President;",
              },
              {
                number: "(b)",
                text: "the declaration is signed by the First Vice-President and a majority of the Cabinet, holding office at that time; and",
              },
              {
                number: "(c)",
                text: "the declaration is submitted by the First Vice-President to the Speaker of the National Assembly.",
              },
            ],
          },
          {
            number: "3",
            text: "Upon submission to the Speaker of a declaration under subsection (2), the First Vice-President shall immediately assume the powers and duties of the office of President as Acting President.",
          },
          {
            number: "4",
            text: "Where the President has been declared to be incapacitated in accordance with subsection (2) the President may, at any time thereafter, submit to the National Assembly a written declaration, certified by a board of independent medical practitioners, stating his or her fitness to carry on the duties of the office of President:",
            subsections: [
              {
                number: "",
                text: "Provided that -",
                subsections: [
                  {
                    number: "(a)",
                    text: "upon receipt of such a declaration from the President, the National Assembly shall have thirty days within which to determine whether or not the President is in fact or not so incapacitated as to be unable to discharge the duties of the office of President; and",
                  },
                  {
                    number: "(b)",
                    text: "if the National Assembly determines that the President remains so incapacitated so as to be unable to discharge the duties of the office of President, by an affirmative vote of two-thirds of all of its members, the First Vice-President shall continue to act as President until the National Assembly determines that the President is again fit to assume the duties and powers of the office of President; or",
                  },
                  {
                    number: "(c)",
                    text: "if the National Assembly determines that the President is no longer so incapacitated as to be unable to discharge the duties of the office of President by an affirmative vote of two-thirds of the National Assembly, the President shall resume the duties of the office of President within thirty days of that vote.",
                  },
                ],
              },
            ],
          },
          {
            number: "5",
            text: "If, on the expiry of twelve months from the date of the Speaker being notified of the incapacity of the President, the National Assembly has not determined that the President is fit to perform duties of the office of President in accordance with subsection (4), then a Presidential election shall be held.",
          },
          {
            number: "6",
            text: "The provisions of this section shall apply mutatis mutandis to the incapacity of the First Vice-President except that the certificate shall then be signed by the President and be submitted to the Speaker by the President and the functions of the First Vice-President shall be carried out by such other member of Cabinet as the President may appoint.",
          },
          {
            number: "7",
            text: "Every board of independent medical practitioners required for the purposes of this section shall be selected in accordance with the procedure laid down in the Standing Orders of Parliament.",
          },
        ],
      },
      {
        number: "88",
        title: "Responsibility of the President",
        content: [
          {
            number: "1",
            text: "The President shall be responsible for the observance of the provisions of this Constitution by the executive and shall, as Head of State, defend and uphold the Constitution as the supreme law of the Republic.",
          },
          {
            number: "2",
            text: "The President shall provide executive leadership in the interest of national unity in accordance with this Constitution and the laws of the Republic.",
          },
          {
            number: "3",
            text: "The President and members of the Cabinet shall not hold any other public office and shall not perform remunerative work outside the duties of their office and shall, within three months from the date of election or appointment, as the case may be, fully disclose all of their assets, liabilities and business interests, and those of their spouses, held by them or on their behalf as at that date; and, unless Parliament otherwise prescribes by an Act of Parliament, such disclosure shall be made in a written document delivered to the Speaker of the National Assembly who shall immediately upon receipt deposit the document with such public office as may be specified in the Standing Orders of Parliament.",
          },
          {
            number: "4",
            text: "Any business interests held by the President and members of the Cabinet shall be held on their behalf in a beneficial trust which shall be managed in such manner as to ensure conformity with this section.",
          },
          {
            number: "5",
            text: `The President and members of the Cabinet shall not use their
            respective offices for personal gain or place themselves in a
            situation where their material interests conflict with the
            responsibilities and duties of their offices.`,
          },
        ],
      },
      {
        number: "89",
        title: "Powers and duties of the President",
        content: [
          {
            number: "1",
            text: "The President shall have the following powers and duties -",
            subsections: [
              {
                number: "(a)",
                text: "to assent to Bills and promulgate Bills duly passed by Parliament;",
              },
              {
                number: "(b)",
                text: "to convene and preside over meetings of the Cabinet;",
              },
              {
                number: "(c)",
                text: "to confer honours;",
              },
              {
                number: "(d)",
                text: "to make such appointments as may be necessary in accordance with powers conferred upon him or her by this Constitution or an Act of Parliament;",
              },
              {
                number: "(e)",
                text: "subject to this Constitution, to appoint, accredit, receive and recognize ambassadors, high commissioners, plenipotentiaries, diplomatic representatives and other diplomatic officers, consuls and consular officers;",
              },
              {
                number: "(f)",
                text: "to negotiate, sign, enter into and accede to international agreements or to delegate such power to ministers, ambassadors and high commissioners;",
              },
              {
                number: "(g)",
                text: "to appoint commissions of inquiry;",
              },
              {
                number: "(h)",
                text: "to refer disputes of a constitutional nature to the High Court; and",
              },
              {
                number: "(i)",
                text: "to proclaim referenda and plebiscites in accordance with this Constitution or an Act of Parliament.",
              },
            ],
          },
          {
            number: "2",
            text: "The President may pardon convicted offenders, grant stays of execution of sentence, reduce sentences, or remit sentences:",
            subsections: [
              {
                number: "",
                text: "Provided that -",
                subsections: [
                  {
                    number: "(a)",
                    text: "decisions under this subsection shall be taken in consultation with an Advisory Committee on the Granting of Pardon, the composition and formation of which shall be determined by an Act of Parliament; and",
                  },
                  {
                    number: "(b)",
                    text: "judgements in cases of impeachment of the President or Vice-President shall not be liable to pardon by the President.",
                  },
                ],
              },
            ],
          },
          {
            number: "3",
            text: "The President shall each year, immediately before the consideration of the official budget, attend Parliament and shall -",
            subsections: [
              {
                number: "(a)",
                text: "address Parliament on the state of the nation and on the future policies of the Government at that time;",
              },
              {
                number: "(b)",
                text: "report on the policies of the previous year; and",
              },
              {
                number: "(c)",
                text: "respond to questions.",
              },
            ],
          },
          {
            number: "4",
            text: "The President shall be called to Parliament to answer questions at such times as may be prescribed by the Standing Orders of Parliament or on a motion of the National Assembly or Senate.",
          },
          {
            number: "5",
            text: "Subject to this Constitution and any Act of Parliament, the President shall exercise all other powers reasonably necessary and incidental to the functions of his or her office in accordance with this Constitution.",
          },
          {
            number: "6",
            text: "The powers and functions of the President shall be exercised by him or her personally or by a member of the Cabinet or by a government official to whom the President has delegated such power in writing.",
          },
        ],
      },
      {
        number: "90",
        title: "Confirmation of decisions, etc., of the President",
        content: [
          {
            number: "1",
            text: "Decisions of the President shall be expressed in writing under his or her signature.",
          },
          {
            number: "2",
            text: "The signature of the President on any instrument shall be confirmed by the Public Seal.",
          },
        ],
      },
      {
        number: "91",
        title: "Immunity",
        content: [
          {
            number: "1",
            text: "No person holding the office of President or performing the functions of President may be sued in any civil proceedings but the office of President shall not be immune to orders of the courts concerning rights and duties under this Constitution.",
          },
          {
            number: "2",
            text: "No person holding the office of President shall be charged with any criminal offence in any court during his [or her] term of office, except where he or she has been charged with an offence on impeachment.",
          },
          {
            number: "3",
            text: "After a person has vacated the office of President, he or she shall not be personally liable for acts done in an official capacity during his or her term of office but shall not otherwise be immune.",
          },
        ],
      },
      {
        number: "92",
        title: "Cabinet",
        content: [
          {
            number: "1",
            text: "There shall be a Cabinet consisting of the President, the First Vice-President, the Second Vice-President and such Ministers and Deputy Ministers as may, from time to time, be appointed by the President.",
          },
          {
            number: "2",
            text: "The Cabinet shall exercise the powers and functions assigned to it by this Constitution or an Act of Parliament and shall be responsible for advising the President with respect to the policies of the Government and with respect to such other matters as may be referred to it by the President.",
          },
          {
            number: "3",
            text: "Cabinet meetings shall be presided over -",
            subsections: [
              {
                number: "(a)",
                text: "by the President; or",
              },
              {
                number: "(b)",
                text: "in the temporary absence of the President, by the First Vice-President; or",
              },
              {
                number: "(c)",
                text: "in the temporary absence of both the President and First Vice-President, by the Second Vice-President.",
              },
            ],
          },
          {
            number: "4",
            text: "There shall be a Secretary to the Cabinet who shall be appointed by the President and whose office shall be public office and who shall -",
            subsections: [
              {
                number: "(a)",
                text: "have charge of the Cabinet office;",
              },
              {
                number: "(b)",
                text: "be responsible, subject to the directions of the Cabinet, for arranging the business, and keeping the minutes of the Cabinet;",
              },
              {
                number: "(c)",
                text: "convey the decisions of the Cabinet to the appropriate persons or authorities; and",
              },
              {
                number: "(d)",
                text: "have such other functions as the Cabinet may direct.",
              },
            ],
          },
        ],
      },
      {
        number: "93",
        title: "Government departments",
        content: [
          {
            number: "1",
            text: "There shall be Ministers and Deputy Ministers who shall be appointed by the President and who shall exercise such powers and functions, including the running of Government departments, as may be prescribed by the President subject to this Constitution.",
          },
          {
            number: "2",
            text: "Every Government department shall be under the supervision of a Principal Secretary who shall be under the direction of a Minister or Deputy Minister, and whose office shall be a public office.",
          },
        ],
      },
      {
        number: "94",
        title: "Appointment of Ministers and Deputy Ministers",
        content: [
          {
            number: "1",
            text: "The President shall have the power to appoint Ministers or Deputy Ministers and to fill vacancies in the Cabinet.",
          },
          {
            number: "2",
            text: "A person shall not be qualified to be appointed as a Minister or Deputy Minister unless that person -",
            subsections: [
              {
                number: "(a)",
                text: "is a citizen of the Republic who upon taking office, has attained the age of twenty-one years;",
              },
              {
                number: "(b)",
                text: "is able to speak and [to] read the English language; and",
              },
              {
                number: "(c)",
                text: "is registered as a voter in a constituency.",
              },
            ],
          },
          {
            number: "3",
            text: "Notwithstanding subsection (2), no person shall be qualified to be appointed as a Minister or Deputy Minister who -",
            subsections: [
              {
                number: "(a)",
                text: "owes allegiance to a foreign country;",
              },
              {
                number: "(b)",
                text: "is, under any law in force in the Republic, adjudged or otherwise declared to be of unsound mind;",
              },
              {
                number: "(c)",
                text: "has, within the last seven years, been convicted by a competent court of a crime involving dishonesty or moral turpitude;",
              },
              {
                number: "(d)",
                text: "is an undischarged bankrupt, having been adjudged or otherwise declared bankrupt under any law in force in the Republic;",
              },
              {
                number: "(e)",
                text: "holds or acts in any public office or appointment, except where this Constitution explicitly provides that a person shall not be disqualified from standing for election solely on account of holding that office or appointment, or where that person resigns from that office in order to stand;",
              },
              {
                number: "(f)",
                text: "belongs to, and is serving in the Defence Forces of Malawi or in the Malawi Police Force;",
              },
              {
                number: "(g)",
                text: "has, within the last seven years, been convicted by a competent court of any violation of any law relating to election of the President or election of the members of Parliament.",
              },
            ],
          },
        ],
      },
      {
        number: "95",
        title: "Oath of office and removal of Minister or Deputy Minister",
        content: [
          {
            number: "1",
            text: "No Minister or Deputy Minister shall assume office, unless an oath or solemn affirmation was taken and executed in public in a manner prescribed by an Act of Parliament.",
          },
          {
            number: "2",
            text: "The President shall have the power to remove Ministers or Deputy Ministers from their posts.",
          },
        ],
      },
      {
        number: "96",
        title: "Duties and functions of the Cabinet",
        content: [
          {
            number: "1",
            text: "The members of the Cabinet shall have the following functions -",
            subsections: [
              {
                number: "(a)",
                text: "to advise the President;",
              },
              {
                number: "(b)",
                text: "to direct, co-ordinate and supervise the activities of Government departments, including parastatal bodies;",
              },
              {
                number: "(c)",
                text: "to initiate Bills for submission to the National Assembly and to explain those Bills;",
              },
              {
                number: "(d)",
                text: "to prepare, explain and formulate for the Parliament the budget of the State and its economic programmes;",
              },
              {
                number: "(e)",
                text: "to be available to Parliament for the purposes of answering any queries or participating in any debate pertaining to the content of the policies of the Government;",
              },
              {
                number: "(f)",
                text: "to assist the President in determining what international agreements are to be concluded or acceded to and to inform Parliament thereon;",
              },
              {
                number: "(g)",
                text: "to take responsibility for the implementation and administration of laws; and",
              },
              {
                number: "(h)",
                text: "to carry out such other functions reasonably necessary for the performance of their duties in accordance with this Constitution, as may be prescribed by an Act of Parliament or the President.",
              },
            ],
          },
          {
            number: "2",
            text: "In performing the duties and functions referred in this section the Cabinet shall make legislative proposals available in time in order to permit sufficient canvassing of expert and public opinion.",
          },
        ],
      },

      {
        number: "97",
        title: "Ministerial accountability",
        content: [
          {
            number: "",
            text: "All Ministers shall be responsible to the President for the administration of their own departments.",
          },
        ],
      },
      {
        number: "98",
        title: "The Attorney General",
        content: [
          {
            number: "1",
            text: "There shall be the office of Attorney General, who shall be the principal legal adviser to the Government.",
          },
          {
            number: "2",
            text: "Such powers as are vested in the office of the Attorney General may be exercised by the person appointed to that office or such other persons in the public service, acting as subordinates of that person and in accordance with his or her general and specific instructions.",
          },
          {
            number: "3",
            text: "Appointment to the office of Attorney General shall be made by the President.",
          },
          {
            number: "4",
            text: "The office of Attorney General shall become vacant after the person holding that office has served for five years, or on his or her resignation or retirement, whichever is sooner, provided that the person holding that office may be nominated for such further terms, not exceeding five years, as the President considers appropriate.",
          },
          {
            number: "5",
            text: "The office of Attorney General may either be the office of a Minister or may be a public office.",
          },
          {
            number: "6",
            text: "The Attorney General shall be subject to removal by the President on the grounds of incompetence, incapacity or being compromised in the exercise of his [or her] duties to the extent that his [or her] ability to give impartial legal advice is seriously in question.",
          },
        ],
      },
      {
        number: "99",
        title: "The Director of Public Prosecutions",
        content: [
          {
            number: "1",
            text: "There shall be a Director of Public Prosecutions, whose office shall be a public office.",
          },
          {
            number: "2",
            text: "The Director of Public Prosecutions shall have power in any criminal case in which he [or she] considers it desirable so to do-",
            subsections: [
              {
                number: "(a)",
                text: "to institute and undertake criminal proceedings against any person before any court (other than a court-martial) in respect of any offence alleged to have been committed by that person;",
              },
              {
                number: "(b)",
                text: "to take over and continue any criminal proceedings which have been instituted or undertaken by any other person or authority; and",
              },
              {
                number: "(c)",
                text: "to discontinue at any stage before judgement is delivered any criminal proceedings instituted or undertaken by himself or herself or any other person or authority.",
              },
            ],
          },
          {
            number: "4",
            text: "Subject to section 101 (2), the powers conferred on the Director of Public Prosecutions by subsection (2) (b) and (c) shall be vested in him or her to the exclusion of any other person or authority and whenever exercised, reasons for the exercise shall be provided to the Legal Affairs Committee or Parliament within ten days:",
            subsections: [
              {
                number: "",
                text: "Provided that where any other person or authority has instituted criminal proceedings, nothing in this subsection shall prevent the withdrawal of those proceedings by or at the instance of that person or authority and with the leave of the court.",
              },
            ],
          },
          {
            number: "4",
            text: "For the purposes of this section, any appeal from any judgement in any criminal proceedings before any court, or any case stated or question of law reserved to any other court for the purpose of any such proceedings, shall be deemed to be part of those proceedings:",
            subsections: [
              {
                number: "",
                text: "Provided that the Director of Public Prosecutions shall not exercise the power under subsection (2) (c) to discontinue proceedings with respect to any appeal by a person convicted in any criminal proceedings or to any case stated or question of law reserved at the instance of such a person.",
              },
            ],
          },
        ],
      },
      {
        number: "100",
        title: "Delegation of powers to prosecute",
        content: [
          {
            number: "1",
            text: "Save as provided in section 99 (3), such powers as are vested in the office of the Director of Public Prosecutions may be exercised by the person appointed to that office or such other persons in the public service, acting as his or her subordinates and in accordance with his or her general and specific instructions in accordance with an Act of Parliament.",
          },
          {
            number: "2",
            text: "Notwithstanding subsection (1) -",
            subsections: [
              {
                number: "(a)",
                text: "the person appointed to the office of Director of Public Prosecutions shall be accountable to the Legal Affairs Committee of Parliament for the exercise of such powers in his or her own behalf and those powers exercised on his or her behalf by subordinates in accordance with subsection (1); and",
              },
              {
                number: "(b)",
                text: "an Act of Parliament shall prescribe restrictions relating to the exercise of powers under this section by any member of the Malawi Police Force.",
              },
            ],
          },
        ],
      },
      {
        number: "101",
        title: "Appointment of the Director of Public Prosecutions",
        content: [
          {
            number: "1",
            text: "Appointment to the office of Director of Public Prosecutions shall be made by the President and confirmed by the Public Appointments Committee subject to satisfying requirements as to competence of the person so appointed to perform the duties of that office and as to capacity of a person so appointed to pursue prosecutions independently.",
          },
          {
            number: "2",
            text: "In the exercise of the powers conferred on him or her by this Constitution or any other law, the Director of Public Prosecutions shall be subject only to the general or special directions of the Attorney General but shall otherwise act independent of the direction or control of any other authority or person and in strict accordance with the law:",
            subsections: [
              {
                number: "",
                text: "Provided that the Director of Public Prosecutions or the Attorney General may be summoned by the Legal Affairs Committee of Parliament to appear before it to give account for the exercise of those powers.",
              },
            ],
          },
        ],
      },
      {
        number: "102",
        title: "Removal of the Director of Public Prosecutions",
        content: [
          {
            number: "1",
            text: "The office of the Director of Public Prosecutions shall become vacant after the person holding that office has served five years or on his or her resignation or retirement whichever is sooner:",
            subsections: [
              {
                number: "",
                text: "Provided that the person holding that office may be appointed for such further terms, not exceeding five years, as the President considers appropriate.",
              },
            ],
          },
          {
            number: "2",
            text: "A person holding the office of the Director of Public Prosecutions may be removed from the office by the President before the expiration of his or her term of office, if the President is satisfied that the person holding that office-",
            subsections: [
              {
                number: "(a)",
                text: "is incompetent in the exercise of his or her duties;",
              },
              {
                number: "(b)",
                text: "is compromised in the exercise of his or her duties to the extent that his or her ability to exercise his or her functions impartially is in serious question;",
              },
              {
                number: "(c)",
                text: "is otherwise incapacitated; or",
              },
              {
                number: "(d)",
                text: "has attained the age prescribed for retirement.",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "chapter9",
    title: "Chapter IX: THE JUDICATURE",
    sections: [
      {
        number: "103",
        title:
          "The independence and jurisdiction of the courts and the judiciary",
        content: [
          {
            number: "1",
            text: "All courts and all persons presiding over those courts shall exercise their functions, powers and duties independent of the influence and direction of any other person or authority.",
          },
          {
            number: "2",
            text: "The judiciary shall have jurisdiction over all issues of judicial nature and shall have exclusive authority to decide whether an issue is within its competence.",
          },
          {
            number: "3",
            text: "There shall be no courts established of superior or concurrent jurisdiction with the Supreme Court of Appeal or High Court.",
          },
        ],
      },
      {
        number: "104",
        title: "The Supreme Court of Appeal",
        content: [
          {
            number: "1",
            text: "There shall be a Supreme Court of Appeal for Malawi, which shall be a superior court of record and shall have such jurisdiction and powers as may be conferred on it by this Constitution or by any other law.",
          },
          {
            number: "2",
            text: "The Supreme Court of Appeal shall be the highest appellate court and shall have jurisdiction to hear appeals from the High Court and such other courts and tribunals as an Act of Parliament may prescribe.",
          },
        ],
      },
      {
        number: "105",
        title: "Composition of the Supreme Court of Appeal",
        content: [
          {
            number: "1",
            text: "The Justices of the Supreme Court of Appeal shall be -",
            subsections: [
              {
                number: "(a)",
                text: "the Chief Justice;",
              },
              {
                number: "(b)",
                text: "such number of other Justices of Appeals not being less than three, as may be prescribed by an Act of Parliament.",
              },
            ],
          },
          {
            number: "2",
            text: "When the Supreme Court of Appeal is determining any matter, other than an interlocutory matter, it shall be composed of an uneven number of Justices of Appeal, not being less than three.",
          },
          {
            number: "3",
            text: "A Justice of the Supreme Court of Appeal may only be appointed in accordance with section 111.",
          },
        ],
      },
      {
        number: "106",
        title: "Acting Justices of Appeal",
        content: [
          {
            number: "1",
            text: "If, by reason of a vacancy of office, or by reason of the operation of section 107, there are less than three serving Justices of Appeal, then the President may, on the recommendation of the Judicial Service Commission, appoint judges of the High Court, to serve as Acting Justices of Appeal.",
          },
          {
            number: "2",
            text: "An Acting Justice of Appeal shall hold that office only until such time as he or she is appointed Chief Justice or Justice of Appeal in accordance with section 111, but he or she shall cease to serve as a Justice of Appeal if -",
            subsections: [
              {
                number: "(a)",
                text: "there are more than three serving Justices of Appeal, either by reason of a vacancy or vacancies being filled in accordance with section 111 or where such Justices of Appeal or Acting Justices of Appeal as have been excused from serving on the Supreme Court are able to resume their duties in accordance with section 107;",
              },
              {
                number: "(b)",
                text: "he or she is excused from his or her duties as a Justice of Appeal or an Acting Justice of Appeal in accordance with section 107.",
              },
            ],
          },
        ],
      },
      {
        number: "107",
        title: "Relief from duties",
        content: [
          {
            number: "1",
            text: "A Justice of Appeal or Acting Justice of Appeal shall be excused from serving on the Supreme Court of Appeal only for such time as is reasonably necessary and only",
            subsections: [
              {
                number: "(a)",
                text: "by reason of that Justice or Acting Justice of Appeal having been a party to proceedings in a lower court, the decision of which is being appealed to the Supreme Court of Appeal; or",
              },
              {
                number: "(b)",
                text: "for such other reason that the Chief Justice or Judicial Service Commission considers would prevent him or her from performing the duties of his or her office.",
              },
            ],
          },
          {
            number: "2",
            text: 'For the purposes of this section "a party to proceedings" shall include -',
            subsections: [
              {
                number: "(a)",
                text: "any person exercising a judicial function in those proceedings;",
              },
              {
                number: "(b)",
                text: "having been retained for the purposes of legally representing a party to the proceedings; or",
              },
              {
                number: "(c)",
                text: "being retained for the purposes of legal advice to party to the proceedings.",
              },
            ],
          },
        ],
      },
      {
        number: "108",
        title: "The High Court",
        content: [
          {
            number: "1",
            text: "There shall be a High Court for the Republic which shall have unlimited original jurisdiction to hear and determine any civil or criminal proceedings under any law.",
          },
          {
            number: "2",
            text: "The High Court shall have original jurisdiction to review any law, and any action or decision of the Government, for conformity with this Constitution, save as otherwise provided by this Constitution and shall have such other jurisdiction and powers as may be conferred on it by this Constitution or any other law.",
          },
        ],
      },
      {
        number: "109",
        title: "Composition of the High Court",
        content: [
          {
            number: "",
            text: "The Judges of the High Court shall be such number of judges, not being less than three, as may be prescribed by an Act of Parliament.",
          },
        ],
      },
      {
        number: "110",
        title: "Subordinate courts",
        content: [
          {
            number: "1",
            text: "There shall be such courts, subordinate to the High Court, as may be prescribed by an Act of Parliament which shall be presided over by professional magistrates and lay magistrates.",
          },
          {
            number: "2",
            text: "There shall be an Industrial Relations Court, subordinate to the High Court, which shall have original jurisdiction over labour disputes and such other issues relating to employment and shall have such composition and procedure as may be specified in an Act of Parliament.",
          },
          {
            number: "3",
            text: "Parliament may make provision for traditional or local courts presided over by lay persons or chiefs:",
            subsections: [
              {
                number: "",
                text: "Provided that the jurisdiction of such courts shall be limited exclusively to civil cases at customary law and such minor common law and statutory offences as prescribed by an Act of Parliament.",
              },
            ],
          },
        ],
      },
      {
        number: "111",
        title: "Appointment of the Judiciary",
        content: [
          {
            number: "1",
            text: "The Chief Justice shall be appointed by the President and confirmed by the National Assembly by a majority of two thirds of the members present and voting.",
          },
          {
            number: "2",
            text: "All other judges shall be appointed by the President on the recommendation of the Judicial Service Commission.",
          },
          {
            number: "3",
            text: "Magistrates and persons appointed to other judicial offices shall be appointed by the Chief Justice on the recommendation of the Judicial Service Commission and shall hold office until the age of seventy unless removed in accordance with section 119.",
          },
          {
            number: "4",
            text: `For the purposes of this Chapter "judicial office" means the office of -`,
            subsections: [
              {
                number: "(a)",
                text: "a Justice of Appeal or Acting Justice of Appeal;",
              },
              {
                number: "(b)",
                text: "a Judge of the High Court or Acting Judge of the High Court;",
              },
              {
                number: "(c)",
                text: "the Registrar or Deputy Registrar of the Supreme Court of Appeal or High Court;",
              },
              {
                number: "(d)",
                text: "a magistrate of whatever grade; and",
              },
              {
                number: "(e)",
                text: "a person presiding over a traditional or local court.",
              },
            ],
          },
          {
            number: "5",
            text: "A person appointed to fill a vacancy in the office of a Judge shall not be required, on being so appointed, to serve in an acting capacity.",
          },
          {
            number: "6",
            text: `For the purposes of this Chapter "judge" shall mean a Justice of Appeal, an Acting Justice of Appeal, a Judge of the High Court or an Acting Judge of the High Court.`,
          },
        ],
      },
      {
        number: "112",
        title: "Qualification of Judicial officers",
        content: [
          {
            number: "1",
            text: "A person shall not be qualified for appointment as a judge unless that person -",
            subsections: [
              {
                number: "(a)",
                text: "is, or has been, a judge of a court having unlimited jurisdiction in criminal or civil proceedings; or",
              },
              {
                number: "(b)",
                text: "is entitled to practise as a legal practitioner or an advocate or a solicitor in such a court and has been entitled so to practise for not less than ten years.",
              },
            ],
          },
          {
            number: "2",
            text: "For the purposes of this section, a person shall be regarded as entitled to practise as a legal practitioner or an advocate or a solicitor if that person has been called, enrolled or otherwise admitted as such and has not been subsequently disbarred or removed from the roll of legal practitioners or advocates or solicitors notwithstanding that the person -",
            subsections: [
              {
                number: "(a)",
                text: "holds or acts in any office the holder of which is, by reason of his or her office, precluded from practising in court; or",
              },
              {
                number: "(b)",
                text: "does not hold a practising certificate and has not satisfied any other like condition of his or her being permitted to practise.",
              },
            ],
          },
        ],
      },
      {
        number: "113",
        title: "Vacancy",
        content: [
          {
            number: "1",
            text: "If the office of Chief Justice is vacant, or if the Chief Justice is for any reason unable to perform the functions of his or her office, then, until a person has been appointed to and has assumed the functions of that office, or until the person holding that office has resumed those functions, as the case may be, those functions shall be performed by the most senior judge then sitting on the Supreme Court of Appeal or High Court.",
          },
          {
            number: "2",
            text: "If any judicial office is vacant or if any judge is appointed to act as Chief Justice, or is for any reason unable to perform the functions of his or her office, the President, on the recommendations of the Judicial Service Commission, may appoint a person qualified for appointment to that judicial office under this section to act in that office.",
          },
        ],
      },
      {
        number: "114",
        title: "Remuneration",
        content: [
          {
            number: "",
            text: "The Chief Justice and all other holders of judicial office shall receive a salary for their services and, on retirement, such pension, gratuity or other allowance as may, from time to time, be determined by the National Assembly. The salary and any allowance of a holder of judicial office shall not without his or her consent be reduced during his or her period of office and shall be increased at intervals so as to retain its original value and shall be a charge upon the Consolidated Fund.",
          },
        ],
      },
      {
        number: "115",
        title: "Judicial oath",
        content: [
          {
            number: "",
            text: "A person holding judicial office shall not enter upon the duties of his or her office unless that officer has taken and subscribed the oath of allegiance for the due execution of his or her office in such manner and form as may be prescribed by an Act of Parliament.",
          },
        ],
      },
      {
        number: "116",
        title: "The Judicial Service Commission",
        content: [
          {
            number: "",
            text: "There shall be a Judicial Service Commission for the regulation of judicial officers and which shall have such jurisdiction and powers as may be conferred on it by this Constitution or, subject to this Constitution, by any Act of Parliament.",
          },
        ],
      },
      {
        number: "117",
        title: "Composition",
        content: [
          {
            number: "",
            text: "The Judicial Service Commission shall consist of -",
            subsections: [
              {
                number: "(a)",
                text: "the Chief Justice who shall be the Chairman;",
              },
              {
                number: "(b)",
                text: "the Chairman of the Civil Service Commission, or such other member as may for the time being be designated in that behalf by the Chairman of the Civil Service Commission;",
              },
              {
                number: "(c)",
                text: "such Justice of Appeal or Judge as may for the time being be designated in that behalf by the President acting after consultation with the Chief Justice; and",
              },
              {
                number: "(d)",
                text: "such legal practitioner and such magistrate as may for the time being be designated in that behalf by the President acting after consultation with the Chief Justice.",
              },
            ],
          },
        ],
      },
      {
        number: "118",
        title: "Powers of the Judicial Service Commission",
        content: [
          {
            number: "",
            text: "The Judicial Service Commission shall have the authority to -",
            subsections: [
              {
                number: "(a)",
                text: "nominate persons for judicial office;",
              },
              {
                number: "(b)",
                text: "exercise such disciplinary powers in relation to persons in judicial office subject to this Constitution as shall be prescribed by an Act of Parliament, subject to this Constitution;",
              },
              {
                number: "(c)",
                text: "recommend, subject to section 119, the removal of a person from judicial office;",
              },
              {
                number: "(d)",
                text: "subject to this Constitution, make such representations to the President as may be prescribed by an Act of Parliament; and",
              },
              {
                number: "(e)",
                text: "exercise such other powers as are conferred on it by this Constitution or as are reasonably necessary for the performance of its duties:",
              },
            ],
          },
          {
            number: "",
            text: "Provided that nothing in this section shall prejudice the right of any person in judicial office who was the subject of any decision by the Judicial Service Commission to appeal to the Supreme Court of Appeal against that decision.",
          },
        ],
      },
      {
        number: "119",
        title: "Tenure of office of Judges",
        content: [
          {
            number: "1",
            text: "Subject to this section, a person holding the office of Judge shall vacate that office on attaining the age prescribed in subsection (6):",
            subsections: [
              {
                number: "",
                text: "Provided that the President, after consultation with the Judicial Service Commission, may permit a Judge who has attained that age to continue in office for such period as may be necessary to enable him to deliver judgement or to do any other thing in relation to proceedings that were commenced before him before he attained that age.",
              },
            ],
          },
          {
            number: "2",
            text: "A person holding the office of Judge may be removed from office only for incompetence in the performance of the duties of his office or for misbehaviour, and shall not be so removed except in accordance with subsections (3) and (4).",
          },
          {
            number: "3",
            text: "The President may by an instrument under the Public Seal and in consultation with the Judicial Service Commission remove from office any Judge where a motion praying for his removal on the ground of incompetence in the performance of the duties of his office or misbehaviour has been-",
            subsections: [
              {
                number: "(a)",
                text: "debated in the National Assembly;",
              },
              {
                number: "(b)",
                text: "passed by a majority of the votes of all the members of the Assembly; and",
              },
              {
                number: "(c)",
                text: "submitted to the President as a petition for the removal of the judge concerned:",
              },
            ],
          },
          {
            number: "",
            text: "Provided that the procedure for the removal of a judge shall be in accordance with the principles of natural justice.",
          },
          {
            number: "4",
            text: "Where notice of intention to introduce before the National Assembly a motion praying for the removal of a Judge from his office has been lodged in the office of the Speaker, the President may, where after consultation with the Judicial Service Commission he is satisfied that it is in the public interest so to do, suspend the Judge from performing the duties of his office.",
          },
          {
            number: "5",
            text: "The suspension of a Judge under subsection (4) may at any time be revoked by the President, after consultation with the Judicial Service Commission, and shall in any case cease to have effect where the motion is withdrawn before being debated in the National Assembly or, upon being debated, is not passed by a majority thereof.",
          },
          {
            number: "6",
            text: "The prescribed age for purposes of subsection (1) shall be the age of sixty-five years or such other age as may be prescribed by Parliament:",
            subsections: [
              {
                number: "",
                text: "Provided that a law made by Parliament, to the extent that it alters the age at which a Judge shall vacate his office, shall not have effect in relation to a Judge after his appointment unless he consents to its having effect.",
              },
            ],
          },
          {
            number: "7",
            text: "Where the President considers it desirable in the public interest so to do, he may, with the consent of the person concerned, assign a person holding the office of Judge to any other office in the public service for such period as the President may determine during which that person may cease to perform the duties of his office as Judge; but so, however, that -",
            subsections: [
              {
                number: "(a)",
                text: "such assignment shall not be regarded as removal of that person under subsection (2) from his office as Judge;",
              },
              {
                number: "(b)",
                text: "the resumption by that person of the duties of his office as Judge shall not require formal re-appointment;",
              },
              {
                number: "(c)",
                text: "the retirement age of that person shall be that prescribed for Judges under subsection (1).",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "chapter10",
    title: "Chapter X: THE OMBUDSMAN",
    sections: [
      {
        number: "120",
        title: "The Office of the Ombudsman",
        content: [
          {
            number: "",
            text: "There shall be a public office known as the office of the Ombudsman which shall have such powers, functions and responsibilities as are conferred upon that office by this Constitution and any other law.",
          },
        ],
      },
      {
        number: "121",
        title: "Independence of the Ombudsman",
        content: [
          {
            number: "",
            text: "In the exercise of his or her powers, functions and duties the Ombudsman shall be completely independent of the interference or direction of any other person or authority.",
          },
        ],
      },
      {
        number: "122",
        title: "Appointment of Ombudsman",
        content: [
          {
            number: "1",
            text: "Nominations for appointment to the office of Ombudsman shall be received from the public by way of a public advertisement placed by the Clerk to the National Assembly and the successful candidate shall be appointed by the Public Appointments Committee in accordance with the requirements of this section.",
          },
          {
            number: "2",
            text: "The person appointed to the office of Ombudsman shall -",
            subsections: [
              {
                number: "(a)",
                text: "have sufficient knowledge of the law;",
              },
              {
                number: "(b)",
                text: "be publicly regarded as a person who can make impartial judgements;",
              },
              {
                number: "(c)",
                text: "have sufficient knowledge of the workings of Government;",
              },
              {
                number: "(d)",
                text: "not have had any criminal convictions and not have been a bankrupt;",
              },
              {
                number: "(e)",
                text: "be otherwise competent and capable of performing the duties of his or her office;",
              },
              {
                number: "(f)",
                text: "not be the President, Vice-President, a Minister or Deputy Minister, a serving public officer or a member of Parliament; and",
              },
              {
                number: "(g)",
                text: "not hold any other public office unless otherwise provided for in this Constitution.",
              },
            ],
          },
        ],
      },
      {
        number: "123",
        title: "Functions and powers",
        content: [
          {
            number: "1",
            text: "The office of the Ombudsman may investigate any and all cases where it is alleged that a person has suffered injustice and it does not appear that there is any remedy reasonably available by way of proceedings in a court or by way of appeal from a court or where there is no other practicable remedy.",
          },
          {
            number: "2",
            text: "Notwithstanding subsection (1), the powers of the office of the Ombudsman under this section shall not oust the jurisdiction of the courts and the decisions and exercise of powers by the Ombudsman shall be reviewable by the High Court on the application of any person with sufficient interest in a case the Ombudsman has determined.",
          },
        ],
      },
      {
        number: "124",
        title: "Powers of investigation",
        content: [
          {
            number: "",
            text: "The Ombudsman shall have full powers to -",
            subsections: [
              {
                number: "(a)",
                text: "subpoena the attendance of any person who the Ombudsman reasonably believes to be connected with any investigation being undertaken by that office;",
              },
              {
                number: "(b)",
                text: "require the immediate disclosure of information and the production of documents of any kind, from any public body;",
              },
              {
                number: "(c)",
                text: "question any person who the Ombudsman reasonably believes to be connected with an investigation that is being undertaken by that office; and",
              },
              {
                number: "(d)",
                text: "initiate contempt proceedings before the High Court against any person or authority in connexion with non-compliance with the powers conferred in this section.",
              },
            ],
          },
        ],
      },
      {
        number: "125",
        title: "Privileges and immunities of the Ombudsman",
        content: [
          {
            number: "",
            text: "A person holding the office of Ombudsman shall -",
            subsections: [
              {
                number: "(a)",
                text: "be provided with the necessary resources to discharge the functions of that office;",
              },
              {
                number: "(b)",
                text: "be entitled to the fullest co-operation of any person or authority of whom he or she requests assistance in connexion with the duties of that office;",
              },
              {
                number: "(c)",
                text: "enjoy, with respect to his or her official functions, similar protection and privileges in so far as they are appropriate as are enjoyed by members of Parliament; and",
              },
              {
                number: "(d)",
                text: "be paid a salary to be charged to the Consolidated Fund and which shall not be reduced without the consent of the office holder.",
              },
            ],
          },
        ],
      },
      {
        number: "126",
        title: "Remedies",
        content: [
          {
            number: "",
            text: "Where the investigations of the Ombudsman reveal sufficient evidence to satisfy him or her that an injustice has been done, the Ombudsman shall -",
            subsections: [
              {
                number: "(a)",
                text: "direct that appropriate administrative action be taken to redress the grievance;",
              },
              {
                number: "(b)",
                text: "cause the appropriate authority to ensure that there are, in future, reasonably practicable remedies to redress a grievance; and",
              },
              {
                number: "(c)",
                text: "refer a case to the Director of Public Prosecutions with a recommendation for prosecution, and, in the event of a refusal by the Director of Public Prosecutions to proceed with the case, the Ombudsman shall have the power to require reasons for the refusal.",
              },
            ],
          },
        ],
      },
      {
        number: "127",
        title: "Reports of the Ombudsman",
        content: [
          {
            number: "",
            text: "The Ombudsman shall lay, each year, before the National Assembly a report which shall include a record of all complaints and applications to the office of Ombudsman, a record of the exercise of powers in relation to applications, of the remedies afforded to applicants in respect of grievances and shall also include a record of the general recommendations of the Ombudsman in respect of grievances.",
          },
        ],
      },
      {
        number: "128",
        title: "Removal from office",
        content: [
          {
            number: "1",
            text: "A person appointed to the office of Ombudsman shall serve a term of not more than five years, provided that the Public Appointments Committee may appoint that person for such further terms of five years as it considers appropriate unless that Committee sooner terminates that appointment in accordance with this section.",
          },
          {
            number: "2",
            text: "A person appointed to the office of Ombudsman shall not be removed by the Public Appointments Committee, except -",
            subsections: [
              {
                number: "(a)",
                text: "in such circumstances where had that person not been Ombudsman, that person would have been disqualified from being appointed;",
              },
              {
                number: "(b)",
                text: "for gross misconduct; or",
              },
              {
                number: "(c)",
                text: "on reaching the age of sixty-five years.",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: "chapter11",
    title: "Chapter XI: HUMAN RIGHTS COMMISSION",
    sections: [
      {
        number: "129",
        title: "Establishment of the Human Rights Commission",
        content: [
          {
            number: "",
            text: "There shall be a Human Rights Commission the primary functions of which shall be the protection and investigation of violations of the rights accorded by this Constitution or any other law.",
          },
        ],
      },
      {
        number: "130",
        title: "Powers",
        content: [
          {
            number: "",
            text: "The Human Rights Commission shall, with respect to the applications of an individual or class of persons, or on its own motion, have such powers of investigation and recommendation as are reasonably necessary for the effective promotion of the rights conferred by or under this Constitution, but shall not exercise a judicial or legislative function and shall not be given powers so to do.",
          },
        ],
      },
      {
        number: "131",
        title: "Composition",
        content: [
          {
            number: "1",
            text: "The Human Rights Commission shall consist of -",
            subsections: [
              {
                number: "(a)",
                text: "the person for the time being holding the office of Law Commissioner;",
              },
              {
                number: "(b)",
                text: "the person for the time being holding the position of Ombudsman:",
                subsections: [
                  {
                    number: "",
                    text: "Provided that, save as prescribed by this section, no other member of the Human Rights Commission shall be a person in any public office or the President or Vice-President, a Minister or Deputy Minister or a member of Parliament.",
                  },
                ],
              },
              {
                number: "(c)",
                text: "such persons as shall be nominated from time to time in that behalf by those organizations that are considered in the absolute discretion of both the Law Commissioner and the Ombudsman to be reputable organizations representative of Malawian Society and that are wholly or largely concerned with the promotion of the rights and freedoms guaranteed by this Constitution.",
              },
            ],
          },
          {
            number: "2",
            text: "The Law Commissioner and the Ombudsman shall jointly refer the name of persons nominated under paragraph (c) of subsection (1) to the President who shall formally appoint such persons as members of the Human Rights Commission.",
          },
          {
            number: "3",
            text: "A member of the Human Rights Commission, other than a member by virtue of paragraph (a) or (b) of subsection (1), shall continue to be members of the Commission until such time as they are removed from office on the grounds of -",
            subsections: [
              {
                number: "(a)",
                text: "incompetence;",
              },
              {
                number: "(b)",
                text: "incapacity; or",
              },
              {
                number: "(c)",
                text: "in circumstances where the member is compromised to the extent that his or her ability to impartially exercise the duties of his or her office is seriously in question.",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: "chapter12",
    title: "Chapter XII: LAW COMMISSION",
    sections: [
      {
        number: "132",
        title: "Establishment of the Law Commission",
        content: [
          {
            number: "",
            text: "There shall be a Law Commission which shall have the power to review and make recommendations relating to the repeal and amendment of laws and which shall have such powers and functions as are conferred on it by this Constitution and any other Act of Parliament.",
          },
        ],
      },
      {
        number: "133",
        title: "Composition",
        content: [
          {
            number: "",
            text: "The Law Commission shall consist of -",
            subsections: [
              {
                number: "(a)",
                text: "a permanent, salaried Law Commissioner who shall be appointed by the President on the recommendation of the Judicial Service Commission and who shall be a legal practitioner or a person qualified to be a judge; and",
              },
              {
                number: "(b)",
                text: "such number of other persons as the Law Commissioner in consultation with the Judicial Service Commission may appoint from time to time and for such time as they are required on account of their expert knowledge of a matter of law being then under review by the Law Commissioner, or on account of their expert knowledge of other matters relating to a legal issue being then under review.",
              },
            ],
          },
        ],
      },
      {
        number: "134",
        title: "Removal of the Law Commissioner",
        content: [
          {
            number: "",
            text: "The President may remove the Law Commissioner or other person appointed to the Law Commission on the recommendation of the Judicial Service Commission if the Judicial Service Commission is satisfied that the Law Commissioner or such other person appointed to the Law Commission, as the case may be, is not competent or otherwise incapacitated so as to be unable to perform the functions of his or her office.",
          },
        ],
      },
      {
        number: "135",
        title: "Powers and functions of the Law Commission",
        content: [
          {
            number: "",
            text: "The Law Commission shall have the powers -",
            subsections: [
              {
                number: "(a)",
                text: "to review and make recommendations regarding any matter pertaining to the laws of Malawi and their conformity with this Constitution and applicable international law;",
              },
              {
                number: "(b)",
                text: "to review and make recommendations regarding any matter pertaining to this Constitution;",
              },
              {
                number: "(c)",
                text: "to receive any submissions from any person or body regarding the laws of Malawi or this Constitution; and",
              },
              {
                number: "(d)",
                text: "to report its findings and recommendations to the Minister for the time being responsible for Justice who shall publish any such report and lay it before Parliament.",
              },
            ],
          },
        ],
      },
      {
        number: "136",
        title: "Independence of the Law Commission",
        content: [
          {
            number: "",
            text: "The Law Commission shall exercise its functions and powers independent of the direction or interference of any other person or authority.",
          },
        ],
      },
    ],
  },

  {
    id: "chapter13",
    title: "Chapter XIII: NATIONAL COMPENSATION FUND",
    sections: [
      {
        number: "137",
        title: "The National Compensation Tribunal",
        content: [
          {
            number: "",
            text: "There shall be a National Compensation Tribunal which shall entertain claims with respect to alleged criminal and civil liability of the Government of Malawi which was in power before the appointed day and which shall have such powers and functions as are conferred on it by this Constitution and an Act of Parliament.",
          },
        ],
      },
      {
        number: "138",
        title: "Exclusive original jurisdiction",
        content: [
          {
            number: "1",
            text: "No person shall institute proceedings against any Government in power after the commencement of this Constitution in respect of any alleged criminal or civil liability of the Government of Malawi in power before the commencement of this Constitution arising from abuse of power or office, save by application to the National Compensation Tribunal, which shall hear cases initiated by persons with sufficient interest.",
          },
          {
            number: "2",
            text: "The National Compensation Tribunal shall have all powers of investigation necessary to establish the facts of any case before it.",
          },
          {
            number: "3",
            text: "Notwithstanding subsection (1), the National Compensation Tribunal shall have the power to remit a case or a question of law for determination by the ordinary courts where the National Compensation Tribunal is satisfied that the Tribunal does not have jurisdiction, or where the Tribunal feels it is in the interest of justice so to do.",
          },
        ],
      },
      {
        number: "139",
        title: "Composition",
        content: [
          {
            number: "1",
            text: "There shall be a Chairman of the National Compensation Tribunal who shall be a judge and who shall -",
            subsections: [
              {
                number: "(a)",
                text: "be appointed in that behalf by the Chief Justice on the nomination of the Judicial Service Commission; and",
              },
              {
                number: "(b)",
                text: "hold the office of Chairman of the National Compensation Tribunal for not more than three years or until such time as that person ceases to be a judge whichever is sooner.",
              },
            ],
          },
          {
            number: "2",
            text: "The Chairman of the National Compensation Tribunal shall be assisted by such additional members and by such assessors and other experts as may be appointed in accordance with the provisions of an Act of Parliament.",
          },
        ],
      },
      {
        number: "140",
        title: "Procedure",
        content: [
          {
            number: "1",
            text: "The rules of procedure of the National Compensation Tribunal and other matters of policy or principle concerning its powers and functions shall be prescribed by or under an Act of Parliament and shall be such as shall ensure expeditious disposal of cases, which may include an informal preliminary arbitration procedure.",
          },
          {
            number: "2",
            text: "Notwithstanding subsection (1), the procedures of the National Compensation Tribunal shall -",
            subsections: [
              {
                number: "(a)",
                text: "conform to the standards of proof required for a normal civil court unless the National Compensation Tribunal otherwise determines in the interest of justice in any particular case or class of cases; and",
              },
              {
                number: "(b)",
                text: "conform with the standards of justice set out in this Constitution and the principles of natural justice.",
              },
            ],
          },
        ],
      },
      {
        number: "141",
        title: "Protection of third party rights",
        content: [
          {
            number: "",
            text: "Where a third party disputes a claim and has an interest in money or property that is the subject of a claim before the National Compensation Tribunal -",
            subsections: [
              {
                number: "(a)",
                text: "that party shall be given adequate notification;",
              },
              {
                number: "(b)",
                text: "that party shall be entitled to legal representation; and",
              },
              {
                number: "(c)",
                text: "if the Chairman of the National Compensation Tribunal is satisfied that the person is of insufficient means to retain legal counsel, legal assistance shall be provided at the expense of the State.",
              },
            ],
          },
        ],
      },
      {
        number: "142",
        title: "Jurisdiction of ordinary courts",
        content: [
          {
            number: "1",
            text: "The High Court shall not be excluded from hearing applications for judicial review of the decisions of the Tribunal nor shall a determination by the Tribunal be a bar to further criminal or civil proceedings in an appropriate court against a private person for the duration of the existence of the fund.",
          },
          {
            number: "2",
            text: 'A "private person" for the purposes of this section means a person who was before the commencement of this Constitution a member of the Government or of an agent of the Government, who would, under the laws then in force, have been personally liable for an act that is the subject of the criminal or civil proceedings.',
          },
        ],
      },
      {
        number: "143",
        title: "Power to waive statutory limitations",
        content: [
          {
            number: "",
            text: "For the purposes of pursuing claims before the National Compensation Tribunal and criminal and civil proceedings against a private person within the meaning of subsection 142 (2), any statutory time limitation may be waived by the Tribunal or by a court if it seems to the Tribunal or the court equitable to do so.",
          },
        ],
      },
      {
        number: "144",
        title: "National Compensation Fund",
        content: [
          {
            number: "1",
            text: "There shall be a National Compensation Fund which shall be a trust vested in the Republic.",
          },
          {
            number: "2",
            text: "The National Compensation Fund shall be used exclusively for the purposes assigned to it by this Constitution and shall-",
            subsections: [
              {
                number: "(a)",
                text: "be a trust the purpose of which shall be for the exclusive benefit of those applicants to the National Compensation Tribunal who have been granted any award, gratuity, pension or other form of reparation according to the principles, procedures and rules of the National Compensation Tribunal;",
              },
              {
                number: "(b)",
                text: "be held in a separate account within the Reserve Bank of Malawi; and",
              },
              {
                number: "(c)",
                text: "have all of its reports, financial statements and information relating to its operation published and maintained for public scrutiny.",
              },
            ],
          },
          {
            number: "3",
            text: "subject to this Chapter, the only charges on, or disbursements to be made from, the National Compensation Fund shall be by -",
            subsections: [
              {
                number: "(a)",
                text: "the National Compensation Tribunal; or",
              },
              {
                number: "(b)",
                text: "the trustees of the Fund in so far as such disbursements or charges are necessary and prudent for the efficient operation of the Fund in accordance with its purpose as declared in this subsection.",
              },
            ],
          },
          {
            number: "4",
            text: "There shall be not less than four trustees of the National Compensation Fund who shall be appointed from time to time by the Public Appointments Committee on the recommendation of the National Compensation Tribunal.",
          },
          {
            number: "5",
            text: "A Trustee shall hold office for the duration of the Fund unless and only in such circumstances as that trustee is removed by the Public Appointments Committee on the grounds of -",
            subsections: [
              {
                number: "(a)",
                text: "incompetence;",
              },
              {
                number: "(b)",
                text: "incapacity; or",
              },
              {
                number: "(c)",
                text: "being compromised in the exercise of his or her functions to the extent that his or her financial probity is in serious question.",
              },
            ],
          },
          {
            number: "6",
            text: "Trustees of the National Compensation Fund shall exercise their functions independent of any direction or interference by any body or authority, save as is provided by this section.",
          },
          {
            number: "7",
            text: "The Auditor General shall make an annual report, to be laid before the National Assembly, on the conduct and status of the Fund which shall provide guidance to the National Assembly for voting an appropriation for the purposes of the National Compensation Fund.",
          },
          {
            number: "8",
            text: "An appropriation for the purposes of the National Compensation Fund shall be laid before the National Assembly by the Minister responsible for Finance before the beginning, and with respect to, every financial year during the life of the Fund.",
          },
          {
            number: "9",
            text: "With respect to any financial year, the Tribunal shall prescribe a period not being more than six months after the commencement of that financial year, after which the Tribunal shall not receive applications for compensation within that financial year from the National Compensation Fund.",
          },
        ],
      },
      {
        number: "145",
        title: "Winding up of the National Compensation Fund",
        content: [
          {
            number: "1",
            text: "The National Compensation Fund shall cease to be charged with new claims for compensation not later than ten years after the commencement of this Constitution at which time the National Compensation Tribunal shall dissolve.",
          },
          {
            number: "2",
            text: "If, with respect to any year within the period prescribed in subsection (1), and after the second year of it coming into existence, less than ten applications are made to the National Compensation Tribunal, then the Chairman of the Tribunal may direct the Minister responsible for Finance to lay before the National Assembly a Bill -",
            subsections: [
              {
                number: "(a)",
                text: "to dissolve the National Compensation Tribunal; and",
              },
              {
                number: "(b)",
                text: "to confer on the High Court jurisdiction equivalent to that of the National Compensation Tribunal to determine claims against the Government within the meaning of this Chapter.",
              },
            ],
          },
          {
            number: "3",
            text: "Where the National Compensation Tribunal has been dissolved, for the remaining duration of the period prescribed in subsection (1) the uncommitted residue of the Fund shall remain a separate fund within the accounts of the Consolidated Fund which shall be drawn upon by the Minister responsible for Finance in respect of awards made by the High Court in relation to claims that would otherwise have been determined by the National Compensation Tribunal.",
          },
          {
            number: "4",
            text: "The National Compensation Fund shall continue until there is no longer a committed residue.",
          },
        ],
      },
    ],
  },

  {
    id: "chapter14",
    title: "Chapter XIV: LOCAL GOVERNMENT",
    sections: [
      {
        number: "146",
        title: "Functions of local government authorities",
        content: [
          {
            number: "1",
            text: "There shall be local government authorities which shall have such powers as are vested in them by this Constitution and an Act of Parliament.",
          },
          {
            number: "2",
            text: "Local government authorities shall be responsible for the representation of the people over whom they have jurisdiction, for their welfare and shall have responsibility for -",
            subsections: [
              {
                number: "(a)",
                text: "the promotion of infrastructural and economic development, through the formulation and execution of local development plans and the encouragement of business enterprise;",
              },
              {
                number: "(b)",
                text: "the presentation to central government authorities of local development plans and the promotion of the awareness of local issues to national government;",
              },
              {
                number: "(c)",
                text: "the consolidation and promotion of local democratic institutions and democratic participation; and",
              },
              {
                number: "(d)",
                text: "such other functions, including the registration of birth and deaths and participation in the delivery of essential and local services, as may be prescribed by any Act of Parliament.",
              },
            ],
          },
          {
            number: "3",
            text: "Parliament shall, where possible, provide that issues of local policy and administration be decided on at local levels under the supervision of local government authorities.",
          },
          {
            number: "4",
            text: "Parliament shall ensure that the composition of local government authorities includes a prescribed number of persons serving as Chiefs in the area of jurisdiction of such authorities and affords equal representation in respect of each ward within its jurisdiction, and that the boundaries of each ward shall be designated by the Electoral Commission in accordance with section 148.",
          },
        ],
      },
      {
        number: "147",
        title: "Composition of local government authorities",
        content: [
          {
            number: "1",
            text: "Local government authorities shall consist of local government officers who shall be elected by free, secret and equal suffrage by the registered voters in the area over which that local government authority is to have jurisdiction and the election shall be organized, conducted and supervised by the Electoral Commission.",
          },
          {
            number: "2",
            text: "The offices of local government shall include mayors in cities and municipalities and local councillors in all areas and local government officers shall have such functions, powers and responsibilities as shall be laid down by an Act of Parliament.",
          },
          {
            number: "3",
            text: "There shall be, in respect of each local government authority, such administrative personnel, subordinate to local government officers, as shall be required to execute and administer the lawful resolutions and policies of those officers.",
          },
          {
            number: "4",
            text: "There shall be a Local Government Service Commission, the composition, functions, powers and procedures of which shall be provided for by an Act of Parliament.",
          },
        ],
      },
      {
        number: "148",
        title: "Jurisdiction of local government authorities",
        content: [
          {
            number: "1",
            text: "Subject to the recommendations of the Electoral Commission, and in accordance with the principles laid down in this Constitution and any other law relating to national elections, there shall be drawn boundaries for local government authorities.",
          },
          {
            number: "2",
            text: "Any boundaries determining the territorial jurisdiction of any local government authority shall be geographical only, without reference to race, colour, tribe or ethnic origin of the inhabitants of the area.",
          },
        ],
      },
      {
        number: "149",
        title: "National Local Government Finance Committee",
        content: [
          {
            number: "1",
            text: "There shall be a National Local Government Finance Committee which shall hear submissions from each and every local government authority in respect of estimates of expenditure and requests for special disbursements and shall have such other powers and functions as may be conferred on it by this Constitution or an Act of Parliament.",
          },
          {
            number: "2",
            text: "The National Local Government Finance Committee shall have the power to -",
            subsections: [
              {
                number: "(a)",
                text: "receive all estimates of revenue and all projected budgets of all local government authorities;",
              },
              {
                number: "(b)",
                text: "supervise and audit accounts of local government authorities, in accordance with any Act of Parliament, Assembly, subject to the recommendations of the Auditor General;",
              },
              {
                number: "(c)",
                text: "make recommendations relating to the distribution of funds allocated to local government authorities, and vary the amount payable from time to time and area to area according to, and with sole consideration of, economic, geographic and demographic variables;",
              },
              {
                number: "(d)",
                text: "prepare a consolidated budget for all local government authorities and estimates after consultation with the Treasury, which shall be presented to the National Assembly by the Minister responsible for Local Government before the commencement of each financial year; and",
              },
              {
                number: "(e)",
                text: "make application to that Minister for supplementary funds where necessary.",
              },
            ],
          },
        ],
      },
      {
        number: "150",
        title:
          "Duty to provide adequate resources for local government functions",
        content: [
          {
            number: "",
            text: "The Government shall be under a duty to ensure that there is adequate provision of resources necessary for the proper exercise of local government functions and to this effect shall allow a local government authority to keep such proportion of the revenue collected by that authority as shall be prescribed by the National Local Government Finance Committee.",
          },
        ],
      },
      {
        number: "151",
        title: "Composition of the National Local Government Finance Committee",
        content: [
          {
            number: "1",
            text: "The members of the National Local Government Finance Committee shall be -",
            subsections: [
              {
                number: "(a)",
                text: "one person who shall be nominated from time to time in that behalf by a caucus of local government authorities;",
              },
              {
                number: "(b)",
                text: "the Principal Secretary for Local Government;",
              },
              {
                number: "(c)",
                text: "one person who is a professionally qualified and practicing accountant appointed by the Public Appointments Committee on the recommendation of the Minister responsible for Local Government;",
              },
              {
                number: "(d)",
                text: "the Chairman of the Civil Service Commission or such member of that Commission as shall be nominated by the Chairman from time to time in that behalf; and",
              },
              {
                number: "(e)",
                text: "one person who shall be nominated from time to time in that behalf by the Electoral Commission.",
              },
            ],
          },
          {
            number: "2",
            text: "Except for persons who are or become members of the Local Government Finance Committee by virtue of holding office as Principal Secretary for Local Government or by Chairman or member of the Civil Service Commission, the term of office of a member of the National Local Government Finance Committee shall expire -",
            subsections: [
              {
                number: "(a)",
                text: "three years after the date that member was first appointed; or",
              },
              {
                number: "(b)",
                text: "on removal by the President on the recommendation of the Public Appointments Committee, but no member shall be recommended for removal under this paragraph unless the Public Appointments Committee is satisfied that he or she is -",
                subsections: [
                  {
                    number: "i",
                    text: "not competent to exercise the duties of that office;",
                  },
                  {
                    number: "ii",
                    text: "compromised to the extent that his or her financial probity is in serious question; or",
                  },
                  {
                    number: "iii",
                    text: "otherwise incapacitated.",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: "chapter15",
    title: "Chapter XV: THE POLICE",
    sections: [
      {
        number: "152",
        title: "The Malawi Police Force",
        content: [
          {
            number: "",
            text: "There shall be a Malawi Police Force which shall be constituted by an Act of Parliament that shall specify the various divisions and functions of the Malawi Police Force.",
          },
        ],
      },
      {
        number: "153",
        title: "Powers and functions of the Police",
        content: [
          {
            number: "1",
            text: "The Malawi Police Force shall be an independent organ of the executive, which shall be there to provide for the protection of public safety and the rights of persons in Malawi according to the prescriptions of this Constitution and any other law.",
          },
          {
            number: "2",
            text: "The Malawi Police Force shall enjoy only such powers as are necessary for the protection of rights under this Constitution and the maintenance of public safety and public order in accordance with the prescriptions of this Constitution and the law.",
          },
          {
            number: "3",
            text: "In the exercise of their functions, members of the Malawi Police Force shall be subject to the direction of the courts and shall be bound by the orders of such courts.",
          },
          {
            number: "4",
            text: "Political responsibility for the Malawi Police Force shall vest in a Minister of the Government who shall ensure that the discipline and conduct of the Malawi Police Force accords with the prescriptions of this Constitution and any other law.",
          },
        ],
      },
      {
        number: "154",
        title: "The Inspector General of Police",
        content: [
          {
            number: "1",
            text: "There shall be an Inspector General of Police who shall be the Head of the Malawi Police Force whose office shall be a public office and also shall be accountable to the Minister responsible for the Police and whose office shall be a public office.",
          },
          {
            number: "2",
            text: "The Inspector General of Police shall be appointed by the President and confirmed by the National Assembly by a majority of the members present and voting, but the Public Appointments Committee may at any time inquire as to the competence of the person so appointed to carry out the duties of that office and as to such other questions as may have direct bearing on the performance of the duties of that office.",
          },
          {
            number: "3",
            text: "The office of the Inspector General of Police shall become vacant after the person holding that office has served for five years, but the person holding that office may be nominated for such further terms, not exceeding five years, as the President may deem appropriate.",
          },
          {
            number: "4",
            text: "A person holding the office of Inspector General of Police shall be subject to removal by the President only by reason of that person being -",
            subsections: [
              {
                number: "(a)",
                text: "incompetent in the exercise of his or her duties;",
              },
              {
                number: "(b)",
                text: "compromised in the exercise of his or her duties to the extent that his or her capacity to exercise his or her powers impartially is in serious question;",
              },
              {
                number: "(c)",
                text: "otherwise incapacitated; and",
              },
              {
                number: "(d)",
                text: "over the age prescribed for retirement.",
              },
            ],
          },
          {
            number: "5",
            text: "Subject to subsection (4), in the exercise of the duties and powers vested in the office of the Inspector General of Police by this Constitution or any other law, the person holding that office shall not be subject to the direction or control of any other person or authority, other than as prescribed in this Constitution or an Act of Parliament.",
          },
          {
            number: "6",
            text: "The Inspector General of Police may delegate such powers as are conferred on him or her by this Constitution or by an Act of Parliament to such other persons or authorities, being part of the Malawi Police Force, as he or she may consider appropriate.",
          },
          {
            number: "7",
            text: "With respect to any powers under this section, the Inspector General of Police shall notify the Police Service Commission of any delegation and the Police Service Commission may prescribe any regulations it considers appropriate in relation to the manner and form of reports that the person exercising that power shall make and shall specify such restrictions as may be required to ensure the proper exercise of the powers delegated under this section in accordance with the law.",
          },
        ],
      },
      {
        number: "155",
        title: "The Police Service Commission",
        content: [
          {
            number: "1",
            text: "There shall be a Police Service Commission with such powers and functions as are conferred upon it by this Constitution or an Act of Parliament.",
          },
          {
            number: "2",
            text: "Subject to this Constitution, power to appoint persons to hold or act in offices in the Malawi Police Force other than that of Inspector General of Police, including the power to confirm appointments, and to remove such persons from office shall vest in the Police Service Commission.",
          },
          {
            number: "3",
            text: "The Police Service Commission shall, subject to this Constitution and any general directions of an Act of Parliament, exercise disciplinary control over persons holding or acting in any office to which this section applies.",
          },
        ],
      },
      {
        number: "156",
        title: "Power to delegate",
        content: [
          {
            number: "1",
            text: "The Police Service Commission, may, subject to such conditions as may be laid down by an Act of Parliament, delegate powers conferred by this Constitution or an Act of Parliament by directions in writing to any member of the Commission or to any public officer or public body.",
          },
          {
            number: "2",
            text: "Where any person or body may from time to time exercise powers under this section on behalf of the Police Service Commission in accordance with subsection (1), the Police Service Commission shall-",
            subsections: [
              {
                number: "(a)",
                text: "require that person or body to furnish reports in such manner or form as specified in the directions which delegated those powers; and",
              },
              {
                number: "(b)",
                text: "hear such complaints or appeals from persons with sufficient interest relating to the exercise of powers delegated under this section and shall have the authority to -",
                subsections: [
                  {
                    number: "(i)",
                    text: "quash the decision of a person or body exercising such powers;",
                  },
                  {
                    number: "(ii)",
                    text: "exercise such disciplinary powers in relation to such person or body, subject to the conditions laid down by an Act of Parliament; and",
                  },
                  {
                    number: "(iii)",
                    text: "revoke directions delegating powers to any person or body:",
                  },
                ],
              },
            ],
          },
          {
            number: "",
            text: "Provided that nothing in this section shall prejudice the right of any person who is the subject of a decision made by or on behalf of the Police Service Commission to appeal to the High Court or the right of any person with sufficient interest in such a decision to petition the High Court for judicial review of that decision.",
          },
        ],
      },
      {
        number: "157",
        title: "Composition",
        content: [
          {
            number: "1",
            text: "The Police Service Commission shall consist of the following members -",
            subsections: [
              {
                number: "(a)",
                text: "such Justice of Appeal or Judge as may for the time being be nominated in that behalf by the Judicial Service Commission and who shall be the Chairman of the Police Service Commission;",
              },
              {
                number: "(b)",
                text: "such member of the Civil Service Commission as may for the time being be nominated in that behalf by the Civil Service Commission;",
              },
              {
                number: "(c)",
                text: "the Inspector General of Police or such senior officer in the Malawi Police Force as the Inspector General may for the time being nominate in that behalf;",
              },
              {
                number: "(d)",
                text: "the Ombudsman; and",
              },
              {
                number: "(e)",
                text: "such legal practitioner as may for the time being be nominated in that behalf by the President and confirmed by the Public Appointments Committee.",
              },
            ],
          },
          {
            number: "2",
            text: "Any three members of the Police Service Commission shall form a quorum.",
          },
          {
            number: "3",
            text: "A person shall not be qualified for appointment as a member of the Police Service Commission if he or she is President, Vice-President, a Minister or Deputy-Minister, a Member of Parliament or a police officer.",
          },
          {
            number: "4",
            text: "Subject to this section, the office of a member of the Police Service Commission shall become vacant -",
            subsections: [
              {
                number: "(a)",
                text: "at the expiration of three years from the date of that person's appointment, unless reappointed to a new three year term:",
              },
            ],
          },
          {
            number: "",
            text: "Provided that this paragraph shall not apply where the member in question still holds the office of Inspector General of Police or of Ombudsman, or other office in which behalf that person was appointed to the Police Service Commission; or",
            subsections: [
              {
                number: "(b)",
                text: "if any circumstances arise that, if that person were not a member of the Police Service Commission, would cause that person to be disqualified from appointment as such.",
              },
            ],
          },
        ],
      },
      {
        number: "158",
        title: "Political independence of the Malawi Police Force",
        content: [
          {
            number: "1",
            text: "Members of the Malawi Police Force shall ensure that they exercise their functions, powers and duties as impartial servants of the general public and the Government of the day.",
          },
          {
            number: "2",
            text: "No member of the Malawi Police Force shall be permitted to participate directly in political activities:",
            subsections: [
              {
                number: "",
                text: "Provided that -",
                subsections: [
                  {
                    number: "(a)",
                    text: "any member of the Malawi Police Force of Malawi shall have the right to resign in order to participate directly in political activities;",
                  },
                  {
                    number: "(b)",
                    text: "nothing in this section shall be deemed to prejudice any member of the Malawi Police Force having the absolute right to vote in accordance with this Constitution; and",
                  },
                  {
                    number: "(c)",
                    text: "nothing in this subsection shall prejudice the right of any member of the Malawi Police Force to hold office in or be a member of any association, group or professional body, the purposes of which are principally to represent their members interests in relation to the terms and conditions of employment or the general carrying on of any profession or trade or the promotion of any interest, not pertaining directly to the promotion of a political party, campaign or philosophy or otherwise inconsistent with the functions of the Malawi Police Force.",
                  },
                ],
              },
            ],
          },
          {
            number: "3",
            text: "No government or political party shall cause any member of the Malawi Police Force acting in that behalf to exercise functions, powers or duties for the purposes of promoting or undermining the interests or affairs of any political party or individual member of that party, nor shall any member of the Malawi Police Force, acting in that behalf, promote or undermine any party or individual member of that party.",
          },
          {
            number: "4",
            text: "No government or political party shall cause any member of the Malawi Police Force, acting in that behalf, to deploy resources, whether they be financial, material or human resources, for the purposes of promoting or undermining any political party or member of a political party or interest group, nor shall any member of the Malawi Police Force, acting in that behalf, cause such deployment:",
            subsections: [
              {
                number: "",
                text: "Provided that nothing in this section shall be construed as derogating from the duty of the Police to uphold the rights and afford protection to all political parties, persons and organizations equally, without fear or favour, in accordance with this Constitution and subject to any law.",
              },
            ],
          },
          {
            number: "5",
            text: "Any member of the Malawi Police Force who contravenes this section shall be subject to such disciplinary measures as the Police Service Commission considers appropriate, taking into account the gravity and circumstances of the contravention, subject to such regulations as may be prescribed by an Act of Parliament.",
          },
          {
            number: "6",
            text: "Where the Police Service Commission is satisfied that a government or political party or member of a political party has acted in contravention of subsection (3) or subsection (4), the Police Service Commission may initiate proceedings before the High Court for punitive damages and, in the case of a contravention of subsection (4), for the recovery of such resources or sums equivalent to the benefit of the enjoyment of those resources from the government, or political party or member of a political party who has so benefitted, as the case may be.",
          },
        ],
      },
    ],
  },

  {
    id: "chapter16",
    title: "Chapter XVI: THE DEFENCE FORCES",
    sections: [
      {
        number: "159",
        title: "The Defence Forces of Malawi",
        content: [
          {
            number: "",
            text: "There shall be no military force constituted in Malawi other than the Defence Forces of Malawi provided for and regulated by this Chapter.",
          },
        ],
      },
      {
        number: "160",
        title: "Constitutional position of the Defence Forces of Malawi",
        content: [
          {
            number: "1",
            text: "The Defence Forces of Malawi shall operate at all times under the direction of those civil authorities in whom this Constitution vests such power, and shall -",
            subsections: [
              {
                number: "(a)",
                text: "uphold the sovereignty and territorial integrity of the Republic and guard against threats to the safety of its citizens by force of arms;",
              },
              {
                number: "(b)",
                text: "uphold and protect the constitutional order in the Republic and assist the civil authorities in the proper exercise of their functions under this Constitution;",
              },
              {
                number: "(c)",
                text: "provide technical expertise and resources to assist the civilian authorities in the maintenance of essential services in times of emergency; and",
              },
              {
                number: "(d)",
                text: "perform such other duties outside the territory of Malawi as may be required of them by any treaty entered into by Malawi in accordance with the prescriptions of international law.",
              },
            ],
          },
          {
            number: "2",
            text: "No person or authority may direct or deploy the Defence Forces of Malawi to act in contravention of this Constitution.",
          },
        ],
      },
      {
        number: "161",
        title: "Responsibility for the Defence Forces of Malawi",
        content: [
          {
            number: "1",
            text: "The ultimate responsibility for the Defence Forces of Malawi shall vest in the President as Commander-in-Chief.",
          },
          {
            number: "2",
            text: "Any power conferred on the President by virtue of this Chapter shall only be exercised on the recommendation of an Army Council which shall be constituted under an Act of Parliament and which shall include the Minister responsible for Defence and the High Command of the Defence Forces of Malawi and which shall have -",
            subsections: [
              {
                number: "(a)",
                text: "the power to determine the operational use of the Defence Forces of Malawi;",
              },
              {
                number: "(b)",
                text: "the power to appoint and remove from office senior officers and other members of the Defence Forces of Malawi; and",
              },
              {
                number: "(c)",
                text: "such other powers and duties as may be prescribed by an Act of Parliament.",
              },
            ],
          },
          {
            number: "3",
            text: "All powers conferred in subsection (2) shall be exercised subject to the scrutiny of a Defence and Security Committee of the National Assembly established by section 162.",
          },
          {
            number: "4",
            text: "The Army Council may, by directions in writing and subject to such conditions as may be laid down by an Act of Parliament or are adopted by the National Assembly on the recommendation of the Defence and Security Committee of the National Assembly, delegate to any member of the Defence Forces of Malawi any of the powers conferred on the Army Council by an Act of Parliament.",
          },
        ],
      },
      {
        number: "162",
        title: "The Defence and Security Committee of the National Assembly",
        content: [
          {
            number: "1",
            text: "There shall be a Defence Security Committee of the National Assembly which shall represent proportionally political parties having seats in the National Assembly, and shall have such powers and functions as may be conferred upon it by this Constitution and an Act of Parliament.",
          },
          {
            number: "",
            text: "The Defence and Security Committee may be assembled at any time, notwithstanding that Parliament stands adjourned and, where Parliament stands dissolved, shall continue to be constituted until such time as a new session Parliament begins and a new Defence and Security Committee is appointed but no member shall otherwise hold office for more than one year.",
          },
        ],
      },
    ],
  },

  {
    id: "chapter17",
    title: "Chapter XVII: PRISONS",
    sections: [
      {
        number: "163",
        title: "The Malawi Prison Service",
        content: [
          {
            number: "",
            text: "The Malawi Prisons Service shall consist of all penal institutions, labour camps, special and secure schools and other institutions that are used to house, detain and rehabilitate persons sentenced to imprisonment in whatever form such imprisonment may take, but shall not include holding cells in police stations.",
          },
        ],
      },
      {
        number: "164",
        title: "The Chief Commissioner for Prisons",
        content: [
          {
            number: "1",
            text: "There shall be a Chief Commissioner for Prisons who shall be responsible for the Malawi Prisons Service and whose office shall be a public office with such powers, functions and duties as are conferred on it by this Constitution and an Act of Parliament.",
          },
          {
            number: "2",
            text: "The principal responsibilities of the Chief Commissioner for Prisons shall be to ensure the proper and efficient administration of the penal institutions that comprise the Malawi Prisons Service, subject to and in accordance with -",
            subsections: [
              {
                number: "(a)",
                text: "the protection of rights and other provisions of this Constitution or any other law;",
              },
              {
                number: "(b)",
                text: "the recommendations of the Prisons Service Commission and the Inspectorate of Prisons in accordance with an Act of Parliament; and",
              },
              {
                number: "(c)",
                text: "the directions of the courts in relation to persons convicted by the courts to a sentence of incarceration or penal servitude within a penal institution, or incarcerated by the courts pending execution of the sentence of the courts, or awaiting sentence by the courts or otherwise on remand to the custody of the Prisons Service or being held on behalf of the Malawi Police Force in connexion with any law.",
              },
            ],
          },
          {
            number: "3",
            text: "Save as provided in this section, in the exercise of the duties and powers vested in the office of the Chief Commissioner for Prisons by this Constitution or any other law, the person holding that office shall not be subject to the direction or control of any other person or authority, other than as prescribed in this Constitution and an Act of Parliament.",
          },
        ],
      },
      {
        number: "165",
        title: "Power to delegate",
        content: [
          {
            number: "1",
            text: "The Chief Commissioner for Prisons may delegate the powers conferred on him or her by this Constitution or an Act of Parliament to such persons or authorities, being part of the Malawi Prisons Service, as he or she considers appropriate.",
          },
          {
            number: "2",
            text: "With respect to the delegation of powers in subsection (1)-",
            subsections: [
              {
                number: "(a)",
                text: "the Chief Commissioner for Prisons shall notify the Prisons Service Commission and the Inspectorate of Prisons of any such delegation; and",
              },
              {
                number: "(b)",
                text: "the Prisons Service Commission or, as the case may be, the Inspectorate of Prisons may -",
                subsections: [
                  {
                    number: "(i)",
                    text: "prescribe any regulations it considers appropriate in relation to the manner and form of reports that the person or authority exercising that power shall make; and",
                  },
                  {
                    number: "(ii)",
                    text: "shall prescribe such restrictions as may be required to ensure the proper exercise of powers delegated under this section.",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        number: "166",
        title: "Appointment of the Chief Commissioner for Prisons",
        content: [
          {
            number: "1",
            text: "The Chief Commissioner for Prisons shall be appointed by the President, but the Public Appointments Committee may at any time inquire as to the competence of the person so appointed to carry out the duties of that office and as to such other questions as may have direct bearing on the performance of the duties of that office.",
          },
          {
            number: "2",
            text: "The office of the Chief Commissioner for Prisons shall become vacant after the person holding that office has served for five years, provided that the person holding that office may be appointed for such further term, not exceeding five years, as the President considers appropriate.",
          },
          {
            number: "3",
            text: "A person holding the office of Chief Commissioner for Prisons shall be subject to removal by the President only by reason of that person being -",
            subsections: [
              {
                number: "(a)",
                text: "incompetent in the exercise of his or her duties;",
              },
              {
                number: "(b)",
                text: "compromised in the exercise of his or her duties to the extent that his or her capacity to impartially exercise the duties of that office is in serious question;",
              },
              {
                number: "(c)",
                text: "otherwise incapacitated; or",
              },
              {
                number: "(d)",
                text: "over the age prescribed for retirement.",
              },
            ],
          },
        ],
      },
      {
        number: "167",
        title: "The Prison Service Commission",
        content: [
          {
            number: "1",
            text: "There shall be a Prisons Service Commission with the powers and functions conferred on it by this Constitution and by an Act of Parliament.",
          },
          {
            number: "2",
            text: "The Prisons Service Commission shall have the power to appoint persons to hold or act in offices in the Prison Service of Malawi, other than the Chief Commissioner for Prisons, including the power to confirm appointments and to remove such persons from office.",
          },
          {
            number: "3",
            text: "The Prisons Service Commission shall, subject to this Constitution and any Act of Parliament, exercise disciplinary control over persons holding or acting in any office to which this section applies.",
          },
          {
            number: "4",
            text: "The Prison Service Commission may, subject to such conditions as may be laid down by an Act of Parliament, delegate powers under this section by directions in writing to any member of the Commission or to any public officer or public body, being part of the Malawi Prisons Service.",
          },
          {
            number: "5",
            text: "Where any person or body may, from time to time, exercise powers under this section on behalf of the Prisons Service Commission, in accordance with subsection (4), the Prisons Service Commission shall -",
            subsections: [
              {
                number: "(a)",
                text: "require that person or body to furnish reports in such manner or form as the Commission has specified in the directions by which it delegated those powers;",
              },
              {
                number: "(b)",
                text: "hear such complaints or appeals from persons in sufficient interest relating to the exercise of powers under this section and shall have the authority to-",
                subsections: [
                  {
                    number: "(i)",
                    text: "quash the decision of a person or body exercising such powers;",
                  },
                  {
                    number: "(ii)",
                    text: "exercise such disciplinary powers with relation to such person or body, subject to the conditions laid down by an Act of Parliament;",
                  },
                  {
                    number: "(iii)",
                    text: "revoke directions delegating powers to any person or body:",
                  },
                ],
              },
            ],
          },
          {
            number: "",
            text: "Provided that nothing in this section shall prejudice the right of any person who is the subject of a decision made by or on behalf of the Prisons Service Commission to appeal to the High Court or the right of any person with sufficient interest in such a decision to petition the High Court for judicial review of that decision.",
          },
        ],
      },
      {
        number: "168",
        title: "Composition of Prison Service Commission",
        content: [
          {
            number: "1",
            text: "The Prisons Service Commission shall consist of the following members -",
            subsections: [
              {
                number: "(a)",
                text: "such Justice of Appeal or Judge as may for the time being be nominated in that behalf by the Judicial Service Commission, who shall be chairman;",
              },
              {
                number: "(b)",
                text: "such member of the Civil Service Commission as may for the time being be nominated in that behalf by the Civil Service Commission;",
              },
              {
                number: "(c)",
                text: "such legal practitioner as may for the time being be nominated by the President subject to confirmation by the Public Appointments Committee;",
              },
              {
                number: "(d)",
                text: "such person as may be nominated from time to time in that behalf by the Inspectorate of Prisons; and",
              },
              {
                number: "(e)",
                text: "the Chief Commissioner for Prisons or a person nominated by the Chief Commissioner of Prisons from time to time in that behalf, being a senior member of the Malawi Prisons Service.",
              },
            ],
          },
          {
            number: "2",
            text: "A person shall not be qualified for appointment as a member of the Prisons Service Commission if he or she is President, Vice-President, a Minister or Deputy-Minister, or a member of Parliament.",
          },
          {
            number: "3",
            text: "Subject to this section, the office of a member of the Prisons Service Commission shall become vacant -",
            subsections: [
              {
                number: "(a)",
                text: "at the expiration of three years from the date of that member's appointment, unless the member is reappointed to a further term not exceeding three years; or",
              },
              {
                number: "(b)",
                text: "if any circumstances arise that, if that member were not a member of the Prisons Service Commission, would cause that member to be disqualified from appointment as such:",
              },
            ],
          },
          {
            number: "",
            text: "Provided that paragraph (a) shall not apply where the member in question still holds the office of Chief Commissioner for Prisons, in which behalf that person was appointed to the Prisons Service Commission.",
          },
        ],
      },
      {
        number: "169",
        title: "The Inspectorate of Prisons",
        content: [
          {
            number: "1",
            text: "There shall be an Inspectorate of Prisons which shall have such powers, functions and duties in relation to the Malawi Prisons Service as are conferred on it by this Constitution or an Act of Parliament.",
          },
          {
            number: "2",
            text: "The Inspectorate of Prisons shall exercise its powers, functions and duties independent of any direction or interference by any other person or authority.",
          },
          {
            number: "3",
            text: "The Inspectorate of Prisons shall -",
            subsections: [
              {
                number: "(a)",
                text: "be charged with monitoring the conditions, administration and general functioning of penal instructions taking due account of applicable international standards;",
              },
              {
                number: "(b)",
                text: "have such powers as shall be required for it to make investigations and shall have the power to require any person to answer questions relating to such subjects as are relevant to those investigations;",
              },
              {
                number: "(c)",
                text: "have the power to visit any and all institutions within the Malawi Prisons Service with or without notice and without let or hindrance; and",
              },
              {
                number: "(d)",
                text: "exercise such other powers as may be prescribed by an Act of Parliament.",
              },
            ],
          },
          {
            number: "4",
            text: "The Inspectorate of Prisons shall cause to be laid before the National Assembly such reports as the Inspectorate of Prisons may make and such reports shall be so laid through the Minister responsible for Prisons in the form of a motion for acceptance of the recommendations of the Inspectorate of Prisons.",
          },
          {
            number: "5",
            text: "Where the recommendations of the Inspectorate of Prisons require amendment to any law, the Minister shall lay before Parliament those recommendations in the form of a Bill.",
          },
          {
            number: "6",
            text: "The powers conferred on the Inspectorate for Prisons by this section shall also be exercisable by the Inspectorate of Prisons with respect to holding cells in police stations.",
          },
        ],
      },
      {
        number: "170",
        title: "Composition of the Inspectorate of Prisons",
        content: [
          {
            number: "1",
            text: "The Inspectorate of Prisons shall consist of the following members -",
            subsections: [
              {
                number: "(a)",
                text: "such Justice of Appeal or Judge as shall from time to time be nominated in that behalf by the Judicial Service Commission, who shall be chairman;",
              },
              {
                number: "(b)",
                text: "the Chief Commissioner for Prisons or such person as he or she may nominate in that behalf being a senior member of the Malawi Prisons Service;",
              },
              {
                number: "(c)",
                text: "such member of the Prisons Service Commission, other than the Chief Commissioner for Prisons, as shall from time to time be nominated in that behalf by that Commission;",
              },
              {
                number: "(d)",
                text: "such Magistrate as shall from time to time be nominated in that behalf by the Judicial Service Commission; and",
              },
              {
                number: "(e)",
                text: "the Ombudsman.",
              },
            ],
          },
          {
            number: "2",
            text: "The Inspectorate of Prisons shall have power to co-opt persons as representatives of any local or international organizations having an office in Malawi involved in the monitoring of human rights or more generally concerned with the welfare of offenders as may be approved of by the membership of the Inspectorate of Prisons.",
          },
        ],
      },
    ],
  },

  {
    id: "chapter18",
    title: "Chapter XVIII: FINANCE",
    sections: [
      {
        number: "171",
        title: "Revenue",
        content: [
          {
            number: "",
            text: "No tax, rate, duty, levy or imposition shall be raised, levied or imposed by or for the purposes of the Government or any local government authority otherwise than by or under the authority of the law.",
          },
        ],
      },
      {
        number: "172",
        title: "The Consolidated Fund",
        content: [
          {
            number: "",
            text: "All revenues or other moneys raised or received for the purposes of the Government shall, subject to this Constitution and any Act of Parliament, be paid into and form one Fund, to be known as the Consolidated Fund.",
          },
        ],
      },
      {
        number: "173",
        title: "Withdrawal of money from the Consolidated Fund",
        content: [
          {
            number: "1",
            text: "No money shall be withdrawn from the Consolidated Fund except -",
            subsections: [
              {
                number: "(a)",
                text: "to meet expenditure that is charged upon the Fund by this Constitution or by any Act of Parliament consistent with this Constitution; or",
              },
              {
                number: "(b)",
                text: "where the issue of those moneys has been authorized by an Appropriation Act, a Supplementary Appropriation Act or by an Act made in pursuance of subsection (5) or of sections 178, 179, 180, 181 or 182 or by a resolution of the National Assembly made in accordance with section 177:",
                subsections: [
                  {
                    number: "",
                    text: "Provided that this subsection shall not apply to any sums mentioned in section 175 (3).",
                  },
                ],
              },
            ],
          },
          {
            number: "2",
            text: "Where any moneys are charged by this Constitution or by any Act of Parliament upon the Consolidated Fund, they shall be paid out of that Fund by the Minister responsible for Finance to the person or authority to whom the payment is due.",
          },
          {
            number: "3",
            text: "No moneys shall be withdrawn from the Consolidated Fund except in the manner prescribed by the National Assembly.",
          },
          {
            number: "4",
            text: "The investment of moneys forming part of the Consolidated Fund by way of deposit with a bank or such other secure investment as may be approved by the National Assembly shall not be regarded as a withdrawal of those moneys from the Consolidated Fund for the purposes of this Constitution.",
          },
          {
            number: "5",
            text: "Notwithstanding subsection (1), provisions may be made by or under an Act of Parliament authorizing withdrawals to be made from the Consolidated Fund, in such circumstances and to such extent as may be prescribed by or under such Act of Parliament, for the purpose of making allowances to persons or authorities other than the Government:",
            subsections: [
              {
                number: "",
                text: "Provided that -",
                subsections: [
                  {
                    number: "(a)",
                    text: "no moneys shall be advanced from the Consolidated Fund under this subsection save on condition that they are repayable by the person or authority to whom or on behalf of whom they are advanced; and",
                  },
                  {
                    number: "(b)",
                    text: "this section shall not apply with respect to -",
                    subsections: [
                      {
                        number: "(i)",
                        text: "the proceeds of Government loans raised for a specific purpose under an Act of Parliament;",
                      },
                      {
                        number: "(ii)",
                        text: "money or interest received by the Government subject to a trust;",
                      },
                      {
                        number: "(iii)",
                        text: "advance drawings and repayments of those drawings, authorized by the National Assembly; and",
                      },
                      {
                        number: "(iv)",
                        text: "such special funds under this Constitution where it is specified that this section should not apply.",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        number: "174",
        title: "Expenditure charged on the Consolidated Fund",
        content: [
          {
            number: "1",
            text: "There shall be charged on the Consolidated Fund in addition to any grant, remuneration or other moneys so charged by this Constitution or any Act consistent with this Constitution -",
            subsections: [
              {
                number: "(a)",
                text: "all debt charges for which the Government is liable;",
              },
              {
                number: "(b)",
                text: "all pensions, compensations for loss of office and gratuities for which the Government is liable;",
              },
              {
                number: "(c)",
                text: "any moneys required to satisfy any judgement, decision or award made or given against the Government by any court or tribunal other than those provided for in the National Compensation Fund; and",
              },
              {
                number: "(d)",
                text: "all moneys or debt charges charged before the appointed day upon the revenues or public funds of Malawi.",
              },
            ],
          },
          {
            number: "2",
            text: 'For the purposes of this section, "debt charges" includes interest, sinking fund charges, the repayment or amortization of debt, and all expenditure in connexion with the raising of loans on the security of the Consolidated Fund and the service and redemption of debt thereby created.',
          },
        ],
      },
      {
        number: "175",
        title: "Annual estimates",
        content: [
          {
            number: "1",
            text: "The Minister responsible for Finance shall lay before the National Assembly a statement of the estimated receipts and the expenditure of the Government in respect of that financial year, other than the sums specified in subsection (3).",
          },
          {
            number: "2",
            text: "The statement of estimates shall be laid before the National Assembly in respect of every financial year and shall be so laid before the commencement of that financial year.",
          },
          {
            number: "3",
            text: "Where the National Assembly does not propose to debate the estimates until after the commencement of the financial year to which they relate, the estimates of the revenue may be laid before the National Assembly at any time before the commencement of such debate.",
          },
          {
            number: "4",
            text: "The estimates of expenditure shall show separately -",
            subsections: [
              {
                number: "(a)",
                text: "the total sums required to meet the expenditure charged on the Consolidated Fund; and",
              },
              {
                number: "(b)",
                text: "the sums respectively required to meet the heads of other expenditure proposed to be met from the Consolidated Fund.",
              },
            ],
          },
          {
            number: "5",
            text: "The sums to be shown in the estimates of receipts and expenditure shall not include -",
            subsections: [
              {
                number: "(a)",
                text: "sums representing the proceeds of any loan raised by the Government for a specific purpose and appropriated for that purpose by the Act authorizing the raising of the loan;",
              },
              {
                number: "(b)",
                text: "sums representing any money or interest on money received by the Government subject to a trust and to be held or applied in accordance with the terms of that trust;",
              },
              {
                number: "(c)",
                text: "sums representing moneys authorized to be advanced from the Consolidated Fund under an Act of Parliament and repayments thereof; and",
              },
              {
                number: "(d)",
                text: "sums representing moneys received for or to be applied from any special fund established and regulated by this Constitution or by an Act of Parliament which provides that subsections (1) and (4) shall not apply to that fund.",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: "chapter19",
    title: "Chapter XIX: THE RESERVE BANK OF MALAWI",
    sections: [
      {
        number: "185",
        title: "The Reserve Bank of Malawi",
        content: [
          {
            number: "1",
            text: "There shall be established by an Act of Parliament a central bank of the Republic, known as the Reserve Bank of Malawi which shall serve as the State's principal instrument for the control of money supply, currency and the institutions of finance and shall serve generally in accordance with the normal functions of a central bank.",
          },
          {
            number: "2",
            text: "The Bank shall be controlled by a Board which shall consist of a chairman and members of the Board who shall, subject to this Constitution, be appointed in accordance with the Act of Parliament by which the Bank is established.",
          },
        ],
      },
    ],
  },

  {
    id: "chapter21",
    title: "Chapter XXI: AMENDMENT OF THIS CONSTITUTION",
    sections: [
      {
        number: "195",
        title: "Power to amend",
        content: [
          {
            number: "",
            text: "Parliament may amend this Constitution in accordance with this Chapter.",
          },
        ],
      },
      {
        number: "196",
        title: "Restrictions on amendments",
        content: [
          {
            number: "1",
            text: "Subject to this section, Parliament may amend this Chapter and the sections of this Constitution listed in the Schedule only if-",
            subsections: [
              {
                number: "(a)",
                text: "the provision to be amended and the proposed amendment to it have been put to a referendum of the people of Malawi and the majority of those voting have voted for the amendment; and",
              },
              {
                number: "(b)",
                text: "the Electoral Commission has so certified to the Speaker.",
              },
            ],
          },
          {
            number: "2",
            text: "The Parliament may pass a Bill proposing an amendment to which the conditions set out in subsection (1) have been satisfied by a simple majority.",
          },
          {
            number: "3",
            text: "Notwithstanding subsection (1), Parliament may pass a Bill containing an amendment to the provisions referred to in that subsection without a referendum where-",
            subsections: [
              {
                number: "(a)",
                text: "the amendment would not affect the substance or the effect of the Constitution;",
              },
              {
                number: "(b)",
                text: "the Speaker has so certified; and",
              },
              {
                number: "(c)",
                text: "the Bill is supported by a majority of at least two-thirds of the total number of members of the National Assembly entitled to vote.",
              },
            ],
          },
        ],
      },
      {
        number: "197",
        title: "Amendments by Parliament",
        content: [
          {
            number: "",
            text: "Subject to section 196, Parliament may amend those Chapters and sections of this Constitution not listed in the Schedule only if the Bill proposing the amendment is supported by at least two-thirds of the total number of members of the National Assembly entitled to vote.",
          },
        ],
      },
    ],
  },

  {
    id: "chapter22",
    title: "Chapter XXII: TRANSITIONAL PROVISIONS",
    sections: [
      {
        number: "198",
        title:
          "Republic, etc. to be constituted in accordance with this Constitution",
        content: [
          {
            number: "",
            text: "The Republic of Malawi, the organs of State and the offices referred to in this Constitution shall be defined and constituted in accordance with this Constitution.",
          },
        ],
      },
      {
        number: "199",
        title: "Status of this Constitution",
        content: [
          {
            number: "",
            text: "This Constitution shall have the status as supreme law and there shall be no legal or political authority save as is provided by or under this Constitution.",
          },
        ],
      },
      {
        number: "200",
        title: "Saving of laws in force",
        content: [
          {
            number: "",
            text: "Except in so far as they are inconsistent with this Constitution, all Acts of Parliament, common law and customary law in force on the appointed day shall continue to have force of law, as if they had been made in accordance with and in pursuance of this Constitution:",
            subsections: [
              {
                number: "",
                text: "Provided that any laws currently in force may be amended or repealed by an Act of Parliament or be declared unconstitutional by a competent court.",
              },
            ],
          },
        ],
      },
      {
        number: "201",
        title: "Elections to the National Assembly",
        content: [
          {
            number: "",
            text: "For the purposes of this Constitution the first National Assembly after the date of commencement of this Constitution shall be composed of those persons successfully elected to the National Assembly in accordance with the Act of Parliament then in force for the election of members of the National Assembly.",
          },
        ],
      },
      {
        number: "202",
        title: "Elections to the office of President",
        content: [
          {
            number: "",
            text: "For the purposes of this Constitution the first President after the date of commencement of this Constitution shall be the person successfully elected in accordance with the Act of Parliament then in force for the election of a person to the office of President.",
          },
        ],
      },
      {
        number: "203",
        title: "Saving of judicial power",
        content: [
          {
            number: "",
            text: "The High Court shall have the same jurisdiction, powers and procedures as before the commencement of this Constitution, subject to amendment or repeal of such powers by an Act of Parliament in accordance with this Constitution.",
          },
        ],
      },
      {
        number: "204",
        title: "Pending legal actions",
        content: [
          {
            number: "1",
            text: "All legal actions which, at the commencement of this Constitution, are pending or being undertaken before any court other than before the Supreme Court of Appeal, the High Court, a Magistrate Court, a District Traditional Appeal Court, District Traditional Court, a Grade A Traditional Court, or a Grade B Traditional Court shall be commenced or continued before the High Court of Malawi or before such Magistrate's court or District Traditional Appeal Court or District Traditional Court or Grade A Traditional Court or Grade B Traditional Court as the Registrar of the High Court shall direct.",
          },
          {
            number: "2",
            text: "All legal actions which, at the commencement of this Constitution, are pending or being undertaken before any Magistrate Court shall be commenced or continued before that court which shall have the same jurisdiction, powers and procedures as before the commencement of this Constitution subject to amendment or repeal of such powers by an Act of Parliament.",
          },
          {
            number: "3",
            text: "All legal actions which, at the commencement of this Constitution, are pending or being undertaken before a District Traditional Appeal Court, a District Traditional Court, a Grade A Traditional Court or a Grade B Traditional Court before the commencement of this Constitution shall be commenced or continued before that court which shall have the same jurisdiction, powers and procedures as before the commencement of this Constitution subject to amendment or repeal of such powers by an Act of Parliament.",
          },
          {
            number: "4",
            text: "Appeals against the decisions of a District Traditional Appeal Court or a District Traditional Court shall lie to the High Court which, in hearing such appeals, may sit with assessors appointed in accordance with an Act of Parliament.",
          },
        ],
      },
      {
        number: "205",
        title: "Judgements and sentences pending execution",
        content: [
          {
            number: "",
            text: "All judgments or sentences pending execution shall be executed as if such judgments or sentences were ordered in accordance with this Constitution:",
            subsections: [
              {
                number: "",
                text: "Provided that where the Constitution provides new grounds for appeal, any such appeal shall act as a stay of execution and the appeal shall be to the High Court.",
              },
            ],
          },
        ],
      },
      {
        number: "206",
        title: "Existing appointments",
        content: [
          {
            number: "1",
            text: "Subject to the provisions of this Constitution, any person holding office under any law in force on the date of the commencement of this Constitution shall continue to hold such office until such time as -",
            subsections: [
              {
                number: "(a)",
                text: "that person is confirmed in that post;",
              },
              {
                number: "(b)",
                text: "that person is replaced by another person appointed or elected to that post in accordance with the Constitution; or",
              },
              {
                number: "(c)",
                text: "that person resigns or retires or is removed in accordance with this Constitution:",
              },
            ],
          },
          {
            number: "",
            text: "Provided that where this Constitution has created an office with a different designation, but an equivalent function is exercised by an office existing at the time of the date of the commencement of this Constitution, a person occupying that office shall, from the date of commencement of this Constitution, have such powers, functions and designation as is prescribed for the equivalent office created by this Constitution.",
          },
          {
            number: "2",
            text: "For the purposes of subsection 51 (2) and subsection 80 (1), and only for that purpose, the person who at the commencement of this Constitution is holding the office of Chief Justice shall be deemed to be appointed under this Constitution as Chief Justice.",
          },
        ],
      },
      {
        number: "207",
        title: "Vesting of lands, etc., in the Republic",
        content: [
          {
            number: "",
            text: "Subject to the provisions of this Constitution, all lands and territories of Malawi are vested in the Republic.",
          },
        ],
      },
      {
        number: "208",
        title: "Saving of rights of Government in property",
        content: [
          {
            number: "",
            text: "The Government shall have title to all rights in property which are vested in the Government of Malawi on the date of the commencement of this Constitution save as otherwise provided by section 209 (2) and provided that the disposal of rights in property where title is vested in the Government shall not be made without the consent of Parliament.",
          },
        ],
      },
      {
        number: "209",
        title: "Continuation of rights of persons in property",
        content: [
          {
            number: "1",
            text: "All persons who have rights in property at the date of the commencement of this Constitution shall continue to have such rights under this Constitution and any other law.",
          },
          {
            number: "2",
            text: "This section shall not apply in respect of rights in property that have been acquired or vested by or on behalf of the Government where that property was acquired or vested at any time since 6th July, 1964, and where that right in property was obtained from citizens or permanent residents of Malawi -",
            subsections: [
              {
                number: "(a)",
                text: "unlawfully according to the laws then in force in Malawi;",
              },
              {
                number: "(b)",
                text: "by virtue of any law not being a penal law passed during that time that did not provide for adequate compensation; or",
              },
              {
                number: "(c)",
                text: "through abandonment by reason of duress of circumstances.",
              },
            ],
          },
          {
            number: "3",
            text: 'For the purposes of this section "Government" shall mean the President, the Cabinet, the Ministries, other organs of the President and Cabinet and their agents, including individuals and bodies under the authority of the President, the Cabinet or the Ministries.',
          },
          {
            number: "4",
            text: "Rights in property which are not recognized by virtue of this section shall be vested in the National Compensation Fund and shall be disposed of in accordance with the principles, procedures and rules of the National Compensation Tribunal.",
          },
          {
            number: "5",
            text: "Persons occupying or using property vested in the National Compensation Fund shall continue to occupy and use that property, as if they retained full legal and equitable title until such time as the National Compensation Tribunal otherwise orders.",
          },
        ],
      },
    ],
  },

  {
    id: "chapter23",
    title: "Chapter XXIII: MISCELLANEOUS",
    sections: [
      {
        number: "212",
        title: "Coming into force of this Constitution",
        content: [
          {
            number: "1",
            text: 'This Constitution shall provisionally come into force on 18th May, 1994, being the appointed day, and shall provisionally apply as the Constitution of the Republic of Malawi for a period of not less and not more than twelve months from that date (in this section referred to as the "period of provisional application").',
          },
          {
            number: "2",
            text: "During the period of provisional application Parliament may amend or repeal this Constitution in accordance with this section subject only to section 196.",
          },
          {
            number: "3",
            text: "Within fourteen days of the first sitting of Parliament after the appointed day, Parliament shall appoint a Committee to be known as the Constitution Committee which shall consist of the Speaker of the National Assembly as Chairman of the Committee and ten other members of Parliament appointed by the National Assembly.",
          },
          {
            number: "4",
            text: "The function of the Constitution Committee shall be to -",
            subsections: [
              {
                number: "(a)",
                text: "organize national education and consultation on this Constitution;",
              },
              {
                number: "(b)",
                text: "organize a national conference fully representative of Malawian society;",
              },
              {
                number: "(c)",
                text: "invite, receive and consider proposals from the citizens of Malawi for the amendment or for the repeal and replacement of this Constitution;",
              },
              {
                number: "(d)",
                text: "allow citizens of Malawi in accordance with its own procedure to make written submissions to, or to be heard in person by, the Committee;",
              },
              {
                number: "(e)",
                text: "compile reports of the proposals received and circulate such reports as widely as possible;",
              },
              {
                number: "(f)",
                text: "lay the reports before Parliament for such period not being less than thirty days before taking action under paragraph (g) as would be reasonably sufficient for responses to be received; and",
              },
              {
                number: "(g)",
                text: "to prepare a Bill or Bills for the amendment of this Constitution or, as provided in subsection (5), a Bill for the repeal and replacement of this Constitution and every such Bill shall be a separate Bill of its own, entitled a Bill to amend the Constitution or, as the case may be, a Bill to repeal and replace the Constitution, and to present such Bill or Bills to Parliament on its own motion.",
              },
            ],
          },
          {
            number: "5",
            text: "On the basis of the extensiveness of the proposals for amendments to this Constitution, Parliament may repeal and replace this Constitution during the period of provisional application, but no such repeal shall be made before, or in contradiction to the resolution of, the national conference referred to under section (4) (b).",
          },
          {
            number: "6",
            text: "Within thirty days before the expiry of the period of provisional application, Parliament shall pass an Act by which this Constitution (with the amendments, if any, made to it under this section) or another Constitution replacing this Constitution shall be brought definitively into force on the expiry of the provisional period of application.",
          },
          {
            number: "7",
            text: "A Bill under this section to amend or to repeal and replace this Constitution shall be passed only if supported by not less than a two-thirds majority of all the members of the National Assembly and shall not be remitted by the Senate.",
          },
          {
            number: "8",
            text: "Unless this Constitution is being repealed and replaced, this section shall not be amended but shall lapse on the expiry of the period of provisional application, save only to the extent necessary to bring this Constitution definitively into force in the circumstances of subsection (9).",
          },
          {
            number: "9",
            text: "If Parliament defaults to act in terms of subsection (6) this Constitution shall be deemed to have definitively come into force on the expiry of the period of provisional application.",
          },
        ],
      },
      {
        number: "213",
        title: "Disclosure of assets by holders of certain offices",
        content: [
          {
            number: "1",
            text: "In addition to the President and members of the Cabinet as provided by section 88 (3), the holders of the following offices, that is to say -",
            subsections: [
              {
                number: "(a)",
                text: "a member of the National Assembly;",
              },
              {
                number: "(b)",
                text: "a member of the Senate;",
              },
              {
                number: "(c)",
                text: "a public officer of such senior grade or position as shall be specified under subsection (2);",
              },
              {
                number: "(d)",
                text: "an officer of such senior grade or position as shall be specified under subsection (2), of -",
                subsections: [
                  {
                    number: "(i)",
                    text: "a corporation, board, commission, council, or similar body established by or under an Act of Parliament;",
                  },
                  {
                    number: "(ii)",
                    text: "any other body, corporate or unincorporate which in accordance with any Act of Parliament is subject to the same statutory procedures for financial control and accountability as apply in common to a body referred to in subparagraph (i),",
                  },
                ],
              },
            ],
          },
          {
            number: "",
            text: "shall, within three months from the date of his or her election, nomination or appointment, as the case may be, fully disclose all of his or her assets, liabilities and business interests and those of his or her spouse held by him or her or on his or her behalf as at that date; and, unless Parliament otherwise prescribes by an Act of Parliament, such disclosure shall be made in a written document delivered to the Speaker of the National Assembly who shall immediately upon receipt deposit the document with such public office as may be specified in the Standing Orders of Parliament.",
          },
          {
            number: "2",
            text: "For the purpose of paragraphs (c) and (d) of subsection (1), the National Assembly shall specify the grades and positions of the officers required to disclose assets in accordance with that subsection, and shall do so by resolution passed by the majority of the members present and voting and which shall be published in the Gazette.",
          },
          {
            number: "3",
            text: "Notwithstanding subsection (1), in the case of those persons who, at the commencement of this section, hold the offices to which this section applies, the period within which they shall comply with subsection (1) shall be a period of three months-",
            subsections: [
              {
                number: "(a)",
                text: "from the commencement of this section, in the case of members of the National Assembly;",
              },
              {
                number: "(b)",
                text: "from the date of the publication of the resolution under subsection (2), in the case of others.",
              },
            ],
          },
          {
            number: "4",
            text: "There shall be a Committee of Parliament appointed by the National Assembly which shall have the function of monitoring the compliance with the requirement on the disclosure of assets under section 88 (3) and under this section and the Committee shall have all the powers necessary to perform its function.",
          },
        ],
      },
      {
        number: "214",
        title: "Short title of this Constitution",
        content: [
          {
            number: "",
            text: "This Constitution of the Republic of Malawi may be cited as the Constitution.",
          },
        ],
      },
      {
        number: "215",
        title: "Definition",
        content: [
          {
            number: "",
            text: "In this Constitution, unless the context otherwise requires -",
            subsections: [
              {
                number: "",
                text: '"appointed day" means 18th May, 1994, being the date on which this Constitution shall come into operation;',
              },
              {
                number: "",
                text: '"age prescribed for retirement" is such age as may be prescribed in an Act of Parliament for the retirement of a person holding public office.',
              },
            ],
          },
        ],
      },
    ],
  },
];
